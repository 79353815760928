import { Main } from './components/core/App';

const basePath = '';
const main = new Main(basePath);
main.preload().then(() => {
    main.mount(document.body);
});

const prod = process.env.NODE_ENV === 'production';
if (!prod) {
    window.main = main;
}
