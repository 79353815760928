import { h, render, Component, createRef } from 'preact';
import bindAll from 'lodash.bindall';
import WebGLManager from '../../managers/webgl-manager';
import {
	isMobile,
	isAndroid,
	isIOS,
	isChrome,
	getChromeVersion,
	isSafari,
	isHTTPS,
	isFirefox,
	isTablet
} from '../../utils/utils';
import { EVENTS, AppProps, CHROME_MIN_VERSION, DEBUG, SKIP_INTRO, VIDEO_STYLES } from '../../config/constants';
import Loader from '../../utils/loader';
import EventManager from '../../managers/event-manager';
import * as UIL from '../../vendor/uil';
import Debug from '../debug/Debug';
import Blackout from '../views/Blackout';
import RotateMessage from '../views/RotateMessage';
import Video from '../views/Video';
import LoadingMessage from '../views/LoadingMessage';
import { config, commonManifest, PATHS, URLS } from '../../config/config';
import AudioManager from '../../managers/audio-manager';
import { supportsARiOS, supportsARAndroid } from '../../utils/ar';
import TransitionGroup from 'preact-transition-group';
import Landing from '../views/Landing';
import MainUI from '../ui/MainUI';
import InteractionMessage from '../views/InteractionMessage';
import MapUI from '../ui/MapUI';
import Outro from '../views/Outro';
import { sendTrackingEvent } from '../../utils/tracking';
import MoveMessage from '../views/MoveMessage';
import gsap from 'gsap';

import '@styles/components/App.scss';
import '@styles/main.scss';
import LegalPopup from '../views/LegalPopup';

class Main {
	constructor({ basePath = '' }) {
		this.basePath = basePath;
		this.locale = 'en-US';
		// if localized, use commented out version
		// this.locale = window.navigator.language || 'en-US';
	}

	preload() {
		this.showLoader();

		// preload landing video?
		// if (!isMobile() && !isFirefox()) {
		// 	commonManifest.push({
		// 		file: `${PATHS.videoPath}desktop/landing.mp4`,
		// 		id: 'landingVideo'
		// 	});
		// }

		commonManifest.forEach(item => {
			item.file = `${this.basePath}${item.file}`;
		});

		const manifest = [
			{
				file: `${this.basePath}${PATHS.localesPath}${this.locale}.json`,
				id: 'copy',
				isData: true
			}
		].concat(commonManifest);

		let loader = new Loader();

		return new Promise(resolve => {
			const handleCompletion = () => {
				this.hideLoader().then(() => {
					loader.dispose();
					resolve();
				});
			};

			loader.load(manifest, files => {
				this.assets = files;

				if (files.data.copy) {
					handleCompletion();
				} else {
					const defaultLocale = [
						{
							file: `${this.basePath}${PATHS.localesPath}en-US.json`,
							id: 'copy',
							isData: true
						}
					];
					loader.load(defaultLocale, files => {
						this.assets.data = files.data;
						handleCompletion();
					});
				}
			});
		});
	}

	showLoader(container = document.body) {
		this.container = container;
		this.loadMessage = render(
			<TransitionGroup component="div">
				<LoadingMessage duration={1} label="Loading" />
			</TransitionGroup>,
			container
		);
	}

	hideLoader() {
		const finish = resolve => {
			render(null, this.container, this.loadMessage);
			this.loadMessage = null;
			resolve();
		};

		const loaderEl = document.querySelector('.loading-message-container-1917');

		return new Promise(resolve => {
			if (loaderEl) {
				gsap.to(loaderEl, {
					duration: 1,
					opacity: 0,
					ease: 'power2.out',
					onComplete: () => {
						finish(resolve);
					}
				});
			} else {
				finish(resolve);
			}
		});
	}

	mount(container = document.body) {
		if (this.app) return;
		this.container = container;
		this.app = render(<App basePath={this.basePath} commonAssets={this.assets} locale={this.locale} />, container);
	}

	unmount() {
		render(null, this.container, this.app);
		this.container = null;
		this.app = null;
	}
}

class App extends Component {
	constructor(props) {
		super(props);

		const env = process.env.NODE_ENV;
		const mobile = isMobile();
		const safari = isSafari();
		const isDevelopment = env === 'development';
		const isProduction = env === 'production';

		if (isProduction) console.warn = () => {};

		this.state = {
			ready: false,
			copyLoaded: false,
			hasInitialized: false,
			hasStarted: false,
			commonAssets: null,
			muted: mobile || safari,
			debugMessage: '',
			env: env,
			locale: 'en-US',
			isDevelopment: isDevelopment,
			isProduction: isProduction,
			isMobile: mobile,
			isTablet: isTablet(),
			isAndroid: isAndroid(),
			isIOS: isIOS(),
			isChrome: isChrome(),
			isSafari: safari,
			isFirefox: isFirefox(),
			supportsARiOS: supportsARiOS(),
			supportsARAndroid: supportsARAndroid(),
			arFiles: null,
			chromeVersion: getChromeVersion(),
			pixelRatio: Math.min(window.devicePixelRatio || 1, 1.5),
			copy: null,
			webglManager: null,
			sceneManager: null,
			audioManager: new AudioManager(config.audio),
			useDeviceOrientation: false,
			scene: null,
			camera: null,
			cameraRig: null,
			renderer: null,
			raycaster: null,
			controls: null,
			skybox: null,
			envMap: null,
			envMapListeners: null,
			devControls: null,
			devControlItems: null,
			fadeToBlack: true,
			fadeToBlackDuration: 3,
			screenWidth: window.innerWidth,
			screenHeight: window.innerHeight,
			videoId: null,
			videoSource: null,
			videoStyle: VIDEO_STYLES.TRANSITION,
			videoSkipTransitionOut: false,
			autoplayVideos: true,
			showInteractionMessage: false,
			showMoveMessage: false,
			showLoadingMessage: false,
			showLanding: true,
			showIntro: false,
			showOutro: false,
			showFullUI: false,
			showMapUI: false,
			showAudioButton: false,
			showButtonLabels: true,
			showLegals: false,
			legalsSide: -1,
			logoEnabled: false,
			hotspots: []
		};

		AppProps.app = this;
		AppProps.state = this.state;

		bindAll(
			this,
			'initApp',
			'handleVisibilityChange',
			'handleResize',
			'handleStart',
			'toggleAudio',
			'showMap',
			'hideMap',
			'handleRestart',
			'handleVisit',
			'handleTickets',
			'goHome'
		);

		this.canvasRef = createRef();
	}

	componentDidMount() {
		const { locale, commonAssets } = this.props;
		const { copy } = commonAssets.data;

		this.setState({
			copy: copy,
			commonAssets: commonAssets,
			copyLoaded: true,
			locale: locale
		});

		this.handleResize();
		window.addEventListener('resize', this.handleResize, false);

		console.log('shared assets:', this.state.commonAssets);
	}

	componentWillUnmount() {
		this.dispose();
	}

	componentDidUpdate(prevProps, prevState) {
		AppProps.state = this.state;

		const { audioManager, showMapUI, ready } = this.state;

		if (!prevState.ready && ready) {
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				this.initApp();
			}, 2000);
		}

		if (audioManager && prevState.showMapUI !== showMapUI) {
			audioManager.setMasterVolume(showMapUI ? 0.25 : 1);
		}
	}

	initApp() {
		const { isDevelopment, isMobile, env, isIOS, isAndroid, isChrome, chromeVersion, pixelRatio } = this.state;

		// add props ui and other dev stuff
		if (isDevelopment) {
			window.app = this;
			window.props = AppProps;

			if (!isMobile) {
				this.createDevControls();
			}
		}

		const webglManager = new WebGLManager(this.canvasRef.current, this.props.basePath);
		this.setState({ webglManager: webglManager });

		console.log('environment:', env);
		console.log('mobile:', isMobile);
		console.log('ios:', isIOS);
		console.log('android:', isAndroid);
		console.log('chrome:', isChrome);
		if (isChrome) console.log('chrome version:', chromeVersion);
		console.log('pixel ratio:', pixelRatio);

		// track leaving the page
		let hidden;
		let visibilityChange;
		if (typeof document.hidden !== 'undefined') {
			// Opera 12.10 and Firefox 18 and later support
			hidden = 'hidden';
			visibilityChange = 'visibilitychange';
		} else if (typeof document.msHidden !== 'undefined') {
			hidden = 'msHidden';
			visibilityChange = 'msvisibilitychange';
		} else if (typeof document.webkitHidden !== 'undefined') {
			hidden = 'webkitHidden';
			visibilityChange = 'webkitvisibilitychange';
		}
		this.documentHidden = hidden;
		this.visibilityChange = visibilityChange;
		document.addEventListener(visibilityChange, this.handleVisibilityChange, false);
	}

	createDevControls() {
		if (!window.devControls) {
			const ui = new UIL.Gui({
				css: 'top:0; left: 50%; z-index:99999;',
				w: 650,
				center: true
			});
			ui.add('title', { name: 'DEV CONTROLS', h: 30 });
			ui.add('fps', { h: 30 });
			ui.content.classList.add('gui');
			window.devControls = ui;
			window.devControlItems = {};
			this.setState({ devControls: window.devControls, devControlItems: window.devControlItems });

			// close controls
			ui.isOpen = false;
			ui.bottom.textContent = 'OPEN';
			ui.setHeight();
			ui.draw();
		} else {
			this.setState({ devControls: window.devControls, devControlItems: window.devControlItems });
		}
	}

	toggleAudio() {
		const { audioManager } = this.state;
		const muted = !this.state.muted;
		this.setState({ muted: muted });

		if (muted) {
			audioManager.mute();
			EventManager.emit(EVENTS.MUTE_AUDIO);
		} else {
			audioManager.unmute();
			EventManager.emit(EVENTS.UNMUTE_AUDIO);
		}

		sendTrackingEvent({
			action: 'click',
			category: 'page',
			label: muted ? 'mute button' : 'unmute button',
			value: muted ? 'mute-button-click' : 'unmute-button-click'
		});
	}

	showMap() {
		const { showFullUI } = this.state;
		if (!showFullUI) return; // don't allow map button click when fading out
		this.setState({ showMapUI: true, showFullUI: false });
		EventManager.emit(EVENTS.PAUSE);
	}

	hideMap() {
		this.setState({ showMapUI: false, showFullUI: true });
		EventManager.emit(EVENTS.RESUME);
	}

	handleStart() {
		if (this.state.hasInitialized) {
			this.setState({ hasStarted: true, showLanding: false });
			this.showIntro();
			return;
		}

		this.setState({ hasStarted: true, hasInitialized: true, showLanding: false });

		const { audioManager } = this.state;
		audioManager.init();

		this.requestSensorPermissions()
			.then(() => {
				this.setState({ ready: true, useDeviceOrientation: true });
				this.showIntro();
			})
			.catch(() => {
				this.setState({ ready: true, useDeviceOrientation: false });
				this.showIntro();
			});

		sendTrackingEvent({
			action: 'click',
			category: 'start screen',
			label: 'start button',
			value: 'start-button-click'
		});
	}

	handleTickets() {
		window.open(URLS.tickets, '_blank');
	}

	handleVisit() {
		window.open(URLS.visit, '_blank');
	}

	handleRestart() {
		const { sceneManager } = this.state;

		this.setState({
			showOutro: false,
			showLoadingMessage: true,
			fadeToBlack: true,
			fadeToBlackDuration: 0,
			skipTransitionOut: false
		});

		clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
			const index = 0;
			sceneManager.loadTrench(index).then(() => {
				this.setState({
					fadeToBlackDuration: 3,
					showFullUI: true
				});

				sceneManager.setCurrentTrenchIndex(index);
				sceneManager.initTrench(index);
			});
		}, 1500);
	}

	goHome() {
		const { sceneManager } = this.state;

		EventManager.emit(EVENTS.GO_HOME);
		EventManager.emit(EVENTS.PAUSE);

		this.setState({
			fadeToBlack: true,
			fadeToBlackDuration: 2,
			logoEnabled: false,
			showFullUI: false,
			showInteractionMessage: false,
			showLoadingMessage: false,
			showIntro: false,
			showOutro: false,
			showMapUI: false,
			showMoveMessage: false
		});

		clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
			// remove current trench
			sceneManager.handleGoHome();

			this.setState({
				showLanding: true
			});

			// after intro show 1st trench
			EventManager.once(EVENTS.INTRO_COMPLETE, () => {
				this.setState({
					fadeToBlackDuration: 0,
					fadeToBlack: true
				});
				const index = 0;
				sceneManager.loadTrench(index).then(trench => {
					this.setState({
						fadeToBlackDuration: 3,
						showLoadingMessage: false
					});
					sceneManager.setCurrentTrenchIndex(index);

					clearTimeout(this.timeout);
					this.timeout = setTimeout(() => {
						sceneManager.initTrench(index);
					}, 1000);
				});
			});
		}, 2000);
	}

	showIntro() {
		const { audioManager } = this.state;

		if (!SKIP_INTRO) {
			// show audio button at same time(ish) as intro skip button
			EventManager.once(EVENTS.VIDEO_STARTED, () => {
				this.setState({
					showAudioButton: true
				});
			});

			EventManager.once(EVENTS.VIDEO_COMPLETE, () => {
				// transition out video
				this.setState({ videoSource: null, showIntro: false });
				EventManager.emit(EVENTS.INTRO_COMPLETE);
				audioManager.start();
			});

			this.setState({
				showIntro: true,
				videoSource: config.intro.media,
				videoStyle: VIDEO_STYLES.TRANSITION
			});
		} else {
			this.setState({
				showFullUI: true,
				showAudioButton: true
			});
			audioManager.start();
		}
	}

	requestSensorPermissions() {
		const { isIOS, isAndroid, isChrome, chromeVersion } = this.state;
		const https = isHTTPS();

		const logMessage = message => {
			console.log('sensor support:', message);
			this.debug(message);
		};

		return new Promise((resolve, reject) => {
			if (isIOS && window.DeviceMotionEvent && typeof DeviceMotionEvent.requestPermission === 'function' && https) {
				// iOS 13+
				DeviceOrientationEvent.requestPermission()
					.then(response => {
						if (response === 'granted') {
							logMessage('device motion support');
							resolve();
						} else {
							logMessage('no device motion support');
							reject();
						}
					})
					.catch(error => {
						logMessage('no device motion support');
						reject();
					});
			} else if (isAndroid && isChrome && chromeVersion >= CHROME_MIN_VERSION && https) {
				logMessage('device motion support');
				resolve();
			} else {
				// no device motion support?
				logMessage('no device motion support');
				reject();
			}
		});
	}

	render() {
		const {
			hasStarted,
			hasInitialized,
			debugMessage,
			isMobile,
			isTablet,
			copy,
			fadeToBlack,
			fadeToBlackDuration,
			screenWidth,
			screenHeight,
			videoId,
			videoSource,
			videoStyle,
			videoSkipTransitionOut,
			showLoadingMessage,
			muted,
			arFiles,
			supportsARiOS,
			supportsARAndroid,
			isAndroid,
			isIOS,
			showLanding,
			showInteractionMessage,
			showMoveMessage,
			showFullUI,
			showMapUI,
			showButtonLabels,
			showOutro,
			showIntro,
			showAudioButton,
			showLegals,
			legalsSide,
			logoEnabled,
			useDeviceOrientation,
			sceneManager,
			commonAssets
		} = this.state;

		return (
			<div class="app-1917">
				{hasStarted && (
					<span>
						<div class="canvas-container-1917">
							<canvas class="main-canvas-1917" ref={this.canvasRef} />
						</div>

						<TransitionGroup component="div">
							{videoSource && (
								<Video
									id={videoId}
									src={videoSource}
									videoStyle={videoStyle}
									skipTransitionOut={videoSkipTransitionOut}
									showButtonLabels={showButtonLabels}
									showIntro={showIntro}
									screenWidth={screenWidth}
									screenHeight={screenHeight}
									isIntro={showIntro}
								/>
							)}
							{fadeToBlack && <Blackout duration={fadeToBlackDuration} />}
							{showLoadingMessage && copy && <LoadingMessage duration={0.9} delay={0.5} label={copy.loading} />}
						</TransitionGroup>
					</span>
				)}

				<div class="ui-container-1917">
					<TransitionGroup component="div">
						{showLanding && copy && (
							<Landing
								config={config.landing}
								copy={copy}
								onClick={this.handleStart}
								duration={1.5}
								delay={hasInitialized ? 0 : 0.5}
								screenWidth={screenWidth}
								screenHeight={screenHeight}
								isMobile={isMobile}
								commonAssets={commonAssets}
							/>
						)}
						{showInteractionMessage && copy && (
							<InteractionMessage
								isMobile={isMobile}
								copy={copy}
								duration={1.5}
								delay={0.5}
								useDeviceOrientation={useDeviceOrientation}
							/>
						)}
						{showMoveMessage && copy && <MoveMessage isMobile={isMobile} copy={copy} duration={1.5} />}
						{showOutro && (
							<Outro
								config={config.outro}
								copy={copy}
								onClick={this.handleRestart}
								onVisitClick={this.handleVisit}
								duration={1.5}
								// delay={0.5}
								screenWidth={screenWidth}
								screenHeight={screenHeight}
								isMobile={isMobile}
								commonAssets={commonAssets}
							/>
						)}
						{copy && showMapUI && (
							<MapUI
								copy={copy}
								sectionIndex={sceneManager.getCurrentTrenchIndex()}
								isMobile={isMobile}
								isTablet={isTablet}
								closeClick={this.hideMap}
								duration={0.9}
							/>
						)}
						{copy && (
							<MainUI
								showFullUI={showFullUI && !showLoadingMessage && !showLanding && !showOutro}
								showAudioButton={showAudioButton}
								showTop={!showLanding && !showOutro}
								duration={1.2}
								copy={copy}
								muted={muted}
								logoClick={this.goHome}
								audioClick={this.toggleAudio}
								mapClick={this.showMap}
								ticketsClick={this.handleTickets}
								isMobile={isMobile}
								isTablet={isTablet}
								arFiles={arFiles}
								supportsARiOS={supportsARiOS}
								supportsARAndroid={supportsARAndroid}
								isAndroid={isAndroid}
								isIOS={isIOS}
								showButtonLabels={showButtonLabels}
								logoEnabled={logoEnabled}
							/>
						)}
						{copy && showLegals && (
							<LegalPopup copy={copy} config={config.legal} duration={0.9} isMobile={isMobile} side={legalsSide} />
						)}
					</TransitionGroup>
					{isMobile && DEBUG && <Debug message={debugMessage} />}
				</div>

				{isMobile && copy && <RotateMessage visible={screenWidth > screenHeight} label={copy.rotate} />}
			</div>
		);
	}

	handleVisibilityChange(e) {
		const { muted, audioManager } = this.state;

		if (document[this.documentHidden]) {
			EventManager.emit(EVENTS.PAUSE);
			audioManager.mute();
		} else {
			EventManager.emit(EVENTS.RESUME);
			if (!muted) {
				audioManager.unmute();
			}
		}
	}

	handleResize() {
		this.setState({
			screenWidth: window.innerWidth,
			screenHeight: window.innerHeight
		});
	}

	debug(message, append = true) {
		if (!DEBUG) return;

		if (append) {
			const { debugMessage } = this.state;
			this.setState({ debugMessage: debugMessage + '\n\n' + message });
		} else {
			this.setState({ debugMessage: message });
		}
	}

	dispose() {
		document.removeEventListener(this.visibilityChange, this.handleVisibilityChange);
		window.removeEventListener('resize', this.handleResize);
		clearTimeout(this.timeout);

		const { webglManager, audioManager } = this.state;

		if (webglManager) webglManager.dispose();
		if (audioManager) audioManager.dispose();

		AppProps.app = null;
		AppProps.state = null;

		this.state = null;
		this.canvasRef = null;
		this.documentHidden = null;
		this.visibilityChange = null;
		this.timeout = null;

		window.app = null;
		window.props = null;
	}
}

export { Main, App };
