import { h, createRef } from 'preact';
import FadingView from './FadingView';
import gsap from 'gsap';

import '@styles/components/LoadingMessage.scss';

export default class LoadingMessage extends FadingView {
	constructor(props) {
		super(props);
		this.barRef = createRef();
	}

	componentDidMount() {
		if (this.barRef.current) {
			gsap.fromTo(
				this.barRef.current,
				{
					backgroundPositionX: 0
				},
				{
					backgroundPositionX: `${window.innerWidth}px`,
					duration: 2,
					repeat: -1,
					ease: 'none'
				}
			);
		}

		const { basic } = this.props;
		if (basic) this.transitionIn(() => {});
	}

	componentWillUnmount() {
		super.componentWillUnmount();
		if (this.barRef.current) {
			gsap.killTweensOf(this.barRef.current);
		}
		this.barRef = null;
	}

	render() {
		const { label, classOverride } = this.props;

		const className = classOverride || 'loading-message-container-1917';

		return (
			<div class={className} ref={this.ref}>
				<div class="loading-bar-1917" ref={this.barRef} />
				<div class="loading-message-1917">
					<div class="loading-message-icon-1917" />
					<span>{label}</span>
				</div>
			</div>
		);
	}
}
