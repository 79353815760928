import { h } from 'preact';
import FadingView from '../views/FadingView';
import AudioButton from './AudioButton';
import MapButton from './MapButton';
import ARButton from './ARButton';
import TransitionGroup from 'preact-transition-group';
import CSSTransitionGroup from 'preact-css-transition-group';
import gsap from 'gsap';
import { addActiveCSSClass, removeActiveCSSClass } from '../../utils/utils';

import '@styles/components/MainUI.scss';

export default class MainUI extends FadingView {
	constructor(props) {
		super(props);
	}

	transitionIn(callback) {
		if (!this.ref.current) {
			callback();
			return;
		}

		const { duration = 0.6, delay = 0 } = this.props;

		gsap.fromTo(
			this.ref.current,
			{
				opacity: 0
			},
			{
				duration,
				delay,
				opacity: 1,
				ease: 'power3.inOut',
				onComplete: () => {
					callback();
				}
			}
		);

		const bottomElements = [];
		const arEl = this.ref.current.querySelector('.ar-button-1917');
		const audioEl = this.ref.current.querySelector('.audio-button-1917');
		const mapEl = this.ref.current.querySelector('.map-button-1917');
		const closeEl = document.querySelector('.close-video-button-inset-1917');
		if (arEl) bottomElements.push(arEl);
		if (audioEl) bottomElements.push(audioEl);
		if (mapEl) bottomElements.push(mapEl);
		if (closeEl) bottomElements.push(closeEl);

		if (bottomElements.length) {
			gsap.from(bottomElements, {
				duration,
				y: '+=150',
				ease: 'power3.out',
				delay: 0.5,
				stagger: 0.25
			});
		}
	}

	renderARButton() {
		const {
			arFiles,
			copy,
			supportsARiOS,
			supportsARAndroid,
			isMobile,
			isTablet,
			isAndroid,
			isIOS,
			showButtonLabels
		} = this.props;

		if ((!isMobile && !isTablet) || !arFiles) return null;

		let element = null;

		if (copy) {
			if ((isIOS && supportsARiOS) || (isAndroid && supportsARAndroid)) {
				element = (
					<ARButton
						label={copy.ar}
						isIOS={isIOS}
						arFiles={arFiles}
						duration={1}
						delay={0.5}
						showLabel={showButtonLabels}
					/>
				);
			}
		}

		return element;
	}

	renderBottom() {
		const { showFullUI, showAudioButton, copy, isMobile, audioClick, mapClick, muted, showButtonLabels } = this.props;

		if (isMobile) {
			return (
				<div class="bottom-ui-1917">
					<TransitionGroup component="span">{showFullUI && this.renderARButton()}</TransitionGroup>
					<TransitionGroup component="span">
						{showFullUI && (
							<MapButton
								label={copy.map}
								onClick={mapClick}
								duration={1}
								delay={0.5}
								showLabel={showButtonLabels}
							/>
						)}
					</TransitionGroup>
					<TransitionGroup component="span">
						{showAudioButton && (
							<AudioButton
								label={copy.sound}
								onClick={audioClick}
								muted={muted}
								isMobile={isMobile}
								duration={1}
								delay={isMobile ? 0 : 0.5}
								showLabel={showButtonLabels}
							/>
						)}
						)
					</TransitionGroup>
				</div>
			);
		} else {
			return (
				<div class="bottom-ui-1917">
					<TransitionGroup component="span">
						{showAudioButton && (
							<AudioButton label={copy.sound} duration={1} delay={0.5} onClick={audioClick} muted={muted} />
						)}
					</TransitionGroup>
					<TransitionGroup component="span">
						{showFullUI && <MapButton label={copy.map} duration={1} delay={0.5} onClick={mapClick} />}
					</TransitionGroup>
				</div>
			);
		}
	}

	render() {
		const { copy, ticketsClick, logoClick, logoEnabled, showTop } = this.props;

		const logoClass = logoEnabled ? 'logo-1917' : 'logo-1917 disabled';

		return (
			<div class="main-ui-1917" ref={this.ref}>
				<CSSTransitionGroup
					component="div"
					transitionName="fade-in-delayed"
					transitionAppear={false}
					transitionEnter={true}
					transitionLeave={true}
				>
					{showTop && (
						<div
							class={logoClass}
							onClick={logoClick}
							onTouchStart={addActiveCSSClass}
							onTouchEnd={removeActiveCSSClass}
							onTouchCancel={removeActiveCSSClass}
							// ref={this.logoRef}
						/>
					)}
				</CSSTransitionGroup>
				<CSSTransitionGroup
					component="div"
					transitionName="fade-in-delayed"
					transitionAppear={false}
					transitionEnter={true}
					transitionLeave={true}
				>
					{showTop && (
						<div
							class="get-tickets-1917"
							onClick={ticketsClick}
							onTouchStart={addActiveCSSClass}
							onTouchEnd={removeActiveCSSClass}
							onTouchCancel={removeActiveCSSClass}
							// ref={this.ticketsRef}
						>
							<div class="get-tickets-hitarea-1917" />
							<div class="get-tickets-label-1917">{copy.getTickets}</div>
							<div class="get-tickets-line-1917">
								<div class="get-tickets-line-gradient-1917" />
							</div>
						</div>
					)}
				</CSSTransitionGroup>
				{this.renderBottom()}
			</div>
		);
	}
}
