import Entity from './entity';
import { AppProps, EVENTS } from '../config/constants';
import { Mesh, PlaneBufferGeometry, MeshBasicMaterial, Group } from 'three/build/three.module';
import gsap from 'gsap';
import EventManager from '../managers/event-manager';

export default class FootstepAnimationEntity extends Entity {
	init() {
		const { commonAssets, anistropy } = AppProps.state;
		const { leftFootTex, rightFootTex } = commonAssets.textures;
		const { config, cameraPath, startPosition } = this.props;
		const { positionOffset, rotation } = config;

		leftFootTex.anistropy = rightFootTex.anistropy = anistropy;

		this.mesh = new Group();
		this.mesh.position.copy(cameraPath.getPositionAt(startPosition));

		if (positionOffset) {
			this.mesh.position.x += positionOffset[0];
			this.mesh.position.y += positionOffset[1];
			this.mesh.position.z += positionOffset[2];
		}

		if (rotation) {
			this.mesh.rotation.fromArray(rotation);
		}

		const feet = [];
		const scale = 0.00035;
		let side = 1;
		let maxOpacity;
		let z = 0;

		for (let i = 0; i < 6; i++) {
			if (i == 0) maxOpacity = 0.7;
			else if (i == 1) maxOpacity = 0.85;
			else maxOpacity = 1;

			const footMesh = this.createFootMesh(side, scale, z, maxOpacity);
			this.mesh.add(footMesh);
			feet.push(footMesh);
			side *= -1;
			z -= 0.35;
		}

		this.transitionIn(feet);
	}

	createFootMesh(side = 1, scale, z, maxOpacity = 1) {
		const { commonAssets, isMobile } = AppProps.state;
		const { leftFootTex, rightFootTex } = commonAssets.textures;
		const tex = side === -1 ? leftFootTex : rightFootTex;
		const deviceScalar = isMobile ? 2 : 1;
		const offset =
			side === -1
				? -tex.image.naturalWidth * deviceScalar * scale * 0.45
				: tex.image.naturalWidth * deviceScalar * scale * 0.45;

		const footMesh = new Mesh(
			new PlaneBufferGeometry(
				tex.image.naturalWidth * deviceScalar * scale,
				tex.image.naturalHeight * deviceScalar * scale
			),
			new MeshBasicMaterial({
				map: tex,
				color: 0xffffff,
				transparent: true,
				opacity: 0,
				depthTest: false
			})
		);

		footMesh.userData.maxOpacity = maxOpacity;
		footMesh.rotation.x = -Math.PI / 2;
		footMesh.position.x = offset;
		footMesh.position.z = z;

		return footMesh;
	}

	transitionIn(feet) {
		let delay = 2;
		let timeline;

		feet.forEach((foot, index) => {
			if (index === feet.length - 1) {
				timeline = new gsap.timeline({
					onComplete: () => {
						EventManager.emit(EVENTS.ANIMATION_COMPLETE);
						this.dispose();
					}
				});
			} else {
				timeline = new gsap.timeline();
			}

			timeline.add([
				gsap.to(foot.material, {
					duration: 1.2,
					opacity: foot.userData.maxOpacity,
					delay: delay,
					ease: 'power2.inOut'
				}),
				gsap.from(foot.position, {
					duration: 1.2,
					y: 0.05,
					delay: delay,
					ease: 'power2.out'
				})
			]);

			timeline.add(
				gsap.to(foot.material, {
					duration: 1.2,
					opacity: 0,
					ease: 'power2.inOut',
					delay: 0.6
				})
			);

			delay += 0.75;
		});
	}
}
