import { h, createRef } from 'preact';
import FadingView from './FadingView';
import bindAll from 'lodash.bindall';
import gsap from 'gsap';
import { clamp, addActiveCSSClass, removeActiveCSSClass } from '../../utils/utils';
import EventManager from '../../managers/event-manager';
import { EVENTS, AppProps } from '../../config/constants';
import { URLS } from '../../config/config';

import '@styles/components/Button.scss';
import '@styles/components/Video.scss';
import '@styles/components/Landing.scss';

export default class Landing extends FadingView {
	constructor(props) {
		super(props);
		bindAll(this, 'handleSocialClick', 'handleLegalsClick', 'handleCanPlayThrough', 'update');

		this.videoRef = createRef();
		this.facebookRef = createRef();
		this.twitterRef = createRef();
		this.instagramRef = createRef();
		this.contentRef = createRef();
		this.startButtonRef = createRef();
		this.messageRef = createRef();

		const { isMobile } = this.props;
		if (!isMobile) {
			this.state.videoX = 0;
			this.state.videoY = 0;
			this.state.videoWidth = 0;
			this.state.videoHeight = 0;
			this.state.videoWidthRatio = 1;
			this.state.videoHeightRatio = 1;
			this.state.videoReferenceWidth = 1920;
			this.state.videoReferenceHeight = 1080;
			this.state.revealStartButton = false;
			this.state.clipTransition = {
				from1: 80.4883,
				to1: 69.7266,
				value1: 80.4883,
				from2: 51.2695,
				to2: 40.5273,
				value2: 51.2695,
				from3: -100,
				to3: 0,
				value3: -100,
				from4: 0,
				to4: 1,
				value4: 0
			};
		}
	}

	componentDidMount() {
		const { audioManager } = AppProps.state;

		// fade out bg audio
		audioManager.handleVideoStarted();
		AppProps.app.setState({ showAudioButton: false });

		const video = this.videoRef.current;

		if (video) {
			if (video.readyState < 4) {
				video.addEventListener('canplaythrough', this.handleCanPlayThrough, false);
			} else {
				this.handleCanPlayThrough();
			}
		}

		const { isMobile } = this.props;

		if (!isMobile) {
			this.updateSizeStates();
			this.update();
		}

		EventManager.emit(EVENTS.PAUSE);
	}

	componentWillUnmount() {
		cancelAnimationFrame(this.raf);

		if (this.videoRef.current) {
			this.videoRef.current.removeEventListener('canplaythrough', this.handleCanPlayThrough);
			this.videoRef.current.currentTime = 0;
		}

		if (this.startButtonRef.current) {
			this.startButtonRef.current.style.opacity = null;
		}

		this.raf = null;
		this.videoRef = null;
		this.facebookRef = null;
		this.twitterRef = null;
		this.instagramRef = null;
		this.contentRef = null;
		this.startButtonRef = null;
		this.messageRef = null;
		this.contentTween1 = null;

		EventManager.emit(EVENTS.RESUME);
	}

	componentDidUpdate(prevProps, prevState) {
		const { isMobile } = this.props;

		if (!isMobile) {
			if (prevProps.screenWidth !== this.props.screenWidth || prevProps.screenHeight !== this.props.screenHeight) {
				this.updateSizeStates();
			}

			if (!prevState.revealStartButton && this.state.revealStartButton) {
				this.transitionInStartButton();
			}
		}
	}

	handleCanPlayThrough() {
		const video = this.videoRef.current;
		const { isMobile } = this.props;

		if (video && video.paused) {
			video.removeEventListener('canplaythrough', this.handleCanPlayThrough);

			if (!isMobile) this.updateSizeStates();

			setTimeout(() => {
				video.play().catch(error => {
					console.log(error);
				});
			}, 500);
		}
	}

	updateSizeStates() {
		const video = this.videoRef.current;
		const message = this.messageRef.current;

		if (video && message) {
			const { videoReferenceWidth, videoReferenceHeight } = this.state;
			const bounds = video.getBoundingClientRect();
			const refRatioW = bounds.width / videoReferenceWidth;
			const refRatioH = bounds.height / videoReferenceHeight;
			const ratio = bounds.height / video.videoHeight;

			this.setState({
				videoX: bounds.left,
				videoY: bounds.top,
				videoWidth: video.videoWidth * ratio,
				videoHeight: video.videoHeight * ratio,
				videoWidthRatio: refRatioW,
				videoHeightRatio: refRatioH
			});
		}
	}

	update() {
		const { config } = this.props;
		const { clipTransition } = this.state;
		const { loopStart, loopEnd, videoTransitionStartTime, videoTransitionEndTime } = config;
		const video = this.videoRef.current;

		if (video) {
			if (video.currentTime >= videoTransitionStartTime && video.currentTime <= videoTransitionEndTime) {
				// map mask transition in to video transition
				if (!this.contentTween1) {
					this.contentTween1 = gsap.fromTo(
						clipTransition,
						{
							value1: clipTransition.from1,
							value2: clipTransition.from2,
							value3: clipTransition.from3,
							value4: clipTransition.from4
						},
						{
							duration: videoTransitionEndTime - videoTransitionStartTime,
							value1: clipTransition.to1,
							value2: clipTransition.to2,
							value3: clipTransition.to3,
							value4: clipTransition.to4,
							ease: 'sine.inOut',
							onComplete: () => {
								this.setState({ revealStartButton: true });
							}
						}
					);
				}
				const transitionProgress = clamp(
					(video.currentTime - videoTransitionStartTime) / (videoTransitionEndTime - videoTransitionStartTime),
					0,
					1
				);
				this.contentTween1.progress(transitionProgress);
				this.forceUpdate();
			} else if (video.currentTime >= loopEnd) {
				// loop at correct point
				video.currentTime = loopStart;
			}
		}

		this.raf = requestAnimationFrame(this.update);
	}

	handleSocialClick(e) {
		switch (e.currentTarget.dataset.id) {
			case 'facebook':
				window.open(URLS.facebook, '_blank');
				break;

			case 'twitter':
				window.open(URLS.twitter, '_blank');
				break;

			case 'instagram':
				window.open(URLS.instagram, '_blank');
				break;

			default:
				break;
		}
	}

	handleLegalsClick(e) {
		AppProps.app.setState({ showLegals: true, legalsSide: -1 });
	}

	transitionIn(callback) {
		if (!this.ref.current) {
			callback();
			return;
		}

		const { duration = 0.6, delay = 0, isMobile } = this.props;

		gsap.fromTo(
			this.ref.current,
			{
				opacity: 0
			},
			{
				duration,
				delay,
				opacity: 1,
				ease: 'power3.inOut',
				onComplete: () => {
					callback();
				}
			}
		);

		if (!isMobile) {
			const elements = [];
			const facebook = this.facebookRef.current;
			const twitter = this.twitterRef.current;
			const instagram = this.instagramRef.current;

			if (facebook) elements.push(facebook);
			if (twitter) elements.push(twitter);
			if (instagram) elements.push(instagram);

			if (elements.length) {
				gsap.from(elements, {
					x: '+=50',
					opacity: 0,
					duration: 0.9,
					ease: 'power3.inOut',
					stagger: 0.1,
					delay: 0.75,
					onComplete: () => {
						elements.forEach(element => {
							element.style.opacity = null;
							element.style.transform = null;
						});
					}
				});
			}
		} else {
			const message = this.messageRef.current;
			const startBtn = this.startButtonRef.current;
			const video = this.videoRef.current;

			if (video) {
				gsap.from(video, {
					scale: 1.25,
					opacity: 0,
					duration: 1.5,
					ease: 'power2.inOut',
					delay: 0.5,
					onComplete: () => {
						video.style.opacity = null;
						video.style.transform = null;
					}
				});
			}

			if (message && startBtn) {
				gsap.from(message, {
					y: '+=50',
					opacity: 0,
					duration: 1.2,
					ease: 'power3.out',
					delay: 1.4,
					onComplete: () => {
						message.style.opacity = null;
						message.style.transform = null;
					}
				});

				gsap.from(startBtn, {
					opacity: 0,
					duration: 1.2,
					ease: 'power3.out',
					delay: 2.2,
					onComplete: () => {
						startBtn.style.opacity = null;
					}
				});
			}
		}
	}

	transitionOut(callback) {
		if (!this.ref.current) {
			callback();
			return;
		}

		AppProps.app.setState({ showLegals: false });

		const { duration = 0.6 } = this.props;

		gsap.to(this.ref.current, {
			duration,
			opacity: 0,
			ease: 'power3.inOut',
			delay: 0.25,
			onComplete: () => {
				callback();
			}
		});
	}

	transitionInStartButton() {
		const startBtn = this.startButtonRef.current;

		if (startBtn) {
			gsap.to(startBtn, {
				duration: 0.9,
				opacity: 1,
				ease: 'power3.inOut'
			});
		}
	}

	renderLegals() {
		const { copy } = this.props;

		return (
			<div
				class="landing-legals-1917"
				onClick={this.handleLegalsClick}
				onTouchStart={addActiveCSSClass}
				onTouchEnd={removeActiveCSSClass}
				onTouchCancel={removeActiveCSSClass}
			>
				{copy.legals}
			</div>
		);
	}

	renderDesktop() {
		const { config, copy, onClick, commonAssets } = this.props;
		const { media } = config;
		const landingVideo = commonAssets.video ? commonAssets.video.landingVideo : null;
		const {
			videoX,
			videoY,
			videoWidth,
			videoHeight,
			videoWidthRatio,
			videoHeightRatio,
			videoReferenceWidth,
			clipTransition
		} = this.state;

		const { value1, value2, value3, value4 } = clipTransition;

		const containerWidth = window.innerWidth > videoWidth ? window.innerWidth : videoWidth;
		const containerHeight = window.innerHeight > videoHeight ? window.innerHeight : videoHeight;

		const containerStyle = {
			clipPath: `polygon(${value1}% 0, 100% 0, 100% 100%, ${value2}% 100%)`,
			left: `${videoX}px`,
			top: `${videoY}px`,
			width: `${containerWidth}px`,
			height: `${containerHeight}px`
		};

		// safari
		containerStyle['-webkit-clip-path'] = containerStyle.clipPath;

		const contentStyle = {
			left: `${1110 * videoWidthRatio}px`,
			top: `${555 * videoHeightRatio}px`
		};

		const messageScale = Math.min(window.innerWidth / videoReferenceWidth, 1);
		const messageStyle = {
			opacity: value4,
			transform: `scale(${messageScale}) translateX(${value3}%)`
		};

		const suggestionStyle = {
			opacity: value4,
			marginTop: '20px'
			// left: `${1110 * videoWidthRatio}px`,
			// bottom: containerHeight < window.innerHeight ? '56px' : `${((containerHeight - window.innerHeight)/2) + 56}px`
		};

		const buttonStyle = {
			marginTop: `${20 - (184 * (1 - messageScale) - 20)}px`
		};

		return (
			<div class="landing-1917" ref={this.ref}>
				<video
					src={landingVideo || media}
					ref={this.videoRef}
					class="landing-video-1917"
					muted
					playsinline
					preload="auto"
				/>
				<div class="landing-content-container" style={containerStyle}>
					<div class="landing-content" style={contentStyle} ref={this.contentRef}>
						<div class="landing-message" style={messageStyle} ref={this.messageRef}>
							{copy.landingMessage}
							<div class="landing-suggestion" style={suggestionStyle}>
								{copy.landingSuggestion}
							</div>
						</div>
						<div class="start-button-1917" onClick={onClick} style={buttonStyle} ref={this.startButtonRef}>
							<div class="left-bracket" />
							<div class="start-button-label">{copy.start}</div>
							<div class="right-bracket" />
							<div class="button-gradient-1917" />
						</div>
					</div>
				</div>
				<div class="landing-social-container-1917">
					<div class="facebook-1917" data-id="facebook" onClick={this.handleSocialClick} ref={this.facebookRef} />
					<div class="twitter-1917" data-id="twitter" onClick={this.handleSocialClick} ref={this.twitterRef} />
					<div class="instagram-1917" data-id="instagram" onClick={this.handleSocialClick} ref={this.instagramRef} />
				</div>
				{this.renderLegals()}
			</div>
		);
	}

	renderMobile() {
		const { config, copy, onClick } = this.props;
		const { media } = config;

		return (
			<div class="landing-1917" ref={this.ref}>
				<video
					src={media}
					ref={this.videoRef}
					class="landing-video-1917"
					muted
					playsinline
					autoplay
					loop
					preload="auto"
				/>
				<div class="landing-content-container">
					<div class="landing-content" ref={this.contentRef}>
						<div class="landing-message" ref={this.messageRef}>
							{copy.landingMessageMobile}
						</div>
						<div
							class="start-button-1917"
							onClick={onClick}
							onTouchStart={addActiveCSSClass}
							onTouchEnd={removeActiveCSSClass}
							onTouchCancel={removeActiveCSSClass}
							ref={this.startButtonRef}
						>
							<div class="left-bracket" />
							<div class="start-button-label">{copy.start}</div>
							<div class="right-bracket" />
							<div class="button-gradient-1917" />
						</div>
					</div>
				</div>
				{this.renderLegals()}
			</div>
		);
	}

	render() {
		const { isMobile } = this.props;

		if (isMobile) {
			return this.renderMobile();
		} else {
			return this.renderDesktop();
		}
	}
}
