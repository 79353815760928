import {
	DataTexture,
	Matrix4,
	Mesh,
	MeshFaceMaterial,
	Frustum,
	Raycaster,
	Vector3,
	Plane,
	ShaderChunk,
	Euler,
	FloatType,
	NearestFilter
} from 'three/build/three.module';

export const axisUp = new Vector3(0, 1, 0);
export const axisDown = new Vector3(0, -1, 0);
export const axisLeft = new Vector3(-1, 0, 0);
export const axisRight = new Vector3(1, 0, 0);
export const axisForward = new Vector3(0, 0, 1);
export const axisBackward = new Vector3(0, 0, -1);
export const vectorZero = new Vector3();
export const DEG2RAD = 180 * Math.PI;
export const RAD2DEG = 180 / Math.PI;
export const { abs } = Math;

export function degreesToRads(degrees) {
	return degrees / DEG2RAD;
}

export function radsToDegrees(radians) {
	return radians * RAD2DEG;
}

export function getDataTexture(data, width, height, format) {
	const tex = new DataTexture(data, width, height, format, FloatType);
	tex.minFilter = NearestFilter;
	tex.magFilter = NearestFilter;
	tex.generateMipmaps = false;
	tex.needsUpdate = true;
	return tex;
}

export function replaceThreeChunkFn(a, b) {
	return `${ShaderChunk[b]}\n`;
}

export function shaderParse(glsl) {
	return glsl.replace(/\/\/\s?chunk\(\s?(\w+)\s?\);/g, replaceThreeChunkFn);
}

export function debugTexture(texture, src) {
	const img = new Image();
	img.src = src || texture.image.src;

	const container = document.createElement('div');
	container.style.position = 'absolute';
	container.style.left = 0;
	container.style.top = 0;
	container.style.zIndex = 1000;
	container.appendChild(img);
	document.body.appendChild(container);
}

export function randomRange(min, max) {
	return min + Math.random() * (max - min);
}

export function rotateAroundWorldAxisX(object, radians, matrix) {
	const rotWorldMatrix = matrix || new Matrix4();
	rotWorldMatrix.identity();
	rotWorldMatrix.makeRotationX(radians);
	rotWorldMatrix.multiply(object.matrix);
	object.matrix.copy(rotWorldMatrix);
	object.rotation.setFromRotationMatrix(object.matrix);
}

export function rotateAroundWorldAxisY(object, radians, matrix) {
	const rotWorldMatrix = matrix || new Matrix4();
	rotWorldMatrix.identity();
	rotWorldMatrix.makeRotationY(radians);
	rotWorldMatrix.multiply(object.matrix);
	object.matrix.copy(rotWorldMatrix);
	object.rotation.setFromRotationMatrix(object.matrix);
}

export function rotateAroundWorldAxisZ(object, radians, matrix) {
	const rotWorldMatrix = matrix || new Matrix4();
	rotWorldMatrix.identity();
	rotWorldMatrix.makeRotationZ(radians);
	rotWorldMatrix.multiply(object.matrix);
	object.matrix.copy(rotWorldMatrix);
	object.rotation.setFromRotationMatrix(object.matrix);
}

export function rotateAroundWorldAxis(object, axis, radians, matrix) {
	const rotWorldMatrix = matrix || new Matrix4();
	rotWorldMatrix.identity();
	rotWorldMatrix.makeRotationAxis(axis.normalize(), radians);
	rotWorldMatrix.multiply(object.matrix);
	object.matrix.copy(rotWorldMatrix);
	object.rotation.setFromRotationMatrix(object.matrix);
}

export function setScale(object, scale) {
	object.scale.set(scale, scale, scale);
}

export function disposeOfMesh(mesh) {
	if (!mesh) return;

	if (mesh.parent) mesh.parent.remove(mesh);
	if (mesh.geometry) mesh.geometry.dispose();
	if (mesh.material) {
		if (mesh.material.map) {
			mesh.material.map.dispose();
		}
		mesh.material.dispose();
	}
}

export function disposeOfChildren(children) {
	if (!children) return;

	while (children.length > 0) {
		disposeOfMesh(children[0]);
	}
}

export function disposeNode(node) {
	// console.log('disposeNode:', node.name);
	if (node instanceof Mesh) {
		if (node.geometry) {
			node.geometry.dispose();
		}

		if (node.material) {
			if (node.material instanceof MeshFaceMaterial) {
				node.material.materials.forEach(mtrl => {
					if (mtrl.map) mtrl.map.dispose();
					if (mtrl.lightMap) mtrl.lightMap.dispose();
					if (mtrl.bumpMap) mtrl.bumpMap.dispose();
					if (mtrl.normalMap) mtrl.normalMap.dispose();
					if (mtrl.specularMap) mtrl.specularMap.dispose();
					if (mtrl.envMap) mtrl.envMap.dispose();
					if (mtrl.emissiveMap) mtrl.emissiveMap.dispose();
					if (mtrl.metalnessMap) mtrl.metalnessMap.dispose();
					if (mtrl.roughnessMap) mtrl.roughnessMap.dispose();

					mtrl.dispose(); // disposes any programs associated with the material
				});
			} else {
				if (node.material.map) node.material.map.dispose();
				if (node.material.lightMap) node.material.lightMap.dispose();
				if (node.material.bumpMap) node.material.bumpMap.dispose();
				if (node.material.normalMap) node.material.normalMap.dispose();
				if (node.material.specularMap) node.material.specularMap.dispose();
				if (node.material.envMap) node.material.envMap.dispose();
				if (node.material.emissiveMap) node.material.emissiveMap.dispose();
				if (node.material.metalnessMap) node.material.metalnessMap.dispose();
				if (node.material.roughnessMap) node.material.roughnessMap.dispose();

				node.material.dispose(); // disposes any programs associated with the material
			}
		}
	}
}

export function disposeHierarchy(node, callback) {
	for (let i = node.children.length - 1; i >= 0; i--) {
		const child = node.children[i];
		disposeHierarchy(child, callback);

		if (typeof callback === 'function') {
			callback(child);
		}
	}
}

export function removeAllChildren(object3d) {
	while (object3d.children.length > 0) {
		object3d.remove(object3d.children[0]);
	}
}

export function moveTowards(object, position, easing) {
	object.position.x += (position.x - object.position.x) * easing;
	object.position.y += (position.y - object.position.y) * easing;
	object.position.z += (position.z - object.position.z) * easing;
}

export function lerpTowards(v1, v2, easing) {
	v1.x += (v2.x - v1.x) * easing;
	v1.y += (v2.y - v1.y) * easing;
	v1.z += (v2.z - v1.z) * easing;
}

export function rotateTowards(object, rotation, easing) {
	object.rotation.x += (rotation.x - object.rotation.x) * easing;
	object.rotation.y += (rotation.y - object.rotation.y) * easing;
	object.rotation.z += (rotation.z - object.rotation.z) * easing;
}

export function updateCameraMatrices(camera) {
	camera.updateMatrix();
	camera.updateMatrixWorld();
	camera.matrixWorldInverse.getInverse(camera.matrixWorld);
}

export function meshIsInView(mesh, camera, frustum) {
	frustum = frustum || new Frustum();
	updateCameraMatrices(camera);

	mesh.updateMatrix();
	mesh.updateMatrixWorld();

	frustum.setFromMatrix(new Matrix4().multiplyMatrices(camera.projectionMatrix, camera.matrixWorldInverse));
	return frustum.intersectsObject(mesh);
}

export function getMouseIntersection(mouse, camera, objects, raycaster) {
	raycaster = raycaster || new Raycaster();

	raycaster.setFromCamera(mouse, camera);
	const intersections = raycaster.intersectObjects(objects);
	return intersections.length > 0 ? intersections[0] : null;
}

export function objectWorldPositionToScreen(object, camera, vector, out, screenWidth, screenHeight) {
	updateCameraMatrices(camera);
	object.updateMatrixWorld(true);
	vector.setFromMatrixPosition(object.matrixWorld);
	vector.project(camera);
	const width = screenWidth || window.innerWidth;
	const height = screenHeight || window.innerHeight;
	out = out || {};
	out.x = (((vector.x + 1) * width) / 2) | 0;
	out.y = (((-vector.y + 1) * height) / 2) | 0;
	return out;
}

export function objectWorldPositionToScreenAlt(object, camera, vector, matrix, out, screenWidth, screenHeight) {
	updateCameraMatrices(camera);
	object.updateMatrixWorld(true);
	const width = screenWidth || window.innerWidth;
	const height = screenHeight || window.innerHeight;
	vector.setFromMatrixPosition(object.matrixWorld);
	matrix.multiplyMatrices(camera.projectionMatrix, camera.matrixWorldInverse);
	vector.applyMatrix4(matrix);
	out = out || {};
	out.x = (((vector.x + 1) * width) / 2) | 0;
	out.y = (((-vector.y + 1) * height) / 2) | 0;
	return out;
}

export function screenToWorld(position, camera) {
	updateCameraMatrices(camera);

	const x = (position.x / window.innerWidth) * 2 - 1;
	const y = -(position.y / window.innerHeight) * 2 + 1;
	const vector = new Vector3(x, y, 0.5);

	vector.unproject(camera);

	const dir = vector.sub(camera.position).normalize();
	const distance = -camera.position.z / dir.z;

	return camera.position.clone().add(dir.multiplyScalar(distance));
}

export function screenToWorldAtZ(position, z, camera) {
	const x = (position.x / window.innerWidth) * 2 - 1;
	const y = -(position.y / window.innerHeight) * 2 + 1;
	const planeZ = new Plane(new Vector3(0, 0, 1), z);
	const vector = new Vector3(x, y, 0.5);
	const raycaster = new Raycaster();

	raycaster.setFromCamera(vector, camera);
	const pos = raycaster.ray.intersectPlane(planeZ);
	return pos;
}

export function getWorldSize(camera) {
	const topLeft = screenToWorld({ x: 0, y: 0 }, camera);
	const bottomRight = screenToWorld({ x: window.innerWidth, y: window.innerHeight }, camera);
	const size = bottomRight.sub(topLeft);

	size.x = Math.abs(size.x);
	size.y = Math.abs(size.y);

	return { width: size.x, height: size.y };
}

export function getWorldSizeAtDistance(camera, distance) {
	const vFOV = degreesToRads(camera.fov); // convert vertical fov to radians
	const height = 2 * Math.tan(vFOV / 2) * Math.abs(distance); // visible height
	const width = height * camera.aspect;
	return { width, height };
}

export function worldToScreen(position, camera, vector) {
	updateCameraMatrices(camera);

	const width = window.innerWidth;
	const height = window.innerHeight;
	const widthHalf = width / 2;
	const heightHalf = height / 2;

	vector = vector || new Vector3();

	vector.copy(position);
	vector.project(camera);

	return {
		x: (vector.x * widthHalf + widthHalf) | 0,
		y: (-(vector.y * heightHalf) + heightHalf) | 0
	};

	// vector.x = ( vector.x * widthHalf ) + widthHalf;
	// vector.y = - ( vector.y * heightHalf ) + heightHalf;
	// return vector;
}

export function getMouseWorldPos(mouseScreenPos, camera, vector1, vector2) {
	vector1.copy(mouseScreenPos);
	vector1.unproject(camera);
	const dir = vector1.sub(camera.position).normalize();
	const distance = -camera.position.z / dir.z;
	vector2.copy(camera.position).add(dir.multiplyScalar(distance));
	return vector2;
}

export function getMouseWorldPosUsingCameraWorldPos(mouseScreenPos, camera, vector1, vector2) {
	vector1.copy(mouseScreenPos);
	vector1.unproject(camera);
	camera.getWorldPosition(vector2);
	const dir = vector1.sub(vector2).normalize();
	const distance = -vector2.z / dir.z;
	vector2.add(dir.multiplyScalar(distance));
	return vector2;
}

export function getMouseWorldPosAtPlane(mouseScreenPos, camera, raycaster, plane, vector) {
	raycaster.setFromCamera(mouseScreenPos, camera);
	raycaster.ray.intersectPlane(plane, vector);
	return vector;
}

export function pointOnSphere(r, a1, a2) {
	return {
		x: r * Math.cos(a1) * Math.sin(a2),
		y: r * Math.sin(a1) * Math.sin(a2),
		z: r * Math.cos(a2)
	};
}

export function getPointsOnSphere(n) {
	const pts = [];
	let pt;

	for (let i = 0; i < n; i++) {
		pt = pointOnSphere(1, Math.random() * Math.PI * 2, Math.random() * Math.PI * 2);
		pts.push(new Vector3(pt.x, pt.y, pt.z));
	}

	return pts;
}

export function getPointsWithinSphere(n, maxRadius) {
	const pts = [];
	let pt;

	for (let i = 0; i < n; i++) {
		pt = pointOnSphere(Math.random() * maxRadius, Math.random() * Math.PI * 2, Math.random() * Math.PI * 2);
		pts.push(new Vector3(pt.x, pt.y, pt.z));
	}

	return pts;
}

export function getPointsOnSphereEvenly(n) {
	const pts = [];
	const inc = Math.PI * (3 - Math.sqrt(5));
	const off = 2.0 / n;
	let x;
	let y;
	let z;
	let r;
	let phi;

	for (let k = 0; k < n; k++) {
		y = k * off - 1 + off / 2;
		r = Math.sqrt(1 - y * y);
		phi = k * inc;
		x = Math.cos(phi) * r;
		z = Math.sin(phi) * r;

		pts.push(new Vector3(x, y, z));
	}
	return pts;
}

export function getRandomVector3(min, max) {
	return new Vector3(randomRange(min, max), randomRange(min, max), randomRange(min, max));
}

export function addRandomVector3(vec, min, max) {
	return getRandomVector3(min, max).add(vec);
}

export function rotationInDegrees(rotation) {
	const degrees = 180 / Math.PI;
	return new Euler(rotation.x * degrees, rotation.y * degrees, rotation.z * degrees, rotation.order);
}

export function getVectorAsArray(vector, round = false, fixed = 2) {
	const f = round ? 0 : fixed;
	return [vector.x.toFixed(f), vector.y.toFixed(f), vector.z.toFixed(f)];
}

export function disableWebglWarnings(renderer) {
	renderer.getContext().getShaderInfoLog = () => '';
}

export function logVector(vector, decimals = 2) {
	console.log(vector.x.toFixed(decimals), vector.y.toFixed(decimals), vector.z.toFixed(decimals));
}

// usage: pass renderer.getContext() & mesh.material.program.program, call render at least once first.
export function logProgramInfo(gl, program) {
	const result = {
		attributes: [],
		uniforms: [],
		attributeCount: 0,
		uniformCount: 0
	};
	const activeUniforms = gl.getProgramParameter(program, gl.ACTIVE_UNIFORMS);
	const activeAttributes = gl.getProgramParameter(program, gl.ACTIVE_ATTRIBUTES);

	// Taken from the WebGl spec:
	// http://www.khronos.org/registry/webgl/specs/latest/1.0/#5.14
	const enums = {
		0x8b50: 'FLOAT_VEC2',
		0x8b51: 'FLOAT_VEC3',
		0x8b52: 'FLOAT_VEC4',
		0x8b53: 'INT_VEC2',
		0x8b54: 'INT_VEC3',
		0x8b55: 'INT_VEC4',
		0x8b56: 'BOOL',
		0x8b57: 'BOOL_VEC2',
		0x8b58: 'BOOL_VEC3',
		0x8b59: 'BOOL_VEC4',
		0x8b5a: 'FLOAT_MAT2',
		0x8b5b: 'FLOAT_MAT3',
		0x8b5c: 'FLOAT_MAT4',
		0x8b5e: 'SAMPLER_2D',
		0x8b60: 'SAMPLER_CUBE',
		0x1400: 'BYTE',
		0x1401: 'UNSIGNED_BYTE',
		0x1402: 'SHORT',
		0x1403: 'UNSIGNED_SHORT',
		0x1404: 'INT',
		0x1405: 'UNSIGNED_INT',
		0x1406: 'FLOAT'
	};

	// Loop through active uniforms
	for (let i = 0; i < activeUniforms; i++) {
		const uniform = gl.getActiveUniform(program, i);
		uniform.typeName = enums[uniform.type];
		result.uniforms.push(uniform);
		result.uniformCount += uniform.size;
	}

	// Loop through active attributes
	for (let i = 0; i < activeAttributes; i++) {
		const attribute = gl.getActiveAttrib(program, i);
		attribute.typeName = enums[attribute.type];
		result.attributes.push(attribute);
		result.attributeCount += attribute.size;
	}

	console.log(result);
}
