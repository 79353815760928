import { h } from 'preact';
import FadingView from '../views/FadingView';
import CSSTransitionGroup from 'preact-css-transition-group';
import { addActiveCSSClass, removeActiveCSSClass } from '../../utils/utils';

import '@styles/components/Button.scss';

export default class MapButton extends FadingView {
	render() {
		const { label, onClick, showLabel = true } = this.props;

		return (
			<div
				class="map-button-1917"
				onClick={onClick}
				onTouchStart={addActiveCSSClass}
				onTouchEnd={removeActiveCSSClass}
				onTouchCancel={removeActiveCSSClass}
				ref={this.ref}
			>
				<CSSTransitionGroup
					component="div"
					transitionName="fade"
					transitionAppear={false}
					transitionEnter={true}
					transitionLeave={true}
				>
					{showLabel && (
						<div key="map-btn" class="map-button-label-1917">
							{label}
						</div>
					)}
				</CSSTransitionGroup>
				<div class="map-button-icon-1917">
					<div class="button-gradient-1917" />
					<div class="map-button-icon-inner-1917" />
				</div>
			</div>
		);
	}
}
