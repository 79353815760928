import { h, Component } from 'preact';

import '@styles/components/RotateMessage.scss';

export default class RotateMessage extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { visible = false, label } = this.props;

		const style = {
			display: visible ? 'block' : 'none'
		};

		return (
			<div class="rotate-message-1917" style={style}>
				<div class="rotate-message-container-1917">
					<div class="rotate-message-icon-1917" />
					<div class="rotate-message-label-1917">{label}</div>
				</div>
			</div>
		);
	}
}
