import { Vector3, CurvePath, LineCurve3, Geometry, Line, LineBasicMaterial } from 'three/build/three.module';
import { disposeNode } from '../utils/three-utils';

export default class CameraPath {
	constructor(props) {
		this.props = props;
		this.init();
	}

	init() {
		const { line, pathOffsetY = 0 } = this.props;
		const { position } = line.geometry.attributes;

		const points = [];
		for (let i = 0; i < position.count; i++) {
			const point = new Vector3().fromBufferAttribute(position, i);
			point.y += pathOffsetY;
			points.push(point);
		}

		this.path = new CurvePath();

		for (let i = 0; i < points.length - 1; i++) {
			const curve = new LineCurve3(points[i], points[i + 1]);
			this.path.add(curve);
		}
	}

	setDebugMode(value = true) {
		if (value) {
			const points = this.path.getPoints(25);
			const geometry = new Geometry();
			points.forEach(pt => {
				geometry.vertices.push(new Vector3(pt.x, pt.y - 0.1, pt.z));
			});
			const material = new LineBasicMaterial({
				color: 0xfe5143
			});
			this.mesh = new Line(geometry, material);
			const { parent } = this.props;
			parent.add(this.mesh);

			return this.mesh;
		} else if (this.mesh) {
			if (this.mesh.parent) this.mesh.parent.remove(this.mesh);
			disposeNode(this.mesh);
		}
	}

	getPositionAt(position) {
		return this.path.getPoint(position);
	}

	getTangent(position) {
		return this.path.getTangent(position);
	}

	getLength() {
		return this.path.getLength();
	}

	dispose() {
		console.log('dispose');
		this.path = null;
		this.props = null;
		this.points = null;
	}
}
