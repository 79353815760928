import Entity from './entity';
import {
	SphereBufferGeometry,
	MeshBasicMaterial,
	Mesh,
	RepeatWrapping,
	Color,
	Math as ThreeMath
} from 'three/build/three.module';
import {
	BACKGROUND_COLOR,
	LOWER_FOG_COLOR_DAMPENING,
	SUNSET_START_COLOR,
	SUNSET_END_COLOR,
	AppProps,
	FOG_COLOR,
	SUNSET_POSITION
} from '../config/constants';
import { map } from '../utils/utils';

import fragmentShader from '../../glsl/skybox.frag';

export default class SkyboxEntity extends Entity {
	init() {
		this.speed = 0.005;

		const geometry = new SphereBufferGeometry(500, 60, 40);
		geometry.scale(-1, 1, 1);

		const { texture } = this.props;

		texture.wrapS = texture.wrapT = RepeatWrapping;

		const material = new MeshBasicMaterial({
			color: BACKGROUND_COLOR,
			map: texture,
			fog: false
		});

		material.onBeforeCompile = shader => {
			shader.uniforms.time = { value: 0 };
			shader.uniforms.speed = { value: this.speed };
			shader.uniforms.fogColorDampening = { value: LOWER_FOG_COLOR_DAMPENING };
			shader.uniforms.sunsetStartColor = { value: new Color(SUNSET_START_COLOR) };
			shader.uniforms.sunsetEndColor = { value: new Color(SUNSET_END_COLOR) };
			shader.uniforms.sunsetPosition = { value: SUNSET_POSITION };
			shader.uniforms.sunsetScale = { value: 0 };

			shader.fragmentShader = fragmentShader;

			this.uniforms = shader.uniforms;

			if (AppProps.state.devControls) {
				this.initDevControls();
			}
		};

		this.mesh = new Mesh(geometry, material);
	}

	initDevControls() {
		const { devControlItems, scene } = AppProps.state;
		const { sceneGroup } = devControlItems;

		if (sceneGroup) {
			const height = 30;
			const fontColor = '#ffffff';

			sceneGroup
				.add('color', {
					name: 'Sunset Start Color',
					type: 'html',
					value: SUNSET_START_COLOR,
					fontColor,
					h: height
				})
				.onChange(v => {
					const [r, g, b] = v;
					this.uniforms.sunsetStartColor.value.setRGB(r, g, b);
					// scene.fog.color.setRGB(r, g, b);
				});

			sceneGroup
				.add('color', {
					name: 'Sunset End Color',
					type: 'html',
					value: SUNSET_END_COLOR,
					fontColor,
					h: height
				})
				.onChange(v => {
					const [r, g, b] = v;
					this.uniforms.sunsetEndColor.value.setRGB(r, g, b);
				});

			sceneGroup
				.add('slide', {
					name: 'Sunset Position',
					min: 0,
					max: 1,
					value: this.uniforms.sunsetPosition.value,
					precision: 3,
					fontColor,
					h: height
				})
				.onChange(v => {
					this.uniforms.sunsetPosition.value = v;
				});

			sceneGroup
				.add('slide', {
					name: 'Sunset Scale',
					min: 0,
					max: 1,
					value: this.uniforms.sunsetScale.value,
					precision: 3,
					fontColor,
					h: height
				})
				.onChange(v => {
					this.uniforms.sunsetScale.value = v;
					const mixVal = ThreeMath.clamp(map(this.uniforms.sunsetScale.value, 0, 0.4, 0, 1), 0, 1);
					scene.fog.color.set(FOG_COLOR);
					scene.fog.color.lerp(this.uniforms.sunsetStartColor.value, mixVal);
				});
		}
	}

	setSunsetScale(scale = 0, force) {
		if (!this.uniforms) return;

		// don't move sunset back down
		if (scale > this.uniforms.sunsetScale.value || force) {
			const { scene } = AppProps.state;
			this.uniforms.sunsetScale.value = scale;
			const mixVal = ThreeMath.clamp(map(this.uniforms.sunsetScale.value, 0, 0.4, 0, 1), 0, 1);
			scene.fog.color.set(FOG_COLOR);
			scene.fog.color.lerp(this.uniforms.sunsetStartColor.value, mixVal);
		}
	}

	resetSunsetScale() {
		this.setSunsetScale(0, true);
	}

	setSpeed(speed) {
		this.speed = speed;
		if (this.uniforms) {
			this.uniforms.speed.value = this.speed;
		}
	}

	update(delta = 0.01) {
		if (this.uniforms) {
			this.uniforms.time.value += delta;
		}
	}
}
