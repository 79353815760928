import { h, createRef } from 'preact';
import FadingView from '../views/FadingView';
import CloseButton from './CloseButton';
import bindAll from 'lodash.bindall';
import { AppProps } from '../../config/constants';
import gsap from 'gsap';
import { addActiveCSSClass, removeActiveCSSClass } from '../../utils/utils';
import TransitionGroup from 'preact-transition-group';
import { URLS } from '../../config/config';

import '@styles/components/MapUI.scss';

export default class MapUI extends FadingView {
	constructor(props) {
		super(props);
		bindAll(this, 'handleClick', 'handleSocialClick', 'handleLegalsClick');
		this.state.canClick = true;
		this.state.lineScalar1 = 0;
		this.state.lineScalar2 = 0;
		this.state.lineScalar3 = 0;
		this.tileRef = createRef();
		this.shadowRef = createRef();
		this.option1Ref = createRef();
		this.option2Ref = createRef();
		this.option3Ref = createRef();
		this.dot1Ref = createRef();
		this.dot2Ref = createRef();
		this.dot3Ref = createRef();
		this.optionHeaderRef = createRef();
	}

	componentDidMount() {
		AppProps.app.setState({
			showInteractionMessage: false,
			showButtonLabels: false
		});
	}

	componentWillUnmount() {
		const tiles = this.tileRef.current;
		if (tiles) {
			tiles.style.filter = null;
		}

		this.tileRef = null;
		this.shadowRef = null;
		this.option1Ref = null;
		this.option2Ref = null;
		this.option3Ref = null;
		this.dot1Ref = null;
		this.dot2Ref = null;
		this.dot3Ref = null;
		this.optionHeaderRef = null;
	}

	transitionIn(callback) {
		if (!this.ref.current) {
			callback();
			return;
		}

		const { duration = 0.6, delay = 0, isMobile } = this.props;
		const { isFirefox, isSafari } = AppProps.state;
		const tiles = this.tileRef.current;
		const shadow = this.shadowRef.current;
		const op1 = this.option1Ref.current;
		const op2 = this.option2Ref.current;
		const op3 = this.option3Ref.current;
		const dot1 = this.dot1Ref.current;
		const dot2 = this.dot2Ref.current;
		const dot3 = this.dot3Ref.current;
		const optionHeader = this.optionHeaderRef.current;

		gsap.fromTo(
			this.ref.current,
			{
				opacity: 0
			},
			{
				duration,
				delay,
				opacity: 1,
				ease: 'power3.inOut',
				onComplete: () => {
					callback();
				}
			}
		);

		// const canAnimate = isMobile || (!isMobile && !(isFirefox || isSafari));
		const canAnimate = isMobile || (!isMobile && !isFirefox);
		if (tiles && canAnimate) {
			gsap.from(tiles, {
				y: 42,
				duration: duration * 2,
				delay: delay,
				ease: 'power3.out'
			});

			gsap.from(shadow, {
				opacity: 0,
				duration: duration * 2,
				delay: delay,
				ease: 'power3.out'
			});
		}

		const delay2 = delay + duration - 0.5;

		if (op1 && op2 && op3) {
			const elements = [op1, op2, op3];
			if (optionHeader) elements.unshift(optionHeader);

			gsap.set(elements, { opacity: 1 });
			gsap.from(elements, {
				y: '+=42',
				opacity: 0,
				duration: duration * 2,
				ease: 'power3.out',
				stagger: 0.2,
				delay: delay2 + 0.5
			});

			const v1 = { v: 0 };
			const v2 = { v: 0 };
			const v3 = { v: 0 };

			gsap.to(v1, {
				v: 1,
				duration: duration * 2,
				ease: 'power3.inOut',
				delay: delay2 + 0.25,
				onUpdate: () => {
					this.setState({ lineScalar1: v1.v });
				}
			});

			gsap.to(v2, {
				v: 1,
				duration: duration * 2,
				ease: 'power3.inOut',
				delay: delay2 + 0.45,
				onUpdate: () => {
					this.setState({ lineScalar2: v2.v });
				}
			});

			gsap.to(v3, {
				v: 1,
				duration: duration * 2,
				ease: 'power3.inOut',
				delay: delay2 + 0.65,
				onUpdate: () => {
					this.setState({ lineScalar3: v3.v });
				}
			});
		}

		if (dot1 && dot2 && dot3) {
			gsap.from([dot1, dot2, dot3], {
				scale: 0,
				duration: duration * 2,
				ease: 'power3.out',
				stagger: 0.2,
				delay: delay + 0.5
			});
		}
	}

	transitionOut(callback) {
		if (!this.ref.current) {
			callback();
			return;
		}

		AppProps.app.setState({ showLegals: false });

		const { duration = 0.6, isMobile } = this.props;

		if (isMobile) {
			gsap.to(this.ref.current, {
				duration,
				opacity: 0,
				ease: 'power3.inOut',
				onComplete: () => {
					callback();
				}
			});
		} else {
			const op1 = this.option1Ref.current;
			const op2 = this.option2Ref.current;
			const op3 = this.option3Ref.current;

			if (op1 && op2 && op3) {
				gsap.to([op1, op2, op3], {
					// y: '+=42',
					opacity: 0,
					duration: duration,
					ease: 'power3.inOut',
					stagger: 0.1
				});

				gsap.to(this.ref.current, {
					duration,
					opacity: 0,
					ease: 'power3.inOut',
					delay: duration * 0.75,
					onComplete: () => {
						callback();
					}
				});
			}
		}
	}

	handleSocialClick(e) {
		switch (e.currentTarget.dataset.id) {
			case 'facebook':
				window.open(URLS.facebook, '_blank');
				break;

			case 'twitter':
				window.open(URLS.twitter, '_blank');
				break;

			case 'instagram':
				window.open(URLS.instagram, '_blank');
				break;

			default:
				break;
		}
	}

	handleLegalsClick(e) {
		AppProps.app.setState({ showLegals: true, legalsSide: 1 });
	}

	handleClick(index) {
		const { canClick } = this.state;

		if (!canClick) return;

		const { sceneManager } = AppProps.state;
		const { closeClick } = this.props;

		this.setState({ canClick: false });

		if (sceneManager.currentTrenchIndex === index) {
			// close map
			closeClick();
			return;
		}

		// show black overlay and loading message
		AppProps.app.setState({
			showLoadingMessage: true,
			fadeToBlack: true,
			fadeToBlackDuration: 0
		});

		// close map
		closeClick();

		// load & init trench
		setTimeout(() => {
			AppProps.app.setState({
				fadeToBlackDuration: 3
			});
			sceneManager.loadTrench(index).then(() => {
				sceneManager.setCurrentTrenchIndex(index);
				sceneManager.initTrench(index);
			});
		}, 1500);
	}

	renderOptions() {
		const { isMobile, isTablet, copy, sectionIndex } = this.props;
		const { trench1, trench2, trench3, tapToTravel } = copy.mapUI;

		const optionClass1 = `map-ui-option-1-1917 ${sectionIndex === 0 ? 'current' : ''}`;
		const optionClass2 = `map-ui-option-2-1917 ${sectionIndex === 1 ? 'current' : ''}`;
		const optionClass3 = `map-ui-option-3-1917 ${sectionIndex === 2 ? 'current' : ''}`;

		let line1Style;
		let line2Style;
		let line3Style;
		let option1Style;
		let option2Style;
		let option3Style;
		let dot1Style;
		let dot2Style;
		let dot3Style;
		let dot1Class;
		let dot2Class;
		let dot3Class;

		const width = window.innerWidth;
		const height = window.innerHeight;
		const refW = isMobile ? 414 : 1920;
		const refH = isMobile ? 800 : 1080;
		const originalRatios = {
			width: width / refW,
			height: height / refH
		};

		const coverRatio = Math.max(originalRatios.width, originalRatios.height);
		const actualWidth = refW * coverRatio;
		const actualHeight = refH * coverRatio;
		const imgRatio = actualWidth / refW;
		const actualLeft = (width - actualWidth) * 0.5;
		const actualTop = (height - actualHeight) * 0.5;

		if (!isMobile) {
			const lineWidth = 360 * imgRatio;
			const { lineScalar1, lineScalar2, lineScalar3 } = this.state;

			line1Style = { width: `${lineWidth * lineScalar1}px` };
			line2Style = { width: `${lineWidth * lineScalar2}px` };
			line3Style = { width: `${lineWidth * lineScalar3}px` };

			option1Style = {
				left: `${actualLeft + 380 * imgRatio}px`
			};

			option2Style = {
				left: `${actualLeft + 582 * imgRatio}px`
			};

			option3Style = {
				left: `${actualLeft + 783 * imgRatio}px`
			};
		} else {
			dot1Style = {
				left: `${actualLeft + 94 * imgRatio}px`,
				top: !isTablet ? `${actualTop + 189 * imgRatio}px` : `calc(${actualTop + 189 * imgRatio}px + 15%)`
			};

			dot2Style = {
				left: `${actualLeft + 206 * imgRatio}px`,
				top: !isTablet ? `${actualTop + 290 * imgRatio}px` : `calc(${actualTop + 290 * imgRatio}px + 15%)`
			};

			dot3Style = {
				left: `${actualLeft + 313 * imgRatio}px`,
				top: !isTablet ? `${actualTop + 394 * imgRatio}px` : `calc(${actualTop + 394 * imgRatio}px + 15%)`
			};

			dot1Class = `map-ui-option-dot ${sectionIndex === 0 ? 'current' : ''}`;
			dot2Class = `map-ui-option-dot ${sectionIndex === 1 ? 'current' : ''}`;
			dot3Class = `map-ui-option-dot ${sectionIndex === 2 ? 'current' : ''}`;
		}

		let mobileDots = isMobile ? (
			<div>
				<div
					class={dot1Class}
					style={dot1Style}
					onClick={() => {
						this.handleClick(0);
					}}
					ref={this.dot1Ref}
				>
					<div class="map-ui-option-dot-border" />
				</div>

				<div
					class={dot2Class}
					style={dot2Style}
					onClick={() => {
						this.handleClick(1);
					}}
					ref={this.dot2Ref}
				>
					<div class="map-ui-option-dot-border" />
				</div>

				<div
					class={dot3Class}
					style={dot3Style}
					onClick={() => {
						this.handleClick(2);
					}}
					ref={this.dot3Ref}
				>
					<div class="map-ui-option-dot-border" />
				</div>
			</div>
		) : null;

		const theRest = (
			<div class="map-ui-option-container-1917">
				{isMobile && (
					<div class="map-ui-option-header" ref={this.optionHeaderRef}>
						{tapToTravel}
					</div>
				)}
				<div
					class={optionClass1}
					style={option1Style}
					ref={this.option1Ref}
					onClick={() => {
						this.handleClick(0);
					}}
					onTouchStart={addActiveCSSClass}
					onTouchEnd={removeActiveCSSClass}
					onTouchCancel={removeActiveCSSClass}
				>
					{!isMobile && (
						<div class="map-ui-option-line" style={line1Style}>
							<div class="map-ui-option-dot">
								<div class="map-ui-option-dot-border" />
							</div>
						</div>
					)}
					<div class="map-ui-option-title">{trench1.title}</div>
					{!isMobile &&
						trench1.hotspots.map(label => {
							return <div class="map-ui-option-hotspot">{`• ${label}`}</div>;
						})}
				</div>
				<div
					class={optionClass2}
					style={option2Style}
					ref={this.option2Ref}
					onClick={() => {
						this.handleClick(1);
					}}
					onTouchStart={addActiveCSSClass}
					onTouchEnd={removeActiveCSSClass}
					onTouchCancel={removeActiveCSSClass}
				>
					{!isMobile && (
						<div class="map-ui-option-line" style={line2Style}>
							<div class="map-ui-option-dot">
								<div class="map-ui-option-dot-border" />
							</div>
						</div>
					)}
					<div class="map-ui-option-title">{trench2.title}</div>
					{!isMobile &&
						trench2.hotspots.map(label => {
							return <div class="map-ui-option-hotspot">{`• ${label}`}</div>;
						})}
				</div>
				<div
					class={optionClass3}
					style={option3Style}
					ref={this.option3Ref}
					onClick={() => {
						this.handleClick(2);
					}}
					onTouchStart={addActiveCSSClass}
					onTouchEnd={removeActiveCSSClass}
					onTouchCancel={removeActiveCSSClass}
				>
					{!isMobile && (
						<div class="map-ui-option-line" style={line3Style}>
							<div class="map-ui-option-dot">
								<div class="map-ui-option-dot-border" />
							</div>
						</div>
					)}
					<div class="map-ui-option-title">{trench3.title}</div>
					{!isMobile &&
						trench3.hotspots.map(label => {
							return <div class="map-ui-option-hotspot">{`• ${label}`}</div>;
						})}
				</div>
			</div>
		);

		return (
			<div>
				{mobileDots}
				{theRest}
			</div>
		);
	}

	renderSocial() {
		return (
			<div class="map-social-container-1917">
				<div
					class="facebook-1917"
					data-id="facebook"
					onClick={this.handleSocialClick}
					onTouchStart={addActiveCSSClass}
					onTouchEnd={removeActiveCSSClass}
					onTouchCancel={removeActiveCSSClass}
				/>
				<div
					class="twitter-1917"
					data-id="twitter"
					onClick={this.handleSocialClick}
					onTouchStart={addActiveCSSClass}
					onTouchEnd={removeActiveCSSClass}
					onTouchCancel={removeActiveCSSClass}
				/>
				<div
					class="instagram-1917"
					data-id="instagram"
					onClick={this.handleSocialClick}
					onTouchStart={addActiveCSSClass}
					onTouchEnd={removeActiveCSSClass}
					onTouchCancel={removeActiveCSSClass}
				/>
			</div>
		);
	}

	render() {
		const { isMobile, closeClick, copy } = this.props;
		const { header } = copy.mapUI;

		return (
			<div class="map-ui-1917" ref={this.ref}>
				<div class="map-ui-shadow-1917" ref={this.shadowRef} />
				<div class="map-ui-tiles-1917" ref={this.tileRef} />
				<div class="map-ui-header">{header}</div>
				{this.renderOptions()}
				{!isMobile && this.renderSocial()}
				<div
					class="map-ui-legals-1917"
					onClick={this.handleLegalsClick}
					onTouchStart={addActiveCSSClass}
					onTouchEnd={removeActiveCSSClass}
					onTouchCancel={removeActiveCSSClass}
				>
					{copy.legals}
				</div>
				<TransitionGroup component="div">
					<CloseButton
						className="close-video-button-inset-1917"
						onClick={closeClick}
						showLabel={false}
						hasProgress={false}
						duration={1}
						delay={0.5}
					/>
				</TransitionGroup>
			</div>
		);
	}
}
