import { Howl, Howler } from 'howler';
import bindAll from 'lodash.bindall';
import EventManager from './event-manager';
import { EVENTS, AppProps } from '../config/constants';
import gsap from 'gsap';

export default class AudioManager {
	constructor(audioConfig) {
		this.audioConfig = audioConfig;
		this.masterVolume = { value: 1 };
		bindAll(this, 'handleVideoStarted', 'handleVideoCompleted');
		EventManager.on(EVENTS.VIDEO_STARTED, this.handleVideoStarted);
		EventManager.on(EVENTS.VIDEO_COMPLETE, this.handleVideoCompleted);
	}

	init() {
		const { muted } = AppProps.state;
		const { bg } = this.audioConfig;

		if (muted) this.mute();

		const bgAudio = new Howl({
			src: bg.media,
			loop: true,
			volume: 0,
			autoplay: false
		});

		this.sounds = {
			bg: {
				id: null,
				sound: bgAudio
			}
		};
	}

	start() {
		if (this.sounds && this.sounds.bg && !this.sounds.bg.sound.playing()) {
			this.sounds.bg.id = this.sounds.bg.sound.play();
			this.sounds.bg.sound.fade(0, 1, 3000);
		}
	}

	mute() {
		Howler.mute(true);
	}

	unmute() {
		Howler.mute(false);

		const { bg } = this.sounds;
		if (bg && !bg.sound.playing()) {
			bg.id = bg.sound.play();
		}
	}

	setMasterVolume(volume) {
		gsap.to(this.masterVolume, {
			duration: 2,
			value: volume,
			ease: 'power2.out',
			onUpdate: () => {
				Howler.volume(this.masterVolume.value);
			}
		});
	}

	handleVideoStarted() {
		if (!this.sounds) return;

		const { bg } = this.sounds;

		if (bg) {
			bg.sound.fade(bg.sound.volume(), 0, 2000);
		}
	}

	handleVideoCompleted() {
		if (!this.sounds) return;

		const { bg } = this.sounds;

		if (bg) {
			bg.sound.fade(bg.sound.volume(), 1, 2000);
		}
	}

	dispose() {
		EventManager.off(EVENTS.VIDEO_STARTED, this.handleVideoStarted);
		EventManager.off(EVENTS.VIDEO_COMPLETE, this.handleVideoCompleted);

		this.sounds.bg.sound.stop();
		this.audioConfig = null;
		this.sounds = null;
		this.masterVolume = null;
	}
}
