import { h, Component, createRef } from 'preact';
import { clamp } from '../../utils/utils';

import '@styles/components/Debug.scss';

export default class Debug extends Component {
	constructor(props) {
		super(props);
		this.ref = createRef();
	}

	componentDidUpdate() {
		const textarea = this.ref.current;
		if (textarea) textarea.scrollTop = textarea.scrollHeight;
	}

	render() {
		const { message } = this.props;

		const textarea = this.ref.current;
		const style = {
			height: textarea ? clamp(textarea.scrollHeight, 38, window.innerHeight * 0.3) + 'px' : '38px'
		};

		return (
			<textarea class="debug-message-1917" style={style} ref={this.ref}>
				{message}
			</textarea>
		);
	}
}
