import { h, Component, createRef } from 'preact';
import bindAll from 'lodash.bindall';
import { AppProps, EVENTS } from '../../config/constants';
import screenfull from 'screenfull';
import EventManager from '../../managers/event-manager';

import '@styles/components/VideoControls.scss';

export default class VideoControls extends Component {
	constructor(props) {
		super(props);
		bindAll(
			this,
			'handleProgressClick',
			'handleVideoTimeUpdate',
			'handlePlayClick',
			'handleFullscreenClick',
			'handleVideoPlay',
			'handleVideoPause',
			'handleVideoEnded'
		);

		this.mainRef = createRef();
		this.playBtnRef = createRef();
		this.fullscreenBtnRef = createRef();
		this.progressRef = createRef();
		this.progressBarRef = createRef();

		this.state = {
			progress: 0,
			playing: false
		};
	}

	componentDidMount() {
		this.bindEvents();
	}

	componentWillUnmount() {
		this.unbindEvents();
	}

	bindEvents() {
		const { videoRef } = this.props;
		const video = videoRef.current;

		if (video) {
			video.addEventListener('timeupdate', this.handleVideoTimeUpdate, false);
			video.addEventListener('play', this.handleVideoPlay, false);
			video.addEventListener('pause', this.handleVideoPause, false);
			video.addEventListener('ended', this.handleVideoEnded, false);
		}

		this.progressRef.current.addEventListener('click', this.handleProgressClick, false);
		this.playBtnRef.current.addEventListener('click', this.handlePlayClick, false);
		this.fullscreenBtnRef.current.addEventListener('click', this.handleFullscreenClick, false);
	}

	unbindEvents() {
		const { videoRef } = this.props;
		const video = videoRef.current;

		if (video) {
			video.removeEventListener('timeupdate', this.handleVideoTimeUpdate);
			video.removeEventListener('play', this.handleVideoPlay);
			video.removeEventListener('pause', this.handleVideoPause);
			video.removeEventListener('ended', this.handleVideoEnded);
		}

		this.progressRef.current.removeEventListener('click', this.handleProgressClick);
		this.playBtnRef.current.removeEventListener('click', this.handlePlayClick);
		this.fullscreenBtnRef.current.removeEventListener('click', this.handleFullscreenClick);
	}

	handlePlayClick(e) {
		const { videoRef } = this.props;
		const video = videoRef.current;

		if (video) {
			video.paused ? video.play() : video.pause();
		}
	}

	handleFullscreenClick(e) {
		this.handleFullscreen();
	}

	handleProgressClick(e) {
		const { videoRef } = this.props;
		const bar = this.progressRef.current;
		const video = videoRef.current;

		if (bar && video) {
			const bounds = bar.getBoundingClientRect();
			const pos = (e.clientX - bounds.x) / bar.clientWidth;
			video.currentTime = pos * video.duration;
		}
	}

	handleVideoPlay(e) {
		this.setState({ playing: true });
	}

	handleVideoPause(e) {
		this.setState({ playing: false });
	}

	handleVideoTimeUpdate(e) {
		const video = e.currentTarget;

		const { currentTime, duration } = video;
		const progress = currentTime / duration;
		this.setState({ progress: progress });
	}

	handleVideoEnded(e) {
		if (screenfull.isFullscreen) this.exitFullscreen();
	}

	handleFullscreen() {
		const { videoRef } = this.props;
		const video = videoRef.current;
		let videoClass;

		if (screenfull.isEnabled && !screenfull.isFullscreen) {
			screenfull.request(video).then(() => {
				if (video.classList.contains('video-1917')) {
					videoClass = 'video-1917';
					video.classList.remove('video-1917');
				}
			});

			screenfull.on('change', () => {
				if (!screenfull.isFullscreen && videoClass) {
					video.classList.add(videoClass);
					EventManager.emit(EVENTS.VIDEO_EXITED_FULLSCREEN);
				}
			});
		}
	}

	exitFullscreen() {
		const { videoRef } = this.props;
		const video = videoRef.current;

		if (screenfull.isEnabled) {
			screenfull.exit();
		}

		video.classList.add('video-1917');
	}

	render() {
		const { progress, playing } = this.state;

		const progressStyle = {
			transform: `scaleX(${progress})`
		};

		const playClassName = playing ? 'video-controls-pause-btn-1917' : 'video-controls-play-btn-1917';

		return (
			<div class="video-controls-1917">
				<div class="video-controls-inner-1917">
					<div class={playClassName} ref={this.playBtnRef} />
					<div class="video-controls-progress-1917" ref={this.progressRef}>
						<div class="video-controls-progress-bg-1917" />
						<div class="video-controls-progress-bar-1917" ref={this.progressBarRef} style={progressStyle} />
					</div>
					<div class="video-controls-fullscreen-btn-1917" ref={this.fullscreenBtnRef} />
				</div>
			</div>
		);
	}
}
