import { h, createRef } from 'preact';
import { addActiveCSSClass, removeActiveCSSClass } from '../../utils/utils';
import Landing from './Landing';
import { AppProps } from '../../config/constants';
import gsap from 'gsap';

import '@styles/components/Button.scss';
import '@styles/components/Video.scss';
import '@styles/components/Landing.scss';

export default class Outro extends Landing {
	constructor(props) {
		super(props);
		this.visitButtonRef = createRef();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
		this.visitButtonRef = null;
	}

	handleCanPlayThrough() {
		const video = this.videoRef.current;
		const { isMobile, config } = this.props;
		const { startTime } = config;

		if (video && video.paused) {
			video.removeEventListener('canplaythrough', this.handleCanPlayThrough);
			video.currentTime = startTime;

			video
				.play()
				.then(() => {
					if (!isMobile) this.updateSizeStates();
				})
				.catch(error => {
					console.log(error);
				});
		}
	}

	transitionIn(callback) {
		if (!this.ref.current) {
			callback();
			return;
		}

		const { duration = 0.6, delay = 0, isMobile } = this.props;
		const { audioManager } = AppProps.state;

		setTimeout(() => {
			// fade out bg audio
			audioManager.handleVideoStarted();
			AppProps.app.setState({ showAudioButton: false });
		}, 250);

		gsap.fromTo(
			this.ref.current,
			{
				opacity: 0
			},
			{
				duration,
				delay,
				opacity: 1,
				ease: 'power3.inOut',
				onComplete: () => {
					callback();
				}
			}
		);

		const message = this.messageRef.current;
		const startBtn = this.startButtonRef.current;
		const visitBtn = this.visitButtonRef.current;

		if (!isMobile) {
			const { clipTransition } = this.state;
			clipTransition.value1 = clipTransition.to1;
			clipTransition.value2 = clipTransition.to2;
			clipTransition.value3 = clipTransition.to3;
			clipTransition.value4 = clipTransition.to4;
			this.setState({ clipTransition });

			const elements = [];
			const facebook = this.facebookRef.current;
			const twitter = this.twitterRef.current;
			const instagram = this.instagramRef.current;

			if (facebook) elements.push(facebook);
			if (twitter) elements.push(twitter);
			if (instagram) elements.push(instagram);

			if (elements.length) {
				gsap.from(elements, {
					x: '+=50',
					opacity: 0,
					duration: 0.9,
					ease: 'power3.inOut',
					stagger: 0.1,
					delay: 0.75,
					onComplete: () => {
						elements.forEach(element => {
							element.style.opacity = null;
							element.style.transform = null;
						});
					}
				});
			}

			if (message && startBtn && visitBtn) {
				gsap.fromTo(
					message,
					{
						y: '+=50',
						opacity: 0
					},
					{
						y: 0,
						opacity: 1,
						duration: 1.2,
						ease: 'power3.out',
						delay: 1.4
					}
				);

				gsap.fromTo(
					[startBtn, visitBtn],
					{
						opacity: 0
					},
					{
						opacity: 1,
						duration: 1.2,
						ease: 'power3.out',
						delay: 2.2,
						stagger: 0.1
					}
				);
			}
		} else {
			if (message && startBtn && visitBtn) {
				gsap.from(message, {
					y: '+=50',
					opacity: 0,
					duration: 1.2,
					ease: 'power3.out',
					delay: 1.4,
					onComplete: () => {
						message.style.opacity = null;
						message.style.transform = null;
					}
				});

				gsap.from([startBtn, visitBtn], {
					opacity: 0,
					duration: 1.2,
					ease: 'power3.out',
					delay: 2.2,
					stagger: 0.1,
					onComplete: () => {
						startBtn.style.opacity = null;
						visitBtn.style.opacity = null;
					}
				});
			}
		}
	}

	transitionOut(callback) {
		super.transitionOut(callback);

		const { audioManager } = AppProps.state;

		setTimeout(() => {
			// fade out bg audio
			audioManager.handleVideoCompleted();
			AppProps.app.setState({ showAudioButton: true });
		}, 250);
	}

	update() {
		const { config } = this.props;
		const { loopStart, loopEnd } = config;
		const video = this.videoRef.current;

		if (video) {
			if (video.currentTime >= loopEnd) {
				// loop at correct point
				video.currentTime = loopStart;
			}
		}

		this.raf = requestAnimationFrame(this.update);
	}

	renderDesktop() {
		const { config, copy, onClick, onVisitClick, commonAssets } = this.props;
		const { media } = config;
		const landingVideo = commonAssets.video ? commonAssets.video.landingVideo : null;
		const {
			videoX,
			videoY,
			videoWidth,
			videoHeight,
			videoWidthRatio,
			videoHeightRatio,
			videoReferenceWidth,
			clipTransition
		} = this.state;

		const { value1, value2 } = clipTransition;

		const containerStyle = {
			clipPath: `polygon(${value1}% 0, 100% 0, 100% 100%, ${value2}% 100%)`,
			left: `${videoX}px`,
			top: `${videoY}px`,
			width: `${videoWidth}px`,
			height: `${videoHeight}px`
		};

		const contentStyle = {
			left: `${1110 * videoWidthRatio}px`,
			top: `${555 * videoHeightRatio}px`
		};

		const messageScale = Math.min(window.innerWidth / videoReferenceWidth, 1);
		const messageStyle = {
			// opacity: value4,
			transform: `scale(${messageScale})`
		};

		const buttonStyle = {
			marginTop: `${20 - (92 * (1 - messageScale) - 20)}px`
		};

		return (
			<div class="landing-1917" ref={this.ref}>
				<video
					src={landingVideo || media}
					ref={this.videoRef}
					class="landing-video-1917"
					muted
					playsinline
					preload="auto"
				/>
				<div class="landing-content-container" style={containerStyle}>
					<div class="landing-content" style={contentStyle} ref={this.contentRef}>
						<div class="landing-message" style={messageStyle} ref={this.messageRef}>
							{copy.outroMessage}
						</div>
						<div class="restart-button-1917" onClick={onClick} style={buttonStyle} ref={this.startButtonRef}>
							<div class="left-bracket" />
							<div class="start-button-label">{copy.startAgain}</div>
							<div class="right-bracket" />
							<div class="button-gradient-1917" />
						</div>
						<div class="visit-button-1917" onClick={onVisitClick} ref={this.visitButtonRef}>
							<div class="left-bracket" />
							<div class="start-button-label">{copy.visit}</div>
							<div class="right-bracket" />
							<div class="button-gradient-1917" />
						</div>
					</div>
				</div>
				<div class="landing-social-container-1917">
					<div class="facebook-1917" data-id="facebook" onClick={this.handleSocialClick} ref={this.facebookRef} />
					<div class="twitter-1917" data-id="twitter" onClick={this.handleSocialClick} ref={this.twitterRef} />
					<div class="instagram-1917" data-id="instagram" onClick={this.handleSocialClick} ref={this.instagramRef} />
				</div>
				{this.renderLegals()}
			</div>
		);
	}

	renderMobile() {
		const { config, copy, onClick, onVisitClick } = this.props;
		const { media } = config;

		return (
			<div class="landing-1917" ref={this.ref}>
				<video
					src={media}
					ref={this.videoRef}
					class="landing-video-1917"
					muted
					playsinline
					autoplay
					loop
					preload="auto"
				/>
				<div class="landing-content-container">
					<div class="landing-content" ref={this.contentRef}>
						<div class="landing-message" ref={this.messageRef}>
							{copy.outroMessage}
						</div>
						<div
							class="restart-button-1917"
							onClick={onClick}
							onTouchStart={addActiveCSSClass}
							onTouchEnd={removeActiveCSSClass}
							onTouchCancel={removeActiveCSSClass}
							ref={this.startButtonRef}
						>
							<div class="left-bracket" />
							<div class="start-button-label">{copy.startAgain}</div>
							<div class="right-bracket" />
							<div class="button-gradient-1917" />
						</div>
						<div
							class="visit-button-1917"
							onClick={onVisitClick}
							onTouchStart={addActiveCSSClass}
							onTouchEnd={removeActiveCSSClass}
							onTouchCancel={removeActiveCSSClass}
							ref={this.visitButtonRef}
						>
							<div class="left-bracket" />
							<div class="start-button-label">{copy.visit}</div>
							<div class="right-bracket" />
							<div class="button-gradient-1917" />
						</div>
					</div>
				</div>
				{this.renderLegals()}
			</div>
		);
	}
}
