import { AppProps } from '../config/constants';

export function sendTrackingEvent({ action, category, label, value }) {
	const { isDevelopment } = AppProps.state;

	const data = {
		event_category: category || '',
		event_label: label || '',
		value: value || ''
	};

	if (isDevelopment) {
		console.log('sendTrackingEvent:', action, data);
	}

	if (window.gtag && !isDevelopment) {
		window.gtag('event', action, data);
	}
}
