import { Vector2 } from 'three/build/three.module';

import vert from '../../glsl/post-shader.vert';
import frag from '../../glsl/post-shader.frag';

const PostShader = {
	uniforms: {
		tDiffuse: { value: null },

		// lut
		tLookup: { value: null },

		// fxaa
		resolution: { value: new Vector2() },
		renderFXAA: { value: 1 }, // for safari

		// grain
		grainIntensity: { value: 0.025 },
		time: { value: 0 }
	},
	vertexShader: vert,
	fragmentShader: frag
};

export default PostShader;
