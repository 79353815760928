import { isAndroid, isHTTPS, isChrome, getChromeVersion } from './utils';
import { CHROME_MIN_VERSION } from '../config/constants';

// Copyright 2018 Google Inc. All Rights Reserved.
// Licensed under the Apache License, Version 2.0 (the 'License');
// https://github.com/GoogleWebComponents/model-viewer/blob/v0.5.0/src/features/ar.ts

export const openIOSARQuickLook = (() => {
	const anchor = document.createElement('a');
	anchor.setAttribute('rel', 'ar');
	anchor.appendChild(document.createElement('img'));

	return usdzSrc => {
		anchor.setAttribute('href', usdzSrc);
		anchor.click();
	};
})();

// NOTE: Android AR will only work if served over https with a valid certificate
// Use ngrok or similar for local testing
export const openAndroidARViewer = (() => {
	const anchor = document.createElement('a');
	const noArViewerSigil = '#model-viewer-no-ar-fallback';
	let fallbackInvoked = false;

	return (gltfSrc, title) => {
		// If the fallback has ever been invoked this session, bounce early:
		if (fallbackInvoked) {
			return;
		}

		const location = self.location.toString();
		const locationUrl = new URL(location);
		const modelUrl = new URL(gltfSrc, self.location.href); // base fixes invalid URL error?
		const link = encodeURIComponent(location);
		const scheme = modelUrl.protocol.replace(':', '');

		locationUrl.hash = noArViewerSigil;

		title = encodeURIComponent(title);
		modelUrl.protocol = 'intent://';

		const intent = `${modelUrl.toString()}?link=${link}&title=${title}#Intent;scheme=${scheme};package=com.google.ar.core;action=android.intent.action.VIEW;S.browser_fallback_url=${encodeURIComponent(
			locationUrl.toString()
		)};end;`;

		const undoHashChange = () => {
			if (self.location.hash === noArViewerSigil && !fallbackInvoked) {
				fallbackInvoked = true;
				// The new history will be the current URL with a new hash.
				// Go back one step so that we reset to the expected URL.
				// NOTE(cdata): this should not invoke any browser-level navigation
				// because hash-only changes modify the URL in-place without
				// navigating:
				self.history.back();
			}
		};

		self.addEventListener('hashchange', undoHashChange, { once: true });

		anchor.setAttribute('href', intent);
		anchor.click();
	};
})();

export const supportsARiOS = () => {
	const a = document.createElement('a');
	const supportsAR = a.relList.supports('ar') ? true : false;
	return supportsAR;
};

export const supportsARAndroid = () => {
	return isAndroid() && isHTTPS() && isChrome() && getChromeVersion() >= CHROME_MIN_VERSION;
};
