import Entity from './entity';
import { AppProps } from '../config/constants';
import { PlaneBufferGeometry, MeshBasicMaterial, Mesh, NearestFilter, Group } from 'three/build/three.module';
import gsap from 'gsap';

import dottedLineFrag from '../../glsl/hotspot-dotted-line.frag';

export default class HotSpotEntity extends Entity {
	init() {
		const {
			labelKey,
			numKey,
			iconKey,
			position,
			showPosition,
			lookAtPosition,
			rotation,
			textures,
			cameraPath,
			elementPositions
		} = this.props;
		const { commonAssets, isMobile } = AppProps.state;
		const {
			buttonFill,
			buttonLines,
			buttonOutline,
			hotspotDottedLine,
			hotspotDottedLineVertical,
			hotspotXMarker,
			hotspotDash
		} = commonAssets.textures;

		this.scale = isMobile ? 0.0015 : 0.001;
		const scalar = elementPositions.type === 'right' ? -1 : 1;

		this.mesh = new Group();

		const isVertical = elementPositions.type === 'vertical';
		const backingWidth = (!isVertical ? 895 : 520) * this.scale;
		const backingHeight = (!isVertical ? 367 : 760) * this.scale;
		const backing = new Mesh(
			new PlaneBufferGeometry(backingWidth * 1.2, backingHeight * 1.4),
			new MeshBasicMaterial({ color: 0xff0000, visible: false })
		);
		if (!isVertical) {
			backing.position.set(backingWidth * 0.5 * -scalar, backingHeight * 0.5, 0);
		} else {
			backing.position.set(0, backingHeight * 0.5, 0);
		}
		this.mesh.add(backing);

		const xMarker = this.getMeshForTexture(hotspotXMarker);
		this.mesh.add(xMarker);

		const dottedLine = this.getMeshForTexture(
			elementPositions.type === 'vertical' ? hotspotDottedLineVertical : hotspotDottedLine,
			elementPositions.dottedLine.x,
			elementPositions.dottedLine.y
		);
		dottedLine.scale.x = scalar;
		dottedLine.material.onBeforeCompile = shader => {
			shader.uniforms.maskVal = { value: 0 };
			shader.fragmentShader = dottedLineFrag;
			this.dottendLineUniforms = shader.uniforms;
		};
		this.mesh.add(dottedLine);

		const diagonalLines = this.getMeshForTexture(
			buttonLines,
			elementPositions.diagonalLines.x,
			elementPositions.diagonalLines.y,
			-50
		);
		diagonalLines.material.depthWrite = false;
		this.mesh.add(diagonalLines);

		const fill = this.getMeshForTexture(buttonFill, elementPositions.fill.x, elementPositions.fill.y);
		fill.scale.set(0.5, 0.5, 1);
		this.mesh.add(fill);

		const outline = this.getMeshForTexture(buttonOutline, elementPositions.outline.x, elementPositions.outline.y);
		this.mesh.add(outline);

		const icon = this.getMeshForTexture(commonAssets.textures[iconKey], elementPositions.icon.x, elementPositions.icon.y, 5);
		this.mesh.add(icon);

		let dash;
		let num;
		let label;

		if (numKey) {
			dash = this.getMeshForTexture(hotspotDash, elementPositions.dash.x, elementPositions.dash.y);
			this.mesh.add(dash);
			num = this.getMeshForTexture(textures[numKey], elementPositions.num.x, elementPositions.num.y);
			this.mesh.add(num);
		}

		if (labelKey) {
			label = this.getMeshForTexture(textures[labelKey], elementPositions.label.x, elementPositions.label.y);
			this.mesh.add(label);
		}

		if (position) this.mesh.position.fromArray(position);
		if (rotation) this.mesh.rotation.fromArray(rotation);

		this.setOpacity([fill, diagonalLines, outline, icon, dash, num, label], 0);

		this.state = {
			transitionedIn: false,
			elements: {
				backing: backing,
				xMarker: xMarker,
				dottedLine: dottedLine,
				diagonalLines: diagonalLines,
				fill: fill,
				outline: outline,
				icon: icon,
				dash: dash,
				num: num,
				label: label
			}
		};

		this.hide();

		// if no rotation in config, look at show position
		if (!rotation) {
			const lookAt = cameraPath.getPositionAt(lookAtPosition || showPosition);
			this.mesh.lookAt(lookAt);
		}
	}

	setOpacity(meshes, opacity = 0) {
		meshes.forEach(mesh => {
			if (mesh) mesh.material.opacity = opacity;
		});
	}

	isTransitionedIn() {
		return this.state.transitionedIn;
	}

	getHitDetectionMesh() {
		return this.state.elements.backing;
	}

	getMeshForTexture(texture, xPixels = 0, yPixels = 0, zPixels = 0) {
		const { anisotropy } = AppProps.state;

		texture.minFilter = texture.magFilter = NearestFilter; // non-POT
		texture.anistropy = anisotropy;
		texture.needsUpdate = true;

		const size = this.getTextureSize(texture, this.scale);
		const geometry = new PlaneBufferGeometry(size.width, size.height);
		const material = new MeshBasicMaterial({ map: texture, transparent: true, fog: false, depthWrite: false });
		const mesh = new Mesh(geometry, material);

		this.setPosition(mesh, xPixels, yPixels, zPixels);

		return mesh;
	}

	getTextureSize(texture, scale = 1) {
		return {
			width: texture.image.naturalWidth * scale,
			height: texture.image.naturalHeight * scale
		};
	}

	setPosition(mesh, xPixels = 0, yPixels = 0, zPixels = 0) {
		mesh.position.set(xPixels, yPixels, zPixels).multiplyScalar(this.scale);
	}

	hide() {
		const { elements } = this.state;
		for (let key in elements) {
			if (key !== 'xMarker') {
				if (elements[key]) elements[key].visible = false;
			}
		}
	}

	show() {
		const { elements } = this.state;
		for (let key in elements) {
			if (key !== 'xMarker') {
				if (elements[key]) elements[key].visible = true;
			}
		}
	}

	transitionIn() {
		this.show();

		let ready = false;
		let readyInterval;

		const whenReady = () => {
			if (this.dottendLineUniforms) ready = true;

			if (ready) {
				clearInterval(readyInterval);

				const { diagonalLines, outline, icon, dash, num, label } = this.state.elements;

				const timeline = new gsap.timeline({
					onComplete: () => {
						this.state.transitionedIn = true;
					}
				});

				// dotted line mask in
				timeline.add(
					gsap.fromTo(
						this.dottendLineUniforms.maskVal,
						{
							value: 0
						},
						{
							duration: 1.2,
							value: 0.75,
							ease: 'power3.inOut'
						}
					)
				);

				// dash, num, label, diagonalLines, outline, icon;
				let batch1 = [diagonalLines.material, outline.material, icon.material];
				let batch2 = [diagonalLines.position, outline.position, icon.position];

				if (dash) {
					batch1.push(dash.material);
					batch2.push(dash.position);
				}
				if (num) {
					batch1.push(num.material);
					batch2.push(num.position);
				}
				if (label) {
					batch1.push(label.material);
					batch2.push(label.position);
				}

				timeline.add(
					[
						gsap.fromTo(
							batch1,
							{
								opacity: 0
							},
							{
								opacity: 1,
								duration: 0.3,
								ease: 'power3.out',
								stagger: 0.2
							}
						),
						gsap.from(batch2, {
							y: '-=0.025',
							duration: 0.3,
							ease: 'power3.out',
							stagger: 0.2
						}),
						gsap.fromTo(
							[diagonalLines.scale, outline.scale, icon.scale],
							{
								x: 0.001,
								y: 0.001
							},
							{
								x: 1,
								y: 1,
								duration: 0.6,
								ease: 'power3.inOut',
								stagger: 0.2
							}
						)
					],
					0.6 // start {n} seconds into timeline, slightly earlier than normal
				);
			}
		};

		readyInterval = setInterval(whenReady, 50);
	}

	mouseOver(duration = 0.45) {
		const { transitionedIn, elements } = this.state;

		if (!transitionedIn) return;

		const { fill } = elements;
		const ease = 'power3.out';

		gsap.to(fill.scale, {
			duration,
			x: 1,
			y: 1,
			ease: ease
		});

		gsap.to(fill.material, {
			duration,
			opacity: 1,
			ease: ease
		});
	}

	mouseOut(duration = 0.45) {
		const { transitionedIn, elements } = this.state;

		if (!transitionedIn) return;

		const { fill } = elements;
		const ease = 'power3.out';

		gsap.to(fill.scale, {
			duration,
			x: 0.5,
			y: 0.5,
			ease: ease
		});

		gsap.to(fill.material, {
			duration,
			opacity: 0,
			ease: ease
		});
	}

	update() {
		const { controls } = AppProps.state;
		const { showPosition } = this.props;
		const { backing } = this.state.elements;

		if (!backing.visible) {
			const { pathPosition } = controls;
			if (pathPosition >= showPosition) {
				this.transitionIn();
			}
		}
	}

	reset() {
		const { fill, diagonalLines, outline, icon, dash, num, label } = this.state.elements;
		this.setOpacity([fill, diagonalLines, outline, icon, dash, num, label], 0);
		fill.scale.set(0.5, 0.5, 1);
		if (this.dottendLineUniforms) this.dottendLineUniforms.maskVal.value = 0;
		this.state.transitionedIn = false;
		this.hide();
	}

	dispose() {
		super.dispose();

		this.state = null;
		this.dottendLineUniforms = null;
	}
}
