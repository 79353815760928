import { h, Component, createRef } from 'preact';
import gsap from 'gsap';
import { AppProps, EVENTS, VIDEO_STYLES, VIDEO_ALMOST_COMPLETE_OFFSET } from '../../config/constants';
import bindAll from 'lodash.bindall';
import EventManager from '../../managers/event-manager';
import TransitionGroup from 'preact-transition-group';
import CloseButton from '../ui/CloseButton';
import VideoControls from './VideoControls';
import screenfull from 'screenfull';
import { sendTrackingEvent } from '../../utils/tracking';

import '@styles/components/Video.scss';

export default class Video extends Component {
	constructor(props) {
		super(props);
		bindAll(
			this,
			'handleVideoStarted',
			'handleVideoComplete',
			'handleVideoSelected',
			'seekToEnd',
			'setVideoOpacity',
			'resumeVideo',
			'handleMute',
			'handleUnmute',
			'handleSkip',
			'handleVideoTimeUpdate',
			'handleGoHome',
			'handleFullscreenChange',
			'handleEndFullscreen',
			'updateVideoSizeState'
		);
		this.containerRef = createRef();
		this.videoRef = createRef();
		this.wrapperRef = createRef();
		this.dashedContainerRef = createRef();
		this.state = {
			transitionedIn: false,
			skipped: false,
			progress: 0,
			videoWidth: 0,
			videoHeight: 0,
			goingHome: false
		};
	}

	componentDidMount() {
		const video = this.videoRef.current;

		const { muted, isMobile, isSafari, isAndroid, isIOS } = AppProps.state;
		const { videoStyle = VIDEO_STYLES.TRANSITION } = this.props;
		let muteVideo;

		if (videoStyle !== VIDEO_STYLES.TRANSITION) {
			muteVideo = false;
		} else {
			muteVideo = muted || isMobile || isSafari;
		}

		if (video) {
			video.crossOrigin = 'Anonymous';
			video.muted = muteVideo;
			video.addEventListener('ended', this.handleVideoComplete, false);
			video.addEventListener('play', this.handleVideoStarted, false);
			video.addEventListener('timeupdate', this.handleVideoTimeUpdate, false);

			if (isMobile) {
				video.addEventListener('touchend', this.handleVideoSelected, false);
				if (isIOS) {
					video.addEventListener('webkitendfullscreen', this.handleEndFullscreen);
				} else {
					video.addEventListener('fullscreenchange', this.handleFullscreenChange);
				}
			} else {
				video.addEventListener('click', this.handleVideoSelected, false);
			}

			video.currentTime = 0;

			this.startVideo();

			// request fullscreen on android for hotspot videos
			if (isAndroid && videoStyle !== VIDEO_STYLES.TRANSITION && screenfull.isEnabled) {
				screenfull.request(video);
			}
		}

		if (AppProps.state.isDevelopment) {
			EventManager.on(EVENTS.VIDEO_SEEK_TO_END, this.seekToEnd);
			EventManager.on(EVENTS.SET_VIDEO_OPACITY, this.setVideoOpacity);
			EventManager.on(EVENTS.RESUME_VIDEO, this.resumeVideo);
		}

		EventManager.on(EVENTS.MUTE_AUDIO, this.handleMute);
		EventManager.on(EVENTS.UNMUTE_AUDIO, this.handleUnmute);
		EventManager.on(EVENTS.VIDEO_EXITED_FULLSCREEN, this.updateVideoSizeState);
		EventManager.once(EVENTS.GO_HOME, this.handleGoHome);
	}

	componentWillUnmount() {
		const video = this.videoRef.current;
		const { isMobile, isIOS } = AppProps.state;

		if (video) {
			video.removeEventListener('ended', this.handleVideoComplete);
			video.removeEventListener('play', this.handleVideoStarted);
			video.removeEventListener('timeupdate', this.handleVideoTimeUpdate);

			if (isMobile) {
				video.removeEventListener('touchend', this.handleVideoSelected);
				if (isIOS) {
					video.removeEventListener('webkitendfullscreen', this.handleEndFullscreen);
				} else {
					video.removeEventListener('fullscreenchange', this.handleFullscreenChange);
				}
			} else {
				video.removeEventListener('click', this.handleVideoSelected);
			}
		}

		EventManager.off(EVENTS.VIDEO_SEEK_TO_END, this.seekToEnd);
		EventManager.off(EVENTS.SET_VIDEO_OPACITY, this.setVideoOpacity);
		EventManager.off(EVENTS.RESUME_VIDEO, this.resumeVideo);
		EventManager.off(EVENTS.MUTE_AUDIO, this.handleMute);
		EventManager.off(EVENTS.UNMUTE_AUDIO, this.handleUnmute);
		EventManager.off(EVENTS.GO_HOME, this.handleGoHome);
		EventManager.off(EVENTS.VIDEO_EXITED_FULLSCREEN, this.updateVideoSizeState);

		this.videoRef = null;
		this.containerRef = null;
		this.wrapperRef = null;
		this.dashedContainerRef = null;
	}

	componentDidUpdate(prevProps) {
		if (prevProps.screenWidth !== this.props.screenWidth || prevProps.screenHeight !== this.props.screenHeight) {
			this.updateVideoSizeState();
		}
	}

	updateVideoSizeState() {
		const video = this.videoRef.current;

		if (video) {
			const bounds = video.getBoundingClientRect();
			const ratio = bounds.height / video.videoHeight;
			this.setState({ videoWidth: video.videoWidth * ratio, videoHeight: video.videoHeight * ratio });
		}
	}

	handleMute() {
		const video = this.videoRef.current;
		if (video && !video.muted) {
			video.muted = true;
			console.log('handleMute:', video.muted);
		}
	}

	handleUnmute() {
		const video = this.videoRef.current;
		if (video && video.muted) {
			video.muted = false;
			console.log('handleUnmute:', video.muted);
		}
	}

	handleGoHome() {
		this.setState({ goingHome: true });
		this.handleSkip();
	}

	handleFullscreenChange(e) {
		if (!document.fullscreenElement) {
			this.handleSkip();
		}
	}

	handleEndFullscreen() {
		this.handleSkip();
	}

	handleSkip() {
		if (this.state.skipped) return;

		this.setState({ skipped: true });

		this.pause();
		this.handleVideoComplete();

		const { videoStyle, id } = this.props;

		if (id && videoStyle === VIDEO_STYLES.TRANSITION) {
			sendTrackingEvent({
				action: 'click',
				category: `transition video ${id}`,
				label: 'skip',
				value: `skip-${id}-click`
			});
		}
	}

	handleVideoSelected() {
		const video = this.videoRef.current;

		if (!video) return;

		const { videoStyle } = this.props;

		// toggle play if content video
		if (videoStyle !== VIDEO_STYLES.TRANSITION) {
			video.paused ? video.play() : video.pause();
		} else if (video.paused) {
			// if transition video doesn't autoplay for some reason, force play on select.
			this.play();
		}

		// unmute video if global setting isn't muted but video is.
		const { muted } = AppProps.state;
		if (!muted && video.muted) video.muted = false;
	}

	handleVideoStarted() {
		const video = this.videoRef.current;

		if (video) {
			video.removeEventListener('play', this.handleVideoStarted);
		}

		this.updateVideoSizeState();

		EventManager.emit(EVENTS.VIDEO_STARTED);
		EventManager.emit(EVENTS.PAUSE);
	}

	handleVideoTimeUpdate(e) {
		const video = this.videoRef.current;

		let progress = 0;

		if (video) {
			progress = video.currentTime / video.duration;

			if (video.currentTime >= video.duration - VIDEO_ALMOST_COMPLETE_OFFSET) {
				EventManager.emit(EVENTS.VIDEO_ALMOST_COMPLETE);
			}
		}

		this.setState({ progress });
	}

	handleVideoComplete() {
		const video = this.videoRef.current;
		const { skipTransitionOut } = this.props;

		if (video) {
			video.removeEventListener('ended', this.handleVideoComplete);
			video.removeEventListener('play', this.handleVideoStarted);
		}

		EventManager.emit(EVENTS.RESUME);

		const finish = () => {
			AppProps.app.setState({
				videoSource: null
			});
		};

		if (skipTransitionOut) {
			EventManager.emit(EVENTS.VIDEO_COMPLETE);
			EventManager.emit(EVENTS.VIDEO_ALMOST_COMPLETE);
			this.transitionOut().then(() => {
				finish();
			});
		} else {
			this.transitionOut().then(() => {
				finish();
				EventManager.emit(EVENTS.VIDEO_COMPLETE);
				EventManager.emit(EVENTS.VIDEO_ALMOST_COMPLETE);
			});
		}
	}

	startVideo() {
		const video = this.videoRef.current;

		const { isIntro } = this.props;
		const duration = isIntro ? 0.2 : 1;

		if (video && video.paused) {
			if (video.readyState < 4) {
				const readyStateInterval = setInterval(() => {
					if (video.readyState >= 2 && !this.state.transitionedIn) {
						this.transitionIn(duration);
					}

					if (video.readyState === 4) {
						clearInterval(readyStateInterval);
						if (AppProps.state.autoplayVideos) {
							this.play();
						} else {
							this.pause();
						}
					}
				}, 100);
			} else {
				this.transitionIn(duration);
				this.play();
			}
		}
	}

	play() {
		const video = this.videoRef.current;

		if (video && video.paused) {
			video.play().catch(error => {
				console.log(error);
			});
		}
	}

	pause() {
		const video = this.videoRef.current;

		if (video && !video.paused) {
			video.pause();
		}
	}

	transitionIn(duration = 1, delay = 0) {
		this.setState({ transitionedIn: true });

		AppProps.app.setState({
			showFullUI: false
		});

		return new Promise(resolve => {
			const element = this.containerRef.current;
			const wrapper = this.wrapperRef.current;
			const dashedContainer = this.dashedContainerRef.current;

			if (wrapper) {
				gsap.fromTo(
					wrapper,
					{
						scale: 1.25
					},
					{
						scale: 1,
						duration,
						ease: 'power3.out',
						delay
					}
				);
			}

			if (dashedContainer) {
				gsap.fromTo(
					dashedContainer,
					{
						scale: 0.6
					},
					{
						scale: 1,
						duration,
						ease: 'power3.out',
						delay
					}
				);
			}

			if (element) {
				gsap.fromTo(
					element,
					{
						opacity: 0
					},
					{
						duration,
						opacity: 1,
						ease: 'power2.inOut',
						delay,
						onComplete: () => {
							EventManager.emit(EVENTS.VIDEO_TRANSITIONED_IN);
							resolve();
						}
					}
				);
			} else {
				resolve();
			}
		});
	}

	transitionOut(duration = 1, delay = 0) {
		EventManager.emit(EVENTS.VIDEO_TRANSITION_OUT_STARTED);

		return new Promise(resolve => {
			const element = this.containerRef.current;
			const wrapper = this.wrapperRef.current;
			const dashedContainer = this.dashedContainerRef.current;

			if (wrapper) {
				gsap.to(wrapper, {
					scale: 0.85,
					duration,
					ease: 'power3.inOut',
					delay,
					onComplete: () => {
						gsap.set(wrapper, { scale: 1 });
					}
				});
			}

			if (dashedContainer) {
				gsap.to(dashedContainer, {
					scale: 0.95,
					duration,
					ease: 'power3.inOut',
					delay,
					onComplete: () => {
						gsap.set(dashedContainer, { scale: 1 });
					}
				});
			}

			if (element) {
				gsap.to(element, {
					duration,
					opacity: 0,
					ease: 'power2.inOut',
					delay,
					onComplete: () => {
						if (!this.state.goingHome) {
							AppProps.app.setState({
								showFullUI: true
							});
						}

						EventManager.emit(EVENTS.VIDEO_TRANSITIONED_OUT);
						resolve();
					}
				});
			}
		});
	}

	renderVideoElement() {
		const { src, videoStyle = VIDEO_STYLES.TRANSITION } = this.props;
		const { isMobile, muted } = AppProps.state;

		let videoElement;

		if (isMobile) {
			if (videoStyle === VIDEO_STYLES.TRANSITION) {
				// full screen inline for transitions
				videoElement = (
					<video key={src} src={src} class="video-transition-1917" ref={this.videoRef} autoplay muted playsinline />
				);
			} else {
				// // native player for content on mobile, muted or not.
				// if (muted) {
				// 	videoElement = <video key={src} src={src} class="video-1917" ref={this.videoRef} autoplay muted />;
				// } else {
				// 	videoElement = <video key={src} src={src} class="video-1917" ref={this.videoRef} autoplay />;
				// }

				videoElement = <video key={src} src={src} class="video-1917" ref={this.videoRef} autoplay />;
			}
		} else {
			// desktop
			const className = videoStyle === VIDEO_STYLES.TRANSITION ? 'video-transition-1917' : 'video-1917';
			videoElement = <video key={src} src={src} class={className} ref={this.videoRef} autoplay playsinline />;
		}

		return videoElement;
	}

	// fullscreen transition videos, no controls.
	renderTransitionVideo() {
		return (
			<div class="video-main-1917" ref={this.containerRef}>
				<div class="video-container-1917">{this.renderVideoElement()}</div>
				{this.renderCloseButton()}
			</div>
		);
	}

	// inset hotspot videos, with controls.
	renderContentVideo() {
		const { isMobile } = AppProps.state;
		const { videoWidth, videoHeight } = this.state;
		const style = {};

		if (videoWidth && videoHeight) {
			style.width = `${videoWidth}px`;
			style.height = `${videoHeight}px`;
		}

		return (
			<div class="video-main-1917" ref={this.containerRef}>
				<div class="video-outer-wrapper-1917" ref={this.wrapperRef}>
					{!isMobile && (
						<div class="video-dashed-corner-container-1917" style={style} ref={this.dashedContainerRef}>
							<div class="video-dashed-corner-1917-tl" />
							<div class="video-dashed-corner-1917-tr" />
							<div class="video-dashed-corner-1917-bl" />
							<div class="video-dashed-corner-1917-br" />
						</div>
					)}
					<div class="video-container-inset-1917">{this.renderVideoElement()}</div>
					{!isMobile && (
						<div class="video-controls-container-inset-1917" style={style}>
							{this.renderControls()}
						</div>
					)}
				</div>
				{this.renderCloseButton()}
			</div>
		);
	}

	renderCloseButton() {
		const { showButtonLabels, showIntro } = this.props;
		const { copy } = AppProps.state;
		const { progress } = this.state;

		let className = 'close-video-button-inset-1917';

		return (
			<TransitionGroup component="div">
				<CloseButton
					duration={1}
					delay={showIntro ? 0 : 1}
					className={className}
					label={copy.skip}
					onClick={this.handleSkip}
					showLabel={showButtonLabels}
					progress={progress}
				/>
			</TransitionGroup>
		);
	}

	renderControls() {
		return (
			<TransitionGroup component="div">
				<VideoControls videoRef={this.videoRef} />
			</TransitionGroup>
		);
	}

	render() {
		const { videoStyle = VIDEO_STYLES.TRANSITION } = this.props;

		if (videoStyle === VIDEO_STYLES.TRANSITION) {
			return this.renderTransitionVideo();
		} else {
			return this.renderContentVideo();
		}
	}

	// dev methods
	seekToEnd(pause) {
		const video = this.videoRef.current;

		if (video) {
			if (pause) video.removeEventListener('ended', this.handleVideoComplete);
			video.currentTime = video.duration;
		}
	}

	setVideoOpacity(opacity = 1) {
		const video = this.videoRef.current;

		if (video) {
			video.style.opacity = opacity;
		}
	}

	resumeVideo() {
		this.play();
	}
}
