import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import PostShader from '../post/post-shader';
import { AppProps } from '../config/constants';

export default class EffectsManager {
	constructor(props) {
		this.props = props;
		this.enabled = true;

		const { renderer, camera, scene } = this.props;
		const { pixelRatio, commonAssets, isFirefox } = AppProps.state;
		const { lut } = commonAssets.textures;

		this.composer = new EffectComposer(renderer);

		const renderPass = new RenderPass(scene, camera);

		this.mainPass = new ShaderPass(PostShader);
		this.mainPass.uniforms.resolution.value.set(1 / window.innerWidth, 1 / window.innerHeight); // fxaa
		this.mainPass.uniforms.tLookup.value = lut; // Look up texture
		this.mainPass.uniforms.renderFXAA.value = isFirefox ? 0 : 1; // fxaa
		this.mainPass.uniforms.grainIntensity.value = 0.025 * pixelRatio; // grain

		this.composer.addPass(renderPass);
		this.composer.addPass(this.mainPass);

		setTimeout(() => {
			if (AppProps.state.devControls) {
				this.initDevControls();
			}
		}, 50);
	}

	initDevControls() {
		const { devControls } = AppProps.state;

		const height = 30;
		const fontColor = '#ffffff';
		const group = devControls.add('group', { name: 'POST PROCESSING', h: 30 });

		group
			.add('bool', {
				name: 'Enabled',
				value: this.enabled,
				fontColor
			})
			.onChange(v => {
				this.enabled = v;
			});

		group
			.add('bool', {
				name: 'Antialiasing Enabled',
				value: this.mainPass.uniforms.renderFXAA.value === 1,
				fontColor
			})
			.onChange(v => {
				this.mainPass.uniforms.renderFXAA.value = v ? 1 : 0;
			});

		group
			.add('slide', {
				name: 'Grain Intensity',
				min: 0,
				max: 0.1,
				value: this.mainPass.uniforms.grainIntensity.value,
				precision: 4,
				fontColor,
				h: height
			})
			.onChange(v => {
				this.mainPass.uniforms.grainIntensity.value = v;
			});
	}

	render(delta = 0.01) {
		if (this.enabled) {
			if (this.mainPass) {
				this.mainPass.uniforms.time.value += 0.01; // grain
			}
			this.composer.render(delta);
		} else {
			const { renderer, camera, scene } = this.props;
			renderer.render(scene, camera);
		}
	}

	resize(width = window.innerWidth, height = window.innerHeight) {
		if (this.composer) {
			this.composer.setSize(width, height);
		}

		if (this.mainPass) {
			this.mainPass.uniforms.resolution.value.set(1 / width, 1 / height);
		}
	}

	dispose() {
		console.log('dispose');
		this.props = null;
		this.mainPass = null;
		this.composer = null;
		this.enabled = null;
	}
}
