import Loader from '../utils/loader';
import { disposeNode, disposeHierarchy } from '../utils/three-utils';

export default class Entity {
	constructor(props) {
		this.props = props;
		this.loaded = false;
		this.loading = false;
	}

	load() {
		this.loading = true;

		return new Promise(resolve => {
			if (this.loaded) {
				this.loading = false;
				resolve(this);
			} else {
				const { basePath, manifest } = this.props;

				manifest.forEach(item => {
					item.file = `${basePath}${item.file}`;
				});

				const loader = new Loader();

				loader.load(manifest, files => {
					this.assets = files;
					this.loaded = true;
					this.loading = false;
					loader.dispose();
					if (!this.disposed) {
						resolve(this);
					}
				});
			}
		});
	}

	init() {}

	hide() {
		if (this.mesh) this.mesh.visible = false;
	}

	show() {
		if (this.mesh) this.mesh.visible = true;
	}

	transitionIn() {}

	transitionOut() {}

	update() {}

	dispose() {
		if (this.mesh) {
			disposeHierarchy(this.mesh, disposeNode);
			disposeNode(this.mesh);
			if (this.mesh.parent) {
				this.mesh.parent.remove(this.mesh);
			}
		}

		this.props = null;
		this.loaded = null;
		this.loading = null;
		this.assets = null;
		this.mesh = null;
		this.disposed = true;
	}
}
