import { h } from 'preact';
import FadingView from './FadingView';

import '@styles/components/Blackout.scss';

export default class Blackout extends FadingView {
	// start fully opaque to avoid transition in flicker
	componentWillAppear(callback) {
		callback();
	}

	render() {
		return <div class="blackout-1917" ref={this.ref} />;
	}
}
