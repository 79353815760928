import {
	BufferGeometry,
	Color,
	Float32BufferAttribute,
	Points,
	ShaderMaterial,
	Vector3,
	NormalBlending
} from 'three/build/three.module';
import Entity from './entity';
import { randomRange, randomInt } from '../utils/utils';
import { AppProps, MAX_FOG_VALUE } from '../config/constants';

import vertexShader from '../../glsl/dust.vert';
import fragmentShader from '../../glsl/dust.frag';

export default class DustParticlesEntity extends Entity {
	init() {
		const {
			cameraPath,
			amount,
			textures = [],
			maxPathOffset = { x: 0, y: 0, z: 0 },
			colors,
			minSize = 1,
			maxSize = 1,
			minSpeed = { x: 0.005, y: 0.005, z: 0.005 },
			maxSpeed = { x: 0.01, y: 0.01, z: 0.01 },
			minRotationSpeed = 0.5,
			maxRotationSpeed = 1.5,
			opacity = 1,
			blending = NormalBlending,
			pathStart = 0,
			pathEnd = 1,
			maxDistZ = 5
		} = this.props;

		textures.forEach(tex => {
			tex.needsUpdate = true;
		});

		const geometry = new BufferGeometry();
		const positionsAttribute = [];
		const colorsAttribute = [];
		const sizesAttribute = [];
		const speedsAttribute = [];
		const rotationAttribute = [];
		const rotationSpeedAttribute = [];
		const timeOffsetsAttribute = [];
		const textureIndexAttribute = [];

		const color = new Color();
		const add = new Vector3();

		const { scene, pixelRatio } = AppProps.state;

		for (let i = 0; i < amount; i++) {
			add.set(
				randomRange(-maxPathOffset.x, maxPathOffset.x),
				randomRange(-maxPathOffset.y, maxPathOffset.y),
				randomRange(-maxPathOffset.z, maxPathOffset.z)
			);

			const pathPos = cameraPath.getPositionAt(randomRange(pathStart, pathEnd)).add(add);
			positionsAttribute.push(pathPos.x, pathPos.y, pathPos.z);
			color.setHex(colors[(Math.random() * colors.length) | 0]);
			colorsAttribute.push(color.r, color.g, color.b);
			sizesAttribute.push(randomRange(minSize, maxSize) * pixelRatio);
			speedsAttribute.push(
				randomRange(minSpeed.x, maxSpeed.x),
				randomRange(minSpeed.y, maxSpeed.y),
				randomRange(minSpeed.z, maxSpeed.z)
			);
			rotationAttribute.push(randomRange(0, Math.PI * 2));
			timeOffsetsAttribute.push(randomRange(0, 1));
			textureIndexAttribute.push(randomInt(0, textures.length));
			rotationSpeedAttribute.push(randomRange(minRotationSpeed, maxRotationSpeed));
		}

		// clean up attribute arrays after upload
		const cleanArray = function() {
			this.array = null;
		};

		geometry.setAttribute('position', new Float32BufferAttribute(positionsAttribute, 3).onUpload(cleanArray));
		geometry.setAttribute('color', new Float32BufferAttribute(colorsAttribute, 3).onUpload(cleanArray));
		geometry.setAttribute('size', new Float32BufferAttribute(sizesAttribute, 1).onUpload(cleanArray));
		geometry.setAttribute('speed', new Float32BufferAttribute(speedsAttribute, 3).onUpload(cleanArray));
		geometry.setAttribute('rotation', new Float32BufferAttribute(rotationAttribute, 1).onUpload(cleanArray));
		geometry.setAttribute('rotationSpeed', new Float32BufferAttribute(rotationSpeedAttribute, 1).onUpload(cleanArray));
		geometry.setAttribute('timeOffset', new Float32BufferAttribute(timeOffsetsAttribute, 1).onUpload(cleanArray));
		geometry.setAttribute('textureIndex', new Float32BufferAttribute(textureIndexAttribute, 1).onUpload(cleanArray));

		const uniforms = {
			diffuseColor: { value: new Color(0xffffff) },
			time: { value: 0 },
			amplitude: { value: 1 },
			speedScalar: { value: 1 },
			opacityScalar: { value: opacity },
			fogColor: { value: scene.fog.color },
			fogDensity: { value: scene.fog.density },
			maxFogValue: { value: MAX_FOG_VALUE },
			minCamDist: { value: 0.1 },
			maxCamDist: { value: 1.0 },
			maxDistZ: { value: maxDistZ }
		};

		if (textures[0]) uniforms.tDiffuse0 = { value: textures[0] };
		if (textures[1]) uniforms.tDiffuse1 = { value: textures[1] };
		if (textures[2]) uniforms.tDiffuse2 = { value: textures[2] };

		const material = new ShaderMaterial({
			uniforms,
			vertexShader,
			fragmentShader,
			transparent: true,
			depthWrite: false,
			blending
		});

		this.uniforms = material.uniforms;
		this.mesh = new Points(geometry, material);
		this.mesh.name = 'particles';
	}

	initDevControls() {
		const { devControls } = AppProps.state;

		if (devControls) {
			const height = 30;
			const fontColor = '#ffffff';
			const group = devControls.add('group', { name: 'DUST PARTICLES', h: height });

			group
				.add('bool', {
					name: 'Enabled',
					value: true,
					fontColor
				})
				.onChange(v => {
					if (v) this.show();
					else this.hide();
				});

			group
				.add('color', {
					name: 'Tint',
					type: 'html',
					value: this.uniforms.diffuseColor.value.getHex(),
					fontColor,
					h: height
				})
				.onChange(v => {
					const [r, g, b] = v;
					this.uniforms.diffuseColor.value.setRGB(r, g, b);
				});

			this.particleControls = group;
		}
	}

	removeDevControls() {
		const { devControls } = AppProps.state;

		if (devControls && this.particleControls) {
			devControls.remove(this.particleControls);
			this.particleControls = null;
		}
	}

	update(delta = 0.01) {
		if (this.uniforms) {
			this.uniforms.time.value += delta;
		}
	}

	dispose() {
		this.uniforms = null;
		const { textures } = this.props;
		if (textures) {
			textures.forEach(tex => {
				tex.dispose();
			});
		}
		super.dispose();
	}
}
