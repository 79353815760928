/*
 * ALL MAJOR SETTINGS FOR THE EXPERIENCE
 * Update static asset paths in `PATHS`
 * Update urls in `URLS`
 * Assets loaded up front are defined in `commonManifest`
 * All other settings follow below
 */

import { PARTICLE_TYPES, TRIGGER_TYPES, CAMERA_ROTATION_TRIGGER_DURATION, HOTSPOT_TYPES } from './constants';
import { isMobile, isFirefox, isTablet } from '../utils/utils';

const isDev = process.env.NODE_ENV === 'development';
const mobile = isMobile();
const firefox = isFirefox();
const textureDevicePath = mobile ? 'mobile/' : 'desktop/';
const devicePath = mobile ? 'mobile/' : 'desktop/';

const PATHS = {
	localesPath: 'locales/',
	imagePath: 'images/',
	audioPath: 'audio/',
	videoPath: isDev ? 'video/' : 'https://dx35vtwkllhj9.cloudfront.net/universalstudios/1917-trench/',
	geometryPath: 'geometry/'
};

const URLS = {
	visit: 'https://www.1917.movie',
	tickets: 'https://www.1917.movie',
	facebook: 'https://www.facebook.com/1917Film/',
	twitter: 'https://www.twitter.com/1917/',
	instagram: 'https://www.instagram.com/1917/',
	morseCode: 'https://www.youtube.com/watch?time_continue=1&v=KQfybDxplPw&feature=emb_title',
	privacy: 'https://www.nbcuniversal.com/privacy?brandA=Universal_Pictures&intake=Universal_Pictures',
	feedback: 'http://www.universalstudios.com/contact_form.php',
	terms: 'http://www.nbcuniversal.com/terms/',
	mpa: 'https://www.motionpictures.org/',
	ratings: 'https://www.filmratings.com/'
};

const commonManifest = [
	{
		file: `${PATHS.imagePath}${textureDevicePath}skybox.jpg`,
		id: 'skyboxTexture'
	},
	{
		file: `${PATHS.imagePath}${textureDevicePath}dust-particle-01.png`,
		id: 'dustParticle01'
	},
	{
		file: `${PATHS.imagePath}${textureDevicePath}dust-particle-02.png`,
		id: 'dustParticle02'
	},
	{
		file: `${PATHS.imagePath}${textureDevicePath}dust-particle-03.png`,
		id: 'dustParticle03'
	},
	{
		file: `${PATHS.imagePath}${textureDevicePath}smoke-particle-01.jpg`,
		id: 'smokeParticle01'
	},
	{
		file: `${PATHS.imagePath}${textureDevicePath}smoke-particle-02.jpg`,
		id: 'smokeParticle02'
	},
	{
		file: `${PATHS.imagePath}${textureDevicePath}smoke-particle-03.jpg`,
		id: 'smokeParticle03'
	},
	{
		file: `${PATHS.imagePath}common/lookup.png`,
		id: 'lut'
	},
	{
		file: `${PATHS.imagePath}common/ui/button-fill.png`,
		id: 'buttonFill'
	},
	{
		file: `${PATHS.imagePath}common/ui/button-lines.png`,
		id: 'buttonLines'
	},
	{
		file: `${PATHS.imagePath}common/ui/button-outline.png`,
		id: 'buttonOutline'
	},
	{
		file: `${PATHS.imagePath}common/ui/hotspot-dotted-line.png`,
		id: 'hotspotDottedLine'
	},
	{
		file: `${PATHS.imagePath}common/ui/hotspot-dotted-line-vertical.png`,
		id: 'hotspotDottedLineVertical'
	},
	{
		file: `${PATHS.imagePath}common/ui/hotspot-x-marker.png`,
		id: 'hotspotXMarker'
	},
	{
		file: `${PATHS.imagePath}common/ui/hotspot-dash.png`,
		id: 'hotspotDash'
	},
	{
		file: `${PATHS.imagePath}common/ui/play-button-icon.png`,
		id: 'playIcon'
	},
	{
		file: `${PATHS.imagePath}common/ui/mission-button-icon.png`,
		id: 'missionIcon'
	},
	{
		file: `${PATHS.imagePath}common/ui/link-button-icon.png`,
		id: 'linkIcon'
	},
	{
		file: `${PATHS.imagePath}common/ui/gif-button-icon.png`,
		id: 'gifIcon'
	},
	{
		file: `${PATHS.imagePath}${textureDevicePath}foot-left.png`,
		id: 'leftFootTex'
	},
	{
		file: `${PATHS.imagePath}${textureDevicePath}foot-right.png`,
		id: 'rightFootTex'
	}
];

const dustParticleConfig = {
	type: PARTICLE_TYPES.DUST,
	textureIds: ['dustParticle01', 'dustParticle02', 'dustParticle03'],
	amount: mobile || firefox ? 12500 : 15000,
	maxPathOffset: { x: 1.5, y: 2, z: 0 },
	colors: [0x0d0f19, 0x1e1525, 0x16070c, 0x896b49, 0x0d0f19, 0x1e1525, 0x16070c],
	minSize: 0.015,
	maxSize: 0.03,
	minSpeed: { x: 0.01, y: 0.015, z: 0.0075 },
	maxSpeed: { x: 0.12, y: 0.12, z: 0.015 },
	minRotationSpeed: -2,
	maxRotationSpeed: 2,
	opacity: 1
};

const smokeParticleConfig = {
	type: PARTICLE_TYPES.SMOKE,
	textureIds: ['smokeParticle01', 'smokeParticle02', 'smokeParticle03'],
	amount: mobile || firefox ? 35 : 50,
	maxPathOffset: { x: 1.25, y: 0.5, z: 0 },
	colors: [0x1d201f, 0x2c2326],
	minSize: 1,
	maxSize: 2,
	minSpeed: { x: 0.05, y: 0.05, z: 0.025 },
	maxSpeed: { x: 0.2, y: 0.2, z: 0.075 },
	minRotationSpeed: -0.25,
	maxRotationSpeed: 0.25,
	opacity: 0.5,
	meshSize: 1.5
};

const objectSmokeParticleConfig = {
	type: PARTICLE_TYPES.OBJECT_SMOKE,
	textureIds: ['smokeParticle01', 'smokeParticle02', 'smokeParticle03'],
	amount: mobile || firefox ? 15 : 20,
	colors: [0x1d201f, 0x2c2326],
	maxPathOffset: { x: 0.125, y: 0, z: 0.125 },
	basePosition: [2.3, -0.04, -98.22],
	minSize: 1,
	maxSize: 4,
	minSpeed: { x: 0.05, y: 0.6, z: 0.025 },
	maxSpeed: { x: 0.2, y: 0.8, z: 0.075 },
	minRotationSpeed: -0.5,
	maxRotationSpeed: 0.5,
	opacity: 0.5,
	meshSize: 0.6
};

const trenchEndSmokeParticleConfig = {
	type: PARTICLE_TYPES.SMOKE,
	textureIds: ['smokeParticle01', 'smokeParticle02', 'smokeParticle03'],
	amount: 10,
	maxPathOffset: { x: 0.5, y: 0, z: 0 },
	colors: [0x1d201f, 0x2c2326],
	minSize: 1.5,
	maxSize: 2,
	minSpeed: { x: 0.05, y: 0.05, z: 0.05 },
	maxSpeed: { x: 0.2, y: 0.2, z: 0.15 },
	minRotationSpeed: -0.25,
	maxRotationSpeed: 0.25,
	opacity: 0.5,
	meshSize: 1.5,
	pathStart: 0.9,
	pathEnd: 1,
	maxDistZ: 1.5
};

const hotspotPositionConfig = {
	left: {
		type: 'left',
		dottedLine: {
			x: -315,
			y: 92
		},
		diagonalLines: {
			x: -731,
			y: 62
		},
		outline: {
			x: -731,
			y: 62
		},
		fill: {
			x: -711,
			y: 82
		},
		icon: {
			x: -706,
			y: 82
		},
		dash: {
			x: -774,
			y: 269
		},
		num: {
			x: -614,
			y: 269
		},
		label: {
			x: -456,
			y: 216
		}
	},
	right: {
		type: 'right',
		dottedLine: {
			x: 318,
			y: 92
		},
		diagonalLines: {
			x: 688,
			y: 62
		},
		outline: {
			x: 688,
			y: 62
		},
		fill: {
			x: 709,
			y: 82
		},
		icon: {
			x: 713,
			y: 82
		},
		dash: {
			x: 771,
			y: 269
		},
		num: {
			x: 612,
			y: 269
		},
		label: {
			x: 454,
			y: 216
		}
	},
	vertical: {
		type: 'vertical',
		dottedLine: {
			x: 0,
			y: 167
		},
		diagonalLines: {
			x: -19,
			y: 368
		},
		outline: {
			x: -19,
			y: 368
		},
		fill: {
			x: 2,
			y: 388
		},
		icon: {
			x: 5,
			y: 388
		},
		dash: {
			x: -63,
			y: 592
		},
		num: {
			x: 99,
			y: 592
		},
		label: {
			x: 76,
			y: 529
		}
	},
	verticalStoryboard: {
		type: 'vertical',
		dottedLine: {
			x: 0,
			y: 167
		},
		diagonalLines: {
			x: -19,
			y: 368
		},
		outline: {
			x: -19,
			y: 368
		},
		fill: {
			x: 2,
			y: 388
		},
		icon: {
			x: 5,
			y: 388
		},
		dash: {
			x: -63,
			y: 592
		},
		num: {
			x: 99,
			y: 592
		},
		label: {
			x: 0,
			y: 529
		}
	}
};

const config = {
	landing: {
		media: `${PATHS.videoPath}${devicePath}landing.mp4`,
		loopStart: 2,
		loopEnd: 5.2,
		videoTransitionStartTime: 0,
		videoTransitionEndTime: 1.8
	},
	intro: {
		media: `${PATHS.videoPath}${devicePath}intro.mp4`
	},
	outro: {
		media: `${PATHS.videoPath}${devicePath}landing.mp4`,
		startTime: 2,
		loopStart: 2,
		loopEnd: 5.2
	},
	legal: {
		links: [
			{
				labelKey: 'privacy',
				url: URLS.privacy
			},
			{
				labelKey: 'feedback',
				url: URLS.feedback
			},
			{
				labelKey: 'terms',
				url: URLS.terms
			},
			{
				labelKey: 'mpa',
				url: URLS.mpa
			},
			{
				labelKey: 'ratings',
				url: URLS.ratings
			}
		]
	},
	audio: {
		bg: {
			media: [`${PATHS.audioPath}bg-audio.mp3`, `${PATHS.audioPath}bg-audio.webm`, `${PATHS.audioPath}bg-audio.ogg`]
		}
	},
	trenches: [
		{
			id: 1,
			rootMesh: null,
			meshesToRemove: ['Text'],
			manifest: [
				{
					file: `${PATHS.geometryPath}trenches/${textureDevicePath}part_06/part_06_0.gltf`,
					id: 'geometry'
				},
				{
					file: `${PATHS.geometryPath}trenches/${textureDevicePath}part_06/trench_path.obj`,
					id: 'path'
				},
				{
					file: `${PATHS.imagePath}common/ui/hotspot-label-01.png`,
					id: 'hotspotLabel01'
				},
				{
					file: `${PATHS.imagePath}common/ui/hotspot-num-01.png`,
					id: 'hotspotNum01'
				},
				{
					file: `${PATHS.imagePath}common/ui/hotspot-label-02.png`,
					id: 'hotspotLabel02'
				},
				{
					file: `${PATHS.imagePath}common/ui/hotspot-num-02.png`,
					id: 'hotspotNum02'
				},
				{
					file: `${PATHS.imagePath}common/ui/hotspot-storyboard-to-screen.png`,
					id: 'hotspotLabelStoryboard'
				},
				{
					file: `${PATHS.geometryPath}trenches/${textureDevicePath}part_06/part_06_end_card.png`,
					id: 'endCard'
				}
			],
			ar: {
				ios: `${PATHS.geometryPath}ar/part_06/part_06_0.usdz`,
				android: `${PATHS.geometryPath}ar/part_06/part_06_0.glb`
			},
			particles: [dustParticleConfig, smokeParticleConfig, trenchEndSmokeParticleConfig],
			endCards: [
				{
					meshId: 'part_07',
					textureId: 'endCard'
				}
			],
			triggers: [
				{
					type: TRIGGER_TYPES.CAMERA_CHANGE,
					transitionDuration: CAMERA_ROTATION_TRIGGER_DURATION,
					position: 0,
					triggered: false,
					rotationOffset: [0, -Math.PI * 0.1, 0],
					rotationReset: [0, 0, 0]
				},
				{
					type: TRIGGER_TYPES.CAMERA_CHANGE,
					transitionDuration: CAMERA_ROTATION_TRIGGER_DURATION,
					position: 0.75,
					triggered: false,
					rotationOffset: [0, 0, 0],
					rotationReset: [0, -Math.PI * 0.1, 0]
				},
				{
					id: 1,
					type: TRIGGER_TYPES.MEDIA,
					position: 0.9,
					media: `${PATHS.videoPath}${devicePath}transition-1.mp4`,
					rotationOffset: [0, 0, 0],
					triggered: false,
					last: true
				}
			],
			hotspots: [
				{
					id: 0,
					type: HOTSPOT_TYPES.VIDEO,
					media: `${PATHS.videoPath}${devicePath}trench-1-hotspot-1.mp4`,
					position: [0.4, 0.94, -52.68],
					labelKey: 'hotspotLabel01',
					numKey: 'hotspotNum01',
					iconKey: 'playIcon',
					elementPositions: hotspotPositionConfig.left,
					showPosition: 0.2
				},
				{
					id: 1,
					type: HOTSPOT_TYPES.VIDEO,
					media: `${PATHS.videoPath}${devicePath}trench-1-hotspot-2.mp4`,
					position: [-1.1, 0.94, -56.68],
					rotation: [0, -0.35, 0],
					labelKey: 'hotspotLabel02',
					numKey: 'hotspotNum02',
					iconKey: 'missionIcon',
					elementPositions: hotspotPositionConfig.left,
					showPosition: 0.4
				},
				{
					id: 2,
					type: HOTSPOT_TYPES.VIDEO_4x5,
					media: `${PATHS.videoPath}${devicePath}trench-1-hotspot-3.mp4`,
					position: [-0.08, 0.39, -62.3],
					rotation: [0, -0.63, 0],
					iconKey: 'gifIcon',
					labelKey: 'hotspotLabelStoryboard',
					elementPositions: hotspotPositionConfig.verticalStoryboard,
					showPosition: 0.64
				}
			],
			startPosition: 0.1,
			pathOffsetY: 0,
			footstepAnimation: {
				positionOffset: [0.35, -0.9, -0.9],
				rotation: [0, -0.25, 0]
			}
		},
		{
			id: 2,
			rootMesh: 'Root',
			manifest: [
				{
					file: `${PATHS.geometryPath}trenches/${textureDevicePath}part_04/part_04_0.gltf`,
					id: 'geometry'
				},
				{
					file: `${PATHS.geometryPath}trenches/${textureDevicePath}part_04/trench_path.obj`,
					id: 'path'
				},
				{
					file: `${PATHS.imagePath}common/ui/hotspot-label-04.png`,
					id: 'hotspotLabel04'
				},
				{
					file: `${PATHS.imagePath}common/ui/hotspot-num-04.png`,
					id: 'hotspotNum04'
				},
				{
					file: `${PATHS.imagePath}common/ui/hotspot-storyboard-to-screen.png`,
					id: 'hotspotLabelStoryboard'
				},
				{
					file: `${PATHS.imagePath}common/ui/hotspot-label-07.png`,
					id: 'hotspotLabel07'
				},
				{
					file: `${PATHS.imagePath}common/ui/hotspot-num-07.png`,
					id: 'hotspotNum07'
				},
				{
					file: `${PATHS.geometryPath}trenches/${textureDevicePath}part_04/part_04_top_card.png`,
					id: 'endCardTop'
				},
				{
					file: `${PATHS.geometryPath}trenches/${textureDevicePath}part_04/part_04_bottom_card.png`,
					id: 'endCardBottom'
				}
			],
			ar: {
				ios: `${PATHS.geometryPath}ar/part_04/part_04_0.usdz`,
				android: `${PATHS.geometryPath}ar/part_04/part_04_0.glb`
			},
			particles: [dustParticleConfig, smokeParticleConfig, trenchEndSmokeParticleConfig],
			endCards: [
				{
					meshId: 'top_end_card',
					textureId: 'endCardTop'
				},
				{
					meshId: 'bottom_end_card',
					textureId: 'endCardBottom'
				}
			],
			sunsetScaleRange: {
				from: 0.1,
				scaleMin: 0,
				to: 0.5,
				scaleMax: 0.3
			},
			triggers: [
				{
					type: TRIGGER_TYPES.CAMERA_CHANGE,
					transitionDuration: CAMERA_ROTATION_TRIGGER_DURATION,
					position: 0,
					triggered: false,
					rotationOffset: [0, Math.PI * 0.15, 0],
					rotationReset: [0, 0, 0]
				},
				{
					type: TRIGGER_TYPES.CAMERA_CHANGE,
					transitionDuration: CAMERA_ROTATION_TRIGGER_DURATION,
					position: 0.3,
					triggered: false,
					rotationOffset: [0, 0, 0],
					rotationReset: [0, Math.PI * 0.15, 0]
				},
				{
					id: 2,
					type: TRIGGER_TYPES.MEDIA,
					position: 0.85,
					media: `${PATHS.videoPath}${devicePath}transition-2.mp4`,
					rotationOffset: [0, 0, 0],
					triggered: false,
					last: true
				}
			],
			hotspots: [
				{
					id: 3,
					type: HOTSPOT_TYPES.VIDEO,
					media: `${PATHS.videoPath}${devicePath}trench-2-hotspot-1.mp4`,
					position: [-5.12, 1.78, -9.73],
					labelKey: 'hotspotLabel04',
					numKey: 'hotspotNum04',
					iconKey: 'playIcon',
					elementPositions: hotspotPositionConfig.right,
					showPosition: 0.14
				},
				{
					id: 4,
					type: HOTSPOT_TYPES.VIDEO_4x5,
					media: `${PATHS.videoPath}${devicePath}trench-2-hotspot-2.mp4`,
					position: [-2.27, 0.9, -11.27],
					rotation: [0, -0.58, 0],
					iconKey: 'gifIcon',
					labelKey: 'hotspotLabelStoryboard',
					elementPositions: hotspotPositionConfig.verticalStoryboard,
					showPosition: 0.25
				},
				{
					id: 6,
					type: HOTSPOT_TYPES.VIDEO,
					media: `${PATHS.videoPath}${devicePath}trench-2-hotspot-4.mp4`,
					position: [-2.31, 0.45, -20.35],
					labelKey: 'hotspotLabel07',
					numKey: 'hotspotNum07',
					iconKey: 'playIcon',
					elementPositions: hotspotPositionConfig.vertical,
					alignment: 'left',
					showPosition: 0.65
				}
			],
			startPosition: 0.1,
			pathOffsetY: 0
		},
		{
			id: 3,
			rootMesh: 'Root',
			manifest: [
				{
					file: `${PATHS.geometryPath}trenches/${textureDevicePath}part_08/part_08_0.gltf`,
					id: 'geometry'
				},
				{
					file: `${PATHS.geometryPath}trenches/${textureDevicePath}part_08/trench_path.obj`,
					id: 'path'
				},
				{
					file: `${PATHS.imagePath}common/ui/hotspot-label-09.png`,
					id: 'hotspotLabel09'
				},
				{
					file: `${PATHS.imagePath}common/ui/hotspot-num-09.png`,
					id: 'hotspotNum09'
				},
				{
					file: `${PATHS.imagePath}common/ui/hotspot-label-11.png`,
					id: 'hotspotLabel11'
				},
				{
					file: `${PATHS.imagePath}common/ui/hotspot-num-11.png`,
					id: 'hotspotNum11'
				},
				{
					file: `${PATHS.imagePath}common/ui/hotspot-label-06.png`,
					id: 'hotspotLabel06'
				},
				{
					file: `${PATHS.imagePath}common/ui/hotspot-num-06.png`,
					id: 'hotspotNum06'
				},
				{
					file: `${PATHS.imagePath}common/ui/hotspot-storyboard-to-screen.png`,
					id: 'hotspotLabelStoryboard'
				},
				{
					file: `${PATHS.geometryPath}trenches/${textureDevicePath}part_08/part_08_end_card.png`,
					id: 'endCard'
				}
			],
			ar: {
				ios: `${PATHS.geometryPath}ar/part_08/part_08_0.usdz`,
				android: `${PATHS.geometryPath}ar/part_08/part_08_0.glb`
			},
			particles: [
				dustParticleConfig,
				smokeParticleConfig,
				{
					...trenchEndSmokeParticleConfig,
					maxPathOffset: { x: 1.5, y: 1, z: 0 },
					maxDistZ: 0,
					pathStart: 0.85
				},
				objectSmokeParticleConfig
			],
			endCards: [
				{
					meshId: 'end_card',
					textureId: 'endCard'
				}
			],
			sunsetScaleRange: {
				value: 0.3
			},
			triggers: [
				{
					type: TRIGGER_TYPES.CAMERA_CHANGE,
					transitionDuration: CAMERA_ROTATION_TRIGGER_DURATION,
					position: 0,
					triggered: false,
					rotationOffset: [0, 0, 0],
					rotationReset: [0, 0, 0]
				},

				{
					type: TRIGGER_TYPES.CAMERA_CHANGE,
					transitionDuration: CAMERA_ROTATION_TRIGGER_DURATION,
					position: 0.52,
					triggered: false,
					rotationOffset: [0, -Math.PI * 0.4, 0],
					rotationReset: [0, 0, 0]
				},
				{
					id: 3,
					type: TRIGGER_TYPES.MEDIA,
					position: 0.85,
					media: `${PATHS.videoPath}${devicePath}outro.mp4`,
					skipTransitionOut: true,
					rotationOffset: [0, -Math.PI * 0.4, 0],
					triggered: false,
					last: true
				}
			],
			hotspots: [
				{
					id: 8,
					type: HOTSPOT_TYPES.VIDEO,
					media: `${PATHS.videoPath}${devicePath}trench-3-hotspot-2.mp4`,
					position: [1.95, 0.37, -95.51],
					labelKey: 'hotspotLabel09',
					numKey: 'hotspotNum09',
					iconKey: 'playIcon',
					elementPositions: hotspotPositionConfig.right,
					showPosition: 0.24,
					lookAtPosition: 0.3
				},
				{
					id: 9,
					type: HOTSPOT_TYPES.VIDEO_4x5,
					media: `${PATHS.videoPath}${devicePath}trench-3-hotspot-3.mp4`,
					position: [-1.26, 0.94, -96.07],
					iconKey: 'gifIcon',
					labelKey: 'hotspotLabelStoryboard',
					elementPositions: hotspotPositionConfig.left,
					showPosition: 0.4,
					lookAtPosition: 0.4
				},
				{
					id: 10,
					type: HOTSPOT_TYPES.VIDEO,
					media: `${PATHS.videoPath}${devicePath}trench-3-hotspot-4.mp4`,
					position: [2.31, 0.29, -98.43],
					labelKey: 'hotspotLabel11',
					numKey: 'hotspotNum11',
					iconKey: 'playIcon',
					elementPositions: hotspotPositionConfig.right,
					showPosition: 0.55,
					lookAtPosition: 0.62
				},
				{
					id: 5,
					type: HOTSPOT_TYPES.LINK,
					url: URLS.morseCode,
					position: [3.95, 0.35, -95.94],
					labelKey: 'hotspotLabel06',
					numKey: 'hotspotNum06',
					iconKey: 'linkIcon',
					elementPositions: hotspotPositionConfig.left,
					alignment: 'left',
					showPosition: 0.65
				}
			],
			startPosition: 0.1,
			pathOffsetY: 0
		}
	]
};

export { config, commonManifest, hotspotPositionConfig, PATHS, URLS };
