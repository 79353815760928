export const { abs } = Math;

export function isMobile() {
	return /iPhone|iPad|iPod|Android|BlackBerry|BB10/i.test(navigator.userAgent);
}

export function isTablet() {
	const userAgent = navigator.userAgent.toLowerCase();
	return /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
		userAgent
	);
}

export function isIOS() {
	return /iPhone|iPad|iPod/i.test(navigator.userAgent);
}

export function isAndroid() {
	return /Android/i.test(navigator.userAgent);
}

export function isMacOS() {
	return /Mac/i.test(navigator.appVersion) && !isIOS();
}

export function isWindows() {
	return /Win/i.test(navigator.appVersion);
}

export function isLinux() {
	return /Linux/i.test(navigator.appVersion);
}

export function isHTTPS() {
	return self.location.protocol === 'https:';
}

export function createElement(type, classNames, content) {
	type = type || 'div';
	const el = document.createElement(type);
	if (classNames) {
		classNames.forEach(name => {
			el.classList.add(name);
		});
	}
	if (content) el.innerHTML = content;
	return el;
}

export function normalize(value, min, max) {
	return (value - min) / (max - min);
}

export function lerp(norm, min, max) {
	return (max - min) * norm + min;
}

export function smoothstep(min, max, value) {
	const x = Math.max(0, Math.min(1, (value - min) / (max - min)));
	return x * x * (3 - 2 * x);
}

export function map(value, sourceMin, sourceMax, destMin, destMax) {
	return lerp(normalize(value, sourceMin, sourceMax), destMin, destMax);
}

export function clamp(value, min, max) {
	return Math.min(Math.max(value, Math.min(min, max)), Math.max(min, max));
}

export function distance(p0, p1) {
	const dx = p1.x - p0.x;
	const dy = p1.y - p0.y;
	return Math.sqrt(dx * dx + dy * dy);
}

export function distanceXY(x0, y0, x1, y1) {
	const dx = x1 - x0;
	const dy = y1 - y0;
	return Math.sqrt(dx * dx + dy * dy);
}

export function circleCollision(c0, c1) {
	return distance(c0, c1) <= c0.radius + c1.radius;
}

export function circlePointCollision(x, y, circle) {
	return distanceXY(x, y, circle.x, circle.y) < circle.radius;
}

export function inRange(value, min, max) {
	return value >= Math.min(min, max) && value <= Math.max(min, max);
}

export function pointInRect(x, y, rect) {
	return inRange(x, rect.x, rect.x + rect.width) && inRange(y, rect.y, rect.y + rect.height);
}

export function rangeIntersect(min0, max0, min1, max1) {
	return Math.max(min0, max0) >= Math.min(min1, max1) && Math.min(min0, max0) <= Math.max(min1, max1);
}

export function rectIntersect(r0, r1) {
	return (
		rangeIntersect(r0.x, r0.x + r0.width, r1.x, r1.x + r1.width) &&
		rangeIntersect(r0.y, r0.y + r0.height, r1.y, r1.y + r1.height)
	);
}

export function degreesToRads(degrees) {
	return (degrees / 180) * Math.PI;
}

export function radsToDegrees(radians) {
	return (radians * 180) / Math.PI;
}

export function angleBetweenPoints(p1, p2) {
	return Math.atan2(p2.y - p1.y, p2.x - p1.x);
}

export function randomRange(min, max) {
	return min + Math.random() * (max - min);
}

export function randomInt(min, max) {
	return Math.floor(min + Math.random() * (max - min + 1));
}

export function roundToPlaces(value, places) {
	const mult = Math.pow(10, places);
	return Math.round(value * mult) / mult;
}

export function roundNearest(value, nearest) {
	return Math.round(value / nearest) * nearest;
}

export function quadraticBezier(p0, p1, p2, t, pFinal) {
	pFinal = pFinal || {};
	pFinal.x = Math.pow(1 - t, 2) * p0.x + (1 - t) * 2 * t * p1.x + t * t * p2.x;
	pFinal.y = Math.pow(1 - t, 2) * p0.y + (1 - t) * 2 * t * p1.y + t * t * p2.y;
	return pFinal;
}

export function cubicBezier(p0, p1, p2, p3, t, pFinal) {
	pFinal = pFinal || {};
	pFinal.x = Math.pow(1 - t, 3) * p0.x + Math.pow(1 - t, 2) * 3 * t * p1.x + (1 - t) * 3 * t * t * p2.x + t * t * t * p3.x;
	pFinal.y = Math.pow(1 - t, 3) * p0.y + Math.pow(1 - t, 2) * 3 * t * p1.y + (1 - t) * 3 * t * t * p2.y + t * t * t * p3.y;
	return pFinal;
}

export function multicurve(points, context) {
	let p0;
	let p1;
	let midx;
	let midy;

	context.moveTo(points[0].x, points[0].y);

	for (let i = 1; i < points.length - 2; i += 1) {
		p0 = points[i];
		p1 = points[i + 1];
		midx = (p0.x + p1.x) / 2;
		midy = (p0.y + p1.y) / 2;
		context.quadraticCurveTo(p0.x, p0.y, midx, midy);
	}

	p0 = points[points.length - 2];
	p1 = points[points.length - 1];
	context.quadraticCurveTo(p0.x, p0.y, p1.x, p1.y);
}

export function pointOnSphere(r, a1, a2) {
	return {
		x: r * Math.cos(a1) * Math.sin(a2),
		y: r * Math.sin(a1) * Math.sin(a2),
		z: r * Math.cos(a2)
	};
}

export function getPointsOnSphere(n) {
	const pts = [];
	let pt;

	for (let i = 0; i < n; i++) {
		pt = pointOnSphere(1, Math.random() * Math.PI * 2, Math.random() * Math.PI * 2);
		pts.push({ x: pt.x, y: pt.y, z: pt.z });
	}

	return pts;
}

export function getPointsOnSphereEvenly(n) {
	const pts = [];
	const inc = Math.PI * (3 - Math.sqrt(5));
	const off = 2.0 / n;
	let x;
	let y;
	let z;
	let r;
	let phi;

	for (let k = 0; k < n; k++) {
		y = k * off - 1 + off / 2;
		r = Math.sqrt(1 - y * y);
		phi = k * inc;
		x = Math.cos(phi) * r;
		z = Math.sin(phi) * r;

		pts.push({ x, y, z });
	}
	return pts;
}

export function clone(obj) {
	return JSON.parse(JSON.stringify(obj));
}

export function isArray(arg) {
	return Object.prototype.toString.call(arg) === '[object Array]';
}

export function shuffleArray(array) {
	let currentIndex = array.length;
	let temporaryValue;
	let randomIndex;

	while (currentIndex !== 0) {
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex -= 1;
		temporaryValue = array[currentIndex];
		array[currentIndex] = array[randomIndex];
		array[randomIndex] = temporaryValue;
	}

	return array;
}

export function thinOutArray(array, n) {
	const newArray = [];
	const len = array.length;

	for (let i = 0; i < len; i += n) {
		newArray.push(array[i]);
	}

	return newArray;
}

export function randomItemFromArray(array) {
	return array[(Math.random() * array.length) | 0];
}

export function arrayRestrictedToRangeZ(array, minZ, maxZ) {
	const newArray = [];
	const len = array.length;

	for (let i = 0; i < len; i++) {
		if (inRange(array[i].z, minZ, maxZ)) newArray.push(array[i]);
	}

	return newArray;
}

export function extractKeyFromArray(array, key) {
	const a = [];
	for (let i = 0; i < array.length; i++) {
		a.push(array[i][key]);
	}
	return a;
}

export function contains(source, tests) {
	for (let i = 0; i < tests.length; i++) {
		if (source.indexOf(tests[i]) > -1) {
			return true;
		}
	}

	return false;
}

export function repeat(callback, params, intervals, delay, startDelay) {
	startDelay = startDelay || 0;
	let i = 0;
	const doStuff = () => {
		setTimeout(() => {
			callback(params);
			i++;
			if (i < intervals) doStuff();
		}, delay);
	};
	setTimeout(() => {
		doStuff();
	}, startDelay);
}

export function isOpera() {
	// Opera 8.0+ (UA detection to detect Blink/v8-powered Opera)
	return !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
}

export function isFirefox() {
	// Firefox 1.0+
	return typeof InstallTrigger !== 'undefined';
}

export function isSafari() {
	// At least Safari 3+: '[object HTMLElementConstructor]'
	return navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
}

export function isChrome() {
	// return !!window.chrome && !isOpera();
	return !!navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
}

export function isIE() {
	// At least IE6
	return false || !!document.documentMode;
}

export function getChromeVersion() {
	const raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
	return raw ? parseInt(raw[2], 10) : false;
}

export function hasWebGL() {
	if (window.WebGLRenderingContext) {
		const canvas = document.createElement('canvas');
		const names = ['webgl', 'experimental-webgl', 'moz-webgl', 'webkit-3d'];
		let context = false;

		for (let i = 0; i < 4; i++) {
			try {
				context = canvas.getContext(names[i]);
				if (context && typeof context.getParameter === 'function') {
					// WebGL is enabled
					return true;
				}
			} catch (e) {}
		}
		// WebGL is supported, but disabled
		return false;
	}
	// WebGL not supported
	return false;
}

export function getVideoType(file) {
	if (file.indexOf('.mp4') !== -1) return 'video/mp4';
	if (file.indexOf('.webm') !== -1) return 'video/webm';
	if (file.indexOf('.ogg') !== -1) return 'video/ogg';
}

export function dispatchEvent(eventName, config) {
	config = config || null;

	let event;

	if (!isIE()) {
		event = new CustomEvent(eventName, {
			detail: config
		});
		document.dispatchEvent(event);
	} else {
		event = document.createEvent('CustomEvent');
		event.initCustomEvent(eventName, true, false, config);
		document.dispatchEvent(event);
	}
}

export function setCursor(cursor, element) {
	element = element || document.body;

	if (element.style.cursor !== cursor) {
		element.style.cursor = cursor;
	}
}

export function getParameterByName(name) {
	name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
	const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
	const results = regex.exec(location.search);
	return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export function createButton(config) {
	const {
		label,
		left,
		right,
		top,
		bottom,
		width,
		height,
		clickHandler,
		bgColor = '#fff',
		fontColor = '#000',
		fontSize = '13px',
		font = 'sans-serif'
	} = config;

	const button = document.createElement('button');
	button.style.position = 'absolute';
	if (left !== undefined) button.style.left = left;
	if (right !== undefined) button.style.right = right;
	if (top !== undefined) button.style.top = top;
	if (bottom !== undefined) button.style.bottom = bottom;
	button.style.width = width;
	button.style.height = height;
	button.style.border = '0';
	button.style.padding = '8px';
	button.style.cursor = 'pointer';
	button.style.backgroundColor = bgColor;
	button.style.color = fontColor;
	button.style.fontFamily = font;
	button.style.fontSize = fontSize;
	button.style.fontStyle = 'normal';
	button.style.textAlign = 'center';
	button.style.zIndex = '999';
	button.textContent = label;

	button.onclick = () => {
		clickHandler(button);
	};

	document.body.appendChild(button);

	return button;
}

export function chance(value, lessThan = true) {
	if (lessThan) {
		return Math.random() < value;
	}

	return Math.random() > value;
}

export function timestamp() {
	const date = new Date();
	const hours = date
		.getHours()
		.toString()
		.padStart(2, '0');
	const minutes = date
		.getMinutes()
		.toString()
		.padStart(2, '0');
	const seconds = date
		.getSeconds()
		.toString()
		.padStart(2, '0');
	return `${hours}:${minutes}:${seconds}`;
}

export function addActiveCSSClass(e) {
	e.currentTarget.classList.add('active');
}

export function removeActiveCSSClass(e) {
	e.currentTarget.classList.remove('active');
}
