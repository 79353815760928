const isDev = process.env.NODE_ENV === 'development';

export const DEBUG = false;
export const DEBUG_PATHS = false;
// export const DEBUG = process.env.NODE_ENV !== 'production';
export const SKIP_INTRO = false;
// export const SKIP_INTRO = isDev;
export const INITIAL_TRENCH_INDEX = !isDev ? 0 : 0;

export const CHROME_MIN_VERSION = 78;
export const BACKGROUND_COLOR = 0xfefcef;
export const SUNSET_START_COLOR = 0xff9947;
export const SUNSET_END_COLOR = 0xffde6d;
export const SUNSET_POSITION = 0.4;
export const FOG_COLOR = 0xfefcef;
export const FOG_DENSITY = 0.1; //0.16;
export const CAMERA_FOV = 75;
export const MAX_CAMERA_DISTANCE = 10000;
export const MAX_SCROLL_DELTA = 1;
export const MAX_SCROLL_TIME_DELTA = 20;
export const SCROLL_SCALAR = 0.006;
export const SCROLL_SCALAR_MOBILE = 0.004;
export const CAMERA_POSITION_TRIGGER_THRESHOLD = 0.025;
export const PATH_LENGTH_BASE = 20;
export const LOWER_FOG_COLOR_DAMPENING = 0.8;
export const TRENCH_SUNSET_DAMPENING = 0.5;
export const MAX_FOG_VALUE = 0.6;
export const TEXT_QUALITY = 8;
export const CAMERA_ROTATION_TRIGGER_DURATION = 3;
export const MOVE_MESSAGE_TIMEOUT = 20000;
export const VIDEO_ALMOST_COMPLETE_OFFSET = 0.5;

export const AppProps = {
	app: null,
	state: null
};

export const EVENTS = {
	PAUSE: 'PAUSE',
	RESUME: 'RESUME',
	RESIZE: 'RESIZE',
	UPDATE: 'UPDATE',
	SCROLL: 'SCROLL',
	PATH_TRIGGER: 'PATH_TRIGGER',
	PATH_COMPLETE: 'PATH_COMPLETE',
	VIDEO_STARTED: 'VIDEO_STARTED',
	VIDEO_COMPLETE: 'VIDEO_COMPLETE',
	VIDEO_ALMOST_COMPLETE: 'VIDEO_ALMOST_COMPLETE',
	VIDEO_TRANSITIONED_IN: 'VIDEO_TRANSITIONED_IN',
	VIDEO_TRANSITIONED_OUT: 'VIDEO_TRANSITIONED_OUT',
	VIDEO_TRANSITION_OUT_STARTED: 'VIDEO_TRANSITION_OUT_STARTED',
	VIDEO_SEEK_TO_PERCENTAGE: 'VIDEO_SEEK_TO_PERCENTAGE',
	VIDEO_EXITED_FULLSCREEN: 'VIDEO_EXITED_FULLSCREEN',
	IMAGE_COMPLETE: 'IMAGE_COMPLETE',
	FADE_IN_COMPLETE: 'FADE_IN_COMPLETE',
	FADE_OUT_COMPLETE: 'FADE_OUT_COMPLETE',
	INTRO_COMPLETE: 'INTRO_COMPLETE',
	TRIGGER_COMPLETE: 'TRIGGER_COMPLETE',
	HOTSPOT_SELECTED: 'HOTSPOT_SELECTED',
	VIDEO_SEEK_TO_END: 'VIDEO_SEEK_TO_END',
	SET_VIDEO_OPACITY: 'SET_VIDEO_OPACITY',
	RESUME_VIDEO: 'RESUME_VIDEO',
	MUTE_AUDIO: 'MUTE_AUDIO',
	UNMUTE_AUDIO: 'UNMUTE_AUDIO',
	ANIMATION_COMPLETE: 'ANIMATION_COMPLETE',
	GO_HOME: 'GO_HOME'
};

export const TRIGGER_TYPES = {
	MEDIA: 0,
	CAMERA_CHANGE: 1
};

export const PARTICLE_TYPES = {
	DUST: 0,
	SMOKE: 1,
	OBJECT_SMOKE: 2
};

export const VIDEO_STYLES = {
	TRANSITION: 0,
	CONTENT: 1,
	CONTENT_4x5: 2
};

export const HOTSPOT_TYPES = {
	VIDEO: 0,
	VIDEO_4x5: 1,
	LINK: 2
};
