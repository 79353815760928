import { Howl } from 'howler';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { AudioLoader, AudioListener, Audio, CubeTextureLoader, JSONLoader, FontLoader } from 'three/build/three.module';
import AjaxTextureLoader from '../vendor/ajax-texture-loader';

const LOG_LOADS = false;

export default class Loader {
	constructor() {
		this.checkLoaded = this.checkLoaded.bind(this);
	}

	reset() {
		clearInterval(this.interval);
		this.loadInfo = {};
		this.files = {};
		this.done = false;
	}

	load(manifest, onLoadCallback, onProgressCallback) {
		this.reset();

		this.progressCallback = onProgressCallback;
		this.loadCallback = onLoadCallback;

		this.numFiles = manifest.length;
		this.numLoaded = 0;

		manifest.forEach(fileObj => {
			if (!(Object.prototype.toString.call(fileObj.file) === '[object Array]')) {
				if (
					fileObj.file.indexOf('.png') > -1 ||
					fileObj.file.indexOf('.jpg') > -1 ||
					fileObj.file.indexOf('.jpeg') > -1 ||
					fileObj.file.indexOf('.gif') > -1
				) {
					this.files.textures = this.files.textures || {};
					this.loadTexture(fileObj);
				} else if (fileObj.file.indexOf('.gltf') > -1 || fileObj.file.indexOf('.glb') > -1) {
					this.files.geometry = this.files.geometry || {};
					this.loadGLTF(fileObj);
				} else if (fileObj.file.indexOf('.obj') > -1) {
					this.files.geometry = this.files.geometry || {};
					this.loadOBJ(fileObj);
				} else if (fileObj.file.indexOf('.json') > -1) {
					if (fileObj.isData) {
						this.files.data = this.files.data || {};
						this.loadDataJSON(fileObj);
					} else if (fileObj.isFont) {
						this.files.fonts = this.files.fonts || {};
						this.loadFontJSON(fileObj);
					} else {
						this.files.geometry = this.files.geometry || {};
						this.loadGeometryJSON(fileObj);
					}
				} else if (fileObj.file.indexOf('.dae') > -1) {
					this.files.geometry = this.files.geometry || {};
					this.loadCollada(fileObj);
				} else if (fileObj.file.indexOf('.mp4') > -1 || fileObj.file.indexOf('.webm') > -1) {
					this.files.video = this.files.video || {};
					this.loadVideo(fileObj);
				} else if (fileObj.file.indexOf('.mp3') > -1 || fileObj.file.indexOf('.ogg') > -1) {
					this.files.audio = this.files.audio || {};
					this.loadHowlerAudio(fileObj);
				}
			} else {
				this.loadCubemap(fileObj);
			}
		});

		this.interval = setInterval(this.update, 1000 / 30);
	}

	loadDataJSON(fileObj) {
		this.loadInfo[fileObj.id] = { loaded: 0, total: 0 };

		const xhr = new XMLHttpRequest();

		let hasError = false;

		const onError = () => {
			hasError = true;
			this.files.data[fileObj.id] = null;
			this.loadInfo[fileObj.id].loaded = this.loadInfo[fileObj.id].total = 1;
			this.numLoaded++;
			this.checkLoaded();
		};

		xhr.addEventListener('progress', e => {
			this.loadInfo[fileObj.id].loaded = e.loaded;
			this.loadInfo[fileObj.id].total = e.total;
		});

		xhr.overrideMimeType('application/json');
		xhr.open('GET', fileObj.file, true);
		xhr.onreadystatechange = () => {
			if (xhr.readyState === 4 && xhr.status === 200) {
				this.files.data[fileObj.id] = JSON.parse(xhr.responseText);
				this.loadInfo[fileObj.id].loaded = this.loadInfo[fileObj.id].total;
				if (LOG_LOADS) console.log(`LOAD COMPLETE / ${fileObj.id}`);
				this.numLoaded++;
				this.checkLoaded();
			} else if (xhr.status === 404 && !hasError) {
				onError();
			}
		};
		xhr.onerror = onError;
		xhr.send();
	}

	loadGLTF(fileObj) {
		const loader = new GLTFLoader();
		this.loadInfo[fileObj.id] = { loaded: 0, total: 0 };

		if (fileObj.dracoDecoderPath) {
			DRACOLoader.setDecoderPath(fileObj.dracoDecoderPath);
			loader.setDRACOLoader(new DRACOLoader());
			DRACOLoader.getDecoderModule();
		}

		// console.log('GLTF LOAD STARTED:', fileObj);

		loader.load(
			fileObj.file,
			gltf => {
				this.loadInfo[fileObj.id].loaded = this.loadInfo[fileObj.id].total = 100; // fixes loading from cache if both are 0
				this.files.geometry[fileObj.id] = gltf;
				if (LOG_LOADS) console.log(`LOAD COMPLETE / ${fileObj.id}`);
				this.numLoaded++;
				this.checkLoaded();
			},
			xhr => {
				// console.log('GLTF LOAD PROGRESS:', fileObj.id, xhr.loaded, xhr.total);
				this.loadInfo[fileObj.id].loaded = xhr.loaded;
				this.loadInfo[fileObj.id].total = xhr.total;
			},
			error => {
				console.warn('GLTF LOAD ERROR:', error);
			}
		);
	}

	loadOBJ(fileObj) {
		const loader = new OBJLoader();
		this.loadInfo[fileObj.id] = { loaded: 0, total: 0 };

		// console.log('GLTF LOAD STARTED:', fileObj);

		loader.load(
			fileObj.file,
			obj => {
				this.loadInfo[fileObj.id].loaded = this.loadInfo[fileObj.id].total = 100; // fixes loading from cache if both are 0
				this.files.geometry[fileObj.id] = obj;
				if (LOG_LOADS) console.log(`LOAD COMPLETE / ${fileObj.id}`);
				this.numLoaded++;
				this.checkLoaded();
			},
			xhr => {
				// console.log('GLTF LOAD PROGRESS:', fileObj.id, xhr.loaded, xhr.total);
				this.loadInfo[fileObj.id].loaded = xhr.loaded;
				this.loadInfo[fileObj.id].total = xhr.total;
			},
			error => {
				console.warn('GLTF LOAD ERROR:', error);
			}
		);
	}

	loadGeometryJSON(fileObj) {
		// console.log('loadGeometryJSON:', fileObj.file);

		const loader = new JSONLoader();
		this.loadInfo[fileObj.id] = { loaded: 0, total: 0 };

		loader.load(
			fileObj.file,
			geometry => {
				this.loadInfo[fileObj.id].loaded = this.loadInfo[fileObj.id].total;
				this.files.geometry[fileObj.id] = geometry;
				if (LOG_LOADS) console.log(`LOAD COMPLETE / ${fileObj.id}`);
				this.numLoaded++;
				this.checkLoaded();

				// console.log(`Loader / ${fileObj.file} loaded`);
			},
			xhr => {
				this.loadInfo[fileObj.id].loaded = xhr.loaded;
				this.loadInfo[fileObj.id].total = xhr.total;
			}
		);
	}

	loadFontJSON(fileObj) {
		const loader = new FontLoader();
		this.loadInfo[fileObj.id] = { loaded: 0, total: 0 };

		loader.load(
			fileObj.file,
			font => {
				this.loadInfo[fileObj.id].loaded = this.loadInfo[fileObj.id].total;
				this.files.fonts[fileObj.id] = font;
				if (LOG_LOADS) console.log(`LOAD COMPLETE / ${fileObj.id}`);
				this.numLoaded++;
				this.checkLoaded();

				// console.log(`Loader / ${fileObj.file} loaded`);
			},
			xhr => {
				this.loadInfo[fileObj.id].loaded = xhr.loaded;
				this.loadInfo[fileObj.id].total = xhr.total;
			}
		);
	}

	loadTexture(fileObj) {
		const loader = new AjaxTextureLoader();
		this.loadInfo[fileObj.id] = { loaded: 0, total: 0 };

		loader.load(
			fileObj.file,
			texture => {
				this.loadInfo[fileObj.id].loaded = this.loadInfo[fileObj.id].total;
				this.files.textures[fileObj.id] = texture;
				if (LOG_LOADS) console.log(`LOAD COMPLETE / ${fileObj.id}`);

				this.numLoaded++;
				this.checkLoaded();

				// console.log(`Loader / ${fileObj.file} loaded`);
			},
			xhr => {
				this.loadInfo[fileObj.id].loaded = xhr.loaded;
				this.loadInfo[fileObj.id].total = xhr.total;
			}
		);
	}

	loadCubemap(fileObj) {
		// console.log('loadCubemap:', fileObj.file);

		const loader = new CubeTextureLoader();
		this.loadInfo[fileObj.id] = { loaded: 0, total: 0 };

		loader.load(
			fileObj.file,
			cubemapTexture => {
				this.loadInfo[fileObj.id].loaded = this.loadInfo[fileObj.id].total;
				this.files.textures[fileObj.id] = cubemapTexture;
				if (LOG_LOADS) console.log(`LOAD COMPLETE / ${fileObj.id}`);
				this.numLoaded++;
				this.checkLoaded();

				// console.log(`Loader / ${fileObj.file} loaded`);
			},
			xhr => {
				this.loadInfo[fileObj.id].loaded = xhr.loaded;
				this.loadInfo[fileObj.id].total = xhr.total;
			}
		);
	}

	loadVideo(fileObj) {
		this.loadInfo[fileObj.id] = { loaded: 0, total: 0 };

		const xhr = new XMLHttpRequest();
		const scope = this;

		xhr.addEventListener('progress', e => {
			this.loadInfo[fileObj.id].loaded = e.loaded;
			this.loadInfo[fileObj.id].total = e.total;

			// console.log('video progress:', fileObj.id, this.loadInfo[fileObj.id].loaded, this.loadInfo[fileObj.id].total);
		});

		xhr.open('GET', fileObj.file, true);
		xhr.responseType = 'blob';

		xhr.onload = function() {
			// Onload is triggered even on 404
			// so we need to check the status code
			if (this.status === 200) {
				const videoBlob = this.response;
				const vid = URL.createObjectURL(videoBlob); // IE10+
				// Video is now downloaded
				// and we can set it as source on the video element
				// video.src = vid;

				scope.files.video[fileObj.id] = vid;
				if (LOG_LOADS) console.log(`LOAD COMPLETE / ${fileObj.id}`);
				scope.numLoaded++;
				scope.checkLoaded();

				// console.log(`Loader / ${fileObj.file} loaded`);
			}
		};

		xhr.onerror = () => {
			// Error
		};

		xhr.send();
	}

	loadAudio(fileObj) {
		this.loadInfo[fileObj.id] = { loaded: 0, total: 0 };

		const loader = new AudioLoader();
		const listener = fileObj.listener || new AudioListener();
		const sound = fileObj.sound || new Audio(listener);

		loader.load(
			fileObj.file,
			buffer => {
				sound.setBuffer(buffer);
				this.loadInfo[fileObj.id].loaded = this.loadInfo[fileObj.id].total;
				this.files.audio[fileObj.id] = { sound, listener, buffer };
				if (LOG_LOADS) console.log(`LOAD COMPLETE / ${fileObj.id}`);
				this.numLoaded++;
				this.checkLoaded();

				// console.log(`Loader / ${fileObj.file} loaded`);
			},
			xhr => {
				this.loadInfo[fileObj.id].loaded = xhr.loaded;
				this.loadInfo[fileObj.id].total = xhr.total;
			},
			err => {
				console.warn('ERROR LOADING AUDIO:', err);
			}
		);
	}

	loadHowlerAudio(fileObj) {
		this.loadInfo[fileObj.id] = { loaded: 0, total: 1 };

		const sound = new Howl({
			src: fileObj.sources,
			sprite: fileObj.spriteInfo,
			loop: fileObj.loop,
			preload: false
			// onloaderror: (id, error) => {
			//     console.log('loadHowlerAudio error:', id, error);
			// },
			// onload: () => {
			//     this.loadInfo[fileObj.id].loaded = this.loadInfo[fileObj.id].total;
			//     this.files.audio[fileObj.id] = { sound: sound };
			//     if (LOG_LOADS) console.log(`LOAD COMPLETE / ${fileObj.id}`);
			//     this.numLoaded++;
			//     this.checkLoaded();
			// }
		});

		const handleError = (id, error) => {
			console.warn('loadHowlerAudio error:', id, error);
		};

		const handleLoad = () => {
			sound.off('load', handleLoad);
			sound.off('loaderror', handleError);
			this.loadInfo[fileObj.id].loaded = this.loadInfo[fileObj.id].total;
			this.files.audio[fileObj.id] = { sound };
			if (LOG_LOADS) console.log(`LOAD COMPLETE / ${fileObj.id}`);
			this.numLoaded++;
			this.checkLoaded();
		};

		sound.on('loaderror', handleError);
		sound.on('load', handleLoad);

		sound.load();
	}

	checkLoaded() {
		if (this.done) return;

		if (LOG_LOADS) console.log('Loader.checkLoaded:', this.numLoaded, this.numFiles);

		if (this.numLoaded === this.numFiles) {
			// || percent === 1)
			this.done = true;
			clearInterval(this.interval);
			this.update();

			if (typeof this.loadCallback === 'function') {
				this.loadCallback(this.files);
			}
		}
	}

	update() {
		if (typeof this.progressCallback === 'function') {
			let loaded = 0;
			let total = 0;

			for (const info in this.loadInfo) {
				if (this.loadInfo[info].loaded) {
					loaded += this.loadInfo[info].loaded;
				}
				if (this.loadInfo[info].total) {
					total += this.loadInfo[info].total;
				}
			}

			// checkLoaded(loaded/total);

			if (this.progressCallback) this.progressCallback(loaded, total);
		}
	}

	dispose() {
		console.log('dispose');
		clearInterval(this.interval);
		this.interval = null;
		this.loadInfo = null;
		this.files = null;
		this.numFiles = null;
		this.numLoaded = null;
		this.progressCallback = null;
		this.loadCallback = null;
		this.done = null;
	}
}
