import {
	Vector3,
	Vector2,
	Matrix4,
	Math as ThreeMath,
	Euler,
	Quaternion,
	Mesh,
	BoxBufferGeometry,
	MeshBasicMaterial,
	SphereBufferGeometry
} from 'three/build/three.module';
import Hamster from 'hamsterjs';
import bindAll from 'lodash.bindall';
import gsap from 'gsap';
import { lerpTowards, axisUp, objectWorldPositionToScreen, getMouseIntersection } from '../utils/three-utils';
import {
	SCROLL_SCALAR,
	AppProps,
	MAX_SCROLL_DELTA,
	MAX_SCROLL_TIME_DELTA,
	PATH_LENGTH_BASE,
	EVENTS,
	DEBUG_PATHS,
	SCROLL_SCALAR_MOBILE,
	MOVE_MESSAGE_TIMEOUT
} from '../config/constants';
import EventManager from '../managers/event-manager';

export default class Controls {
	constructor(props) {
		bindAll(
			this,
			'handleScroll',
			'handleMouseDown',
			'handleMouseMove',
			'handleMouseUp',
			'handleTouchStart',
			'handleTouchMove',
			'handleTouchEnd',
			'handleDeviceOrientationChange',
			'handleScreenOrientationChange',
			'testForInactivity',
			'resetScrollTime'
		);

		this.props = props;
		this.init();
	}

	init() {
		const { useDeviceOrientation } = AppProps.state;

		this.enabled = false;
		this.radius = 1.5;
		this.hasTransitionedIn = false;
		this.transitioning = false;

		this.lookAt = {
			coords: new Vector2(),
			range: new Vector2(70, 30),
			target: new Vector3(),
			rotationOffset: new Euler(),
			override: false,
			overrideTarget: new Vector3(),
			position: new Vector3(),
			influence: 0,
			ease: { value: 0.15 }
		};

		this.reset();

		if (useDeviceOrientation) {
			this.initDeviceOrientation();
		}

		setTimeout(() => {
			if (AppProps.state.devControls) {
				this.initDevControls();
			}
		}, 50);

		this.bindEvents();
	}

	initDevControls() {
		const { devControls } = AppProps.state;

		if (devControls) {
			const height = 30;
			const fontColor = '#ffffff';
			const group = devControls.add('group', { name: 'CONTROLS', h: height });

			this.dev = { group: group };

			group
				.add('bool', {
					name: 'Enabled',
					value: true,
					fontColor
				})
				.onChange(v => {
					this.enabled = v;
				});

			this.dev.positionControl = group
				.add('slide', {
					name: 'Path Position',
					min: 0,
					max: 1,
					value: 0,
					precision: 2,
					fontColor,
					h: height
				})
				.onChange(v => {
					this.pathPositionTarget = v;
				});

			group
				.add('slide', {
					name: 'Influence',
					min: 0,
					max: 1,
					value: 1,
					precision: 2,
					fontColor,
					h: height
				})
				.onChange(v => {
					this.lookAt.influence = v;
				});

			group
				.add('bool', {
					name: 'Override Look At',
					value: false,
					fontColor
				})
				.onChange(v => {
					this.lookAt.override = v;
				});

			group
				.add('number', {
					name: 'Look At Override Target',
					value: [0, 0, -0.1],
					fontColor,
					h: height
				})
				.onChange(v => {
					this.lookAt.overrideTarget.set(v[0], v[1], v[2]);
				});

			group
				.add('number', {
					name: 'Position Offset Target',
					value: [0, 0, 0],
					fontColor,
					h: height
				})
				.onChange(v => {
					this.setPositionOffset(v, 0);
				});

			group
				.add('bool', {
					name: 'Show Path',
					value: DEBUG_PATHS,
					fontColor
				})
				.onChange(v => {
					if (this.cameraPath) {
						this.cameraPath.setDebugMode(v);
					}
				});

			group.add('button', {
				name: 'Add Debug Mesh',
				simple: true,
				callback: () => {
					if (!this.dev.debugMesh) {
						this.dev.debugMesh = new Mesh(
							new BoxBufferGeometry(1, 0.5, 0.05),
							new MeshBasicMaterial({ color: 0xfe5143, fog: false, wireframe: true })
						);
						this.dev.debugMesh.add(
							new Mesh(
								new SphereBufferGeometry(0.1, 30, 30),
								new MeshBasicMaterial({ color: 0xfe5143, fog: false })
							)
						);
						AppProps.state.scene.add(this.dev.debugMesh);

						if (this.cameraPath) {
							this.dev.debugMesh.position.copy(this.cameraPath.getPositionAt(0.5));
						}

						const { x, y, z } = this.dev.debugMesh.position;

						this.dev.group
							.add('slide', {
								name: 'Debug Mesh Path Position',
								min: 0,
								max: 1,
								value: 0.5,
								precision: 2,
								fontColor,
								h: height
							})
							.onChange(v => {
								if (this.cameraPath) {
									this.dev.debugMesh.position.copy(this.cameraPath.getPositionAt(v));

									if (this.dev.dmp) {
										const { x, y, z } = this.dev.debugMesh.position;
										this.dev.dmp.setValue(x, 0);
										this.dev.dmp.setValue(y, 1);
										this.dev.dmp.setValue(z, 2);
									}
								}
							});

						this.dev.dmp = this.dev.group
							.add('number', {
								name: 'Debug Mesh Position',
								value: [x, y, z],
								fontColor,
								h: height
							})
							.onChange(v => {
								this.dev.debugMesh.position.set(v[0], v[1], v[2]);
							});

						this.dev.dmr = this.dev.group
							.add('number', {
								name: 'Debug Mesh Rotation',
								value: [0, 0, 0],
								fontColor,
								h: height
							})
							.onChange(v => {
								this.dev.debugMesh.rotation.set(v[0], v[1], v[2]);
							});

						this.dev.group.close();
						this.dev.group.open();
					}
				},
				fontColor,
				h: height
			});
		}
	}

	initDeviceOrientation() {
		const { cameraOrientationObj } = this.props;

		cameraOrientationObj.rotation.reorder('YXZ');

		this.deviceOrientation = {
			object: cameraOrientationObj,
			device: null,
			screenOrientation: null,
			alphaOffset: 0,
			zee: new Vector3(0, 0, 1),
			euler: new Euler(),
			q0: new Quaternion(),
			q1: new Quaternion(-Math.sqrt(0.5), 0, 0, Math.sqrt(0.5)) // - PI/2 around the x-axis
		};

		window.addEventListener('orientationchange', this.handleScreenOrientationChange, false);
		window.addEventListener('deviceorientation', this.handleDeviceOrientationChange, false);

		this.handleScreenOrientationChange();
	}

	reset() {
		this.delta = new Vector2();
		this.setPathPosition(0);
		this.pathPositionOverride = null;
		this.cameraPosition = new Vector3();
		this.pointer = new Vector2();
		this.pointerCopy = new Vector2();
		this.lastInputPosition = new Vector2();
		this.matrix = new Matrix4();
		this.minCameraPosition = 0;
		this.maxCameraPosition = 1;
		this.pathSpeedScaler = 1;
		this.positionOffset = new Vector3();
		this.lookAt.rotationOffset.set(0, 0, 0);
		this.resetLookAt();
		this.resetScrollTime();
	}

	resetScrollTime() {
		this.lastScrollTime = Date.now();
	}

	resetLookAt() {
		this.updateLookAtTarget();
		this.lookAt.position.copy(this.lookAt.target);
	}

	handleDeviceOrientationChange(e) {
		this.deviceOrientation.device = e;
	}

	handleScreenOrientationChange(e) {
		this.deviceOrientation.screenOrientation = window.orientation || 0;
	}

	bindEvents() {
		const { domElement } = this.props;
		const { isMobile } = AppProps.state;

		if (!isMobile) {
			domElement.addEventListener('mousedown', this.handleMouseDown, false);
			domElement.addEventListener('mousemove', this.handleMouseMove, false);
			domElement.addEventListener('mouseup', this.handleMouseUp, false);
			this.hamster = Hamster(domElement);
			this.hamster.wheel(this.handleScroll);
		} else {
			domElement.addEventListener('touchstart', this.handleTouchStart, false);
			domElement.addEventListener('touchmove', this.handleTouchMove, false);
			domElement.addEventListener('touchend', this.handleTouchEnd, false);
			domElement.addEventListener('touchcancel', this.handleTouchEnd, false);
		}

		EventManager.on(EVENTS.VIDEO_STARTED, this.testForInactivity);
		EventManager.on(EVENTS.VIDEO_COMPLETE, this.resetScrollTime);
	}

	unbindEvents() {
		const { domElement } = this.props;
		const { isMobile } = AppProps.state;

		if (!isMobile) {
			domElement.removeEventListener('mousedown', this.handleMouseDown);
			domElement.removeEventListener('mousemove', this.handleMouseMove);
			domElement.removeEventListener('mouseup', this.handleMouseUp);
			this.hamster.unwheel();
			this.hamster = null;
		} else {
			domElement.removeEventListener('touchstart', this.handleTouchStart);
			domElement.removeEventListener('touchmove', this.handleTouchMove);
			domElement.removeEventListener('touchend', this.handleTouchEnd);
			domElement.removeEventListener('touchcancel', this.handleTouchEnd);
		}

		EventManager.off(EVENTS.VIDEO_STARTED, this.testForInactivity);
		EventManager.off(EVENTS.VIDEO_COMPLETE, this.resetScrollTime);
	}

	handleTouchStart(e) {
		const touch = e.changedTouches[0];
		this.lastInputPosition.set(touch.clientX, touch.clientY);

		// test if object was selected on down to test against in up
		this.pointerCopy.copy(this.pointer);
		this.setPointer(e.changedTouches[0]);
		this.selectedOnDown = this.getInteractiveObjectSelected();
		this.pointer.copy(this.pointerCopy);

		if (this.selectedOnDown) {
			this.selectedOnDown.object.userData.object.mouseOver();
		}
	}

	handleTouchMove(e) {
		const touch = e.changedTouches[0];

		this.delta.x = this.lastInputPosition.x - touch.clientX;
		this.delta.y = ThreeMath.clamp(this.lastInputPosition.y - touch.clientY, -MAX_SCROLL_DELTA, MAX_SCROLL_DELTA);

		this.updatePositionTarget(this.delta.y * -SCROLL_SCALAR_MOBILE);

		// update horizonal pan using delta
		this.pointer.x += -this.delta.x * 0.15;
		this.pointer.x = ThreeMath.clamp(this.pointer.x, -this.lookAt.range.x, this.lookAt.range.x);

		this.lastInputPosition.set(touch.clientX, touch.clientY);

		EventManager.emit(EVENTS.SCROLL);
		this.resetScrollTime();

		if (this.selectedOnDown) {
			this.selectedOnDown.object.userData.object.mouseOut();
		}
	}

	handleTouchEnd(e) {
		// copy pointer and set to touch point for picking test
		this.pointerCopy.copy(this.pointer);
		this.setPointer(e.changedTouches[0]);

		// test if object was selected
		this.testForInteractiveObjects(this.selectedOnDown);

		// reset pointer to previous state
		this.pointer.copy(this.pointerCopy);

		if (this.selectedOnDown) {
			this.selectedOnDown.object.userData.object.mouseOut(0.1);
		}
		this.selectedOnDown = null;
	}

	handleMouseDown(e) {
		this.selectedOnDown = this.getInteractiveObjectSelected();
	}

	handleMouseMove(e) {
		this.setPointer(e);

		this.delta.x = this.lastInputPosition.x - e.clientX;
		this.delta.y = this.lastInputPosition.y - e.clientY;

		this.lastInputPosition.set(e.clientX, e.clientY);
	}

	handleMouseUp(e) {
		this.setPointer(e);
		this.testForInteractiveObjects(this.selectedOnDown);
		this.selectedOnDown = null;
	}

	handleScroll(e, delta, deltaX, deltaY) {
		if (this.lastTime) {
			const timeDelta = Date.now() - this.lastTime;
			if (timeDelta < MAX_SCROLL_TIME_DELTA) return;
		}
		this.lastTime = Date.now();

		if (this.transitioning) return;
		const d = ThreeMath.clamp(deltaY, -MAX_SCROLL_DELTA, MAX_SCROLL_DELTA);
		this.updatePositionTarget(-d * SCROLL_SCALAR);
		EventManager.emit(EVENTS.SCROLL);
		this.resetScrollTime();
	}

	setPointer(e) {
		const { domElement } = this.props;
		const { clientWidth, clientHeight } = domElement;

		this.pointer.x = (e.clientX / clientWidth) * 2 - 1;
		this.pointer.y = -(e.clientY / clientHeight) * 2 + 1;

		this.pointer.x = ThreeMath.clamp(this.pointer.x, -1, 1);
		this.pointer.y = ThreeMath.clamp(this.pointer.y, -1, 1);
	}

	setCameraPath(path, startPosition = 0, endPosition = 1) {
		this.cameraPath = path;

		// normalize move speed for different path lengths
		this.pathSpeedScaler = PATH_LENGTH_BASE / this.cameraPath.getLength();
		this.setMinMaxPositions(startPosition, endPosition);
		this.setPathPosition(startPosition, true);
	}

	getCameraPath() {
		return this.cameraPath;
	}

	setCameraPosition(position) {
		if (position) {
			const { cameraRig } = this.props;
			this.cameraPosition.copy(position);
			cameraRig.position.copy(position);
		}
	}

	setCameraPositionTarget(position) {
		if (position) {
			this.cameraPosition.copy(position);
		}
	}

	setMinMaxPositions(min = 0, max = 1) {
		this.minCameraPosition = min;
		this.maxCameraPosition = max;
	}

	setObjectQuaternion(quaternion, alpha, beta, gamma, orient) {
		const { euler, zee, q0, q1 } = this.deviceOrientation;

		euler.set(beta, alpha, -gamma, 'YXZ'); // 'ZXY' for the device, but 'YXZ' for us
		quaternion.setFromEuler(euler); // orient the device
		quaternion.multiply(q1); // camera looks out the back of the device, not the top
		quaternion.multiply(q0.setFromAxisAngle(zee, -orient)); // adjust for screen orientation
	}

	setInfluence(influence, duration = 1) {
		return new Promise(resolve => {
			if (!this.lookAt) {
				resolve();
				return;
			}

			if (duration > 0) {
				gsap.to(this.lookAt, {
					duration,
					influence,
					ease: 'power2.inOut',
					onComplete: () => {
						resolve();
					}
				});
			} else {
				this.lookAt.influence = influence;
				resolve();
			}
		});
	}

	setRotationOffset(euler, duration = 1) {
		return new Promise(resolve => {
			const { rotationOffset } = this.lookAt;

			if (euler) {
				gsap.to(rotationOffset, {
					duration,
					x: euler[0],
					y: euler[1],
					z: euler[2],
					ease: 'power2.inOut',
					onComplete: () => {
						resolve();
					}
				});
			} else {
				gsap.to(rotationOffset, {
					duration,
					x: 0,
					y: 0,
					z: 0,
					ease: 'power2.inOut',
					onComplete: () => {
						resolve();
					}
				});
			}
		});
	}

	setPositionOffset(position, duration = 1) {
		if (duration > 0) {
			if (position) {
				gsap.to(this.positionOffset, {
					duration,
					x: position[0],
					y: position[1],
					z: position[2],
					ease: 'power2.inOut'
				});
			} else {
				gsap.to(this.positionOffset, {
					duration,
					x: 0,
					y: 0,
					z: 0,
					ease: 'power2.inOut'
				});
			}
		} else {
			this.positionOffset.fromArray(position);
		}
	}

	setPathPosition(position = 0, updateCameraPosition) {
		this.pathPosition = position;
		this.pathPositionTarget = position;

		// avoid camera look whip around when position jumps
		if (updateCameraPosition) {
			this.setCameraPosition(this.cameraPath.getPositionAt(this.pathPosition));
			this.resetLookAt();
		}
	}

	setPathPositionOverride(position = 0) {
		this.pathPositionOverride = position;
	}

	setLookAtOverride(override, overrideTarget) {
		if (override) {
			this.lookAt.overrideTarget.copy(overrideTarget);
			this.lookAt.ease.value = 0.05;
		} else {
			gsap.to(this.lookAt.ease, {
				duration: 1,
				value: 0.15,
				ease: 'power2.inOut'
			});
		}
		this.lookAt.override = override;
	}

	enable() {
		this.enabled = true;
	}

	disable() {
		this.enabled = false;
	}

	setCursor(style = 'auto') {
		const { canvas } = AppProps.state;
		if (canvas.style.cursor !== style) canvas.style.cursor = style;
	}

	getInteractiveObjectSelected() {
		const { hotspots, camera, raycaster } = AppProps.state;

		if (hotspots.length) {
			return getMouseIntersection(this.pointer, camera, hotspots, raycaster);
		}

		return null;
	}

	testForInteractiveObjects(testAgainst) {
		const intersection = this.getInteractiveObjectSelected();

		if (intersection && testAgainst && intersection.object === testAgainst.object) {
			const obj = intersection.object.userData.object;
			if (obj.isTransitionedIn()) {
				const { userData } = intersection.object;
				EventManager.emit(EVENTS.HOTSPOT_SELECTED, userData);
			}
		}
	}

	testForInactivity() {
		const { showMoveMessage, showInteractionMessage, showLoadingMessage, showMapUI, videoSource } = AppProps.state;
		const now = Date.now();
		const delta = now - this.lastScrollTime;
		const canShow = !showInteractionMessage && !showLoadingMessage && !showMapUI && !videoSource;

		if (delta >= MOVE_MESSAGE_TIMEOUT && !showMoveMessage && canShow) {
			AppProps.app.setState({
				showMoveMessage: true
			});
		} else if ((delta < MOVE_MESSAGE_TIMEOUT || !canShow) && showMoveMessage) {
			AppProps.app.setState({
				showMoveMessage: false
			});
		}
	}

	updateInputOverInteractiveObjects() {
		const { hotspots, camera, raycaster } = AppProps.state;

		if (hotspots.length) {
			const intersection = getMouseIntersection(this.pointer, camera, hotspots, raycaster);
			if (intersection) {
				const obj = intersection.object.userData.object;
				if (this.currentInputOver !== obj && obj.isTransitionedIn()) {
					this.currentInputOver = obj;
					if (this.currentInputOver) {
						this.currentInputOver.mouseOver();
					}
					this.setCursor('pointer');
				}
			} else {
				if (this.currentInputOver) {
					this.currentInputOver.mouseOut();
					this.currentInputOver = null;
				}
				this.setCursor('auto');
			}
		}
	}

	updateLookAtTarget(skipApplyEuler) {
		const { cameraRig } = this.props;
		const { coords, target, rotationOffset, override, overrideTarget } = this.lookAt;

		// overrides defined in config for video frame matching
		if (override) {
			target.x = overrideTarget.x;
			target.y = overrideTarget.y;
			target.z = overrideTarget.z;
		} else {
			const lon = coords.x - 90;
			const lat = ThreeMath.clamp(-coords.y, -85, 85) + 90;
			const phi = ThreeMath.degToRad(lat);
			const theta = ThreeMath.degToRad(lon);

			target.x = this.radius * Math.sin(phi) * Math.cos(theta);
			target.y = this.radius * Math.cos(phi);
			target.z = this.radius * Math.sin(phi) * Math.sin(theta);

			// set {rotationOffset} to set a look offset, backwards, sideways, etc
			if (!skipApplyEuler) target.applyEuler(rotationOffset);
		}

		target.add(cameraRig.position);
	}

	updateDeviceOrientation(delta) {
		const { object, device, alphaOffset, screenOrientation } = this.deviceOrientation;
		const { rotationOffset } = this.lookAt;
		const { camera, cameraRig, cameraOrientationObj } = this.props;

		if (device) {
			cameraOrientationObj.position.copy(cameraRig.position);

			const { domElement } = this.props;
			const { clientWidth, clientHeight } = domElement;
			const alpha = device.alpha ? ThreeMath.degToRad(device.alpha) + alphaOffset + rotationOffset.z : 0; // Z
			const beta = device.beta ? ThreeMath.degToRad(device.beta) + rotationOffset.x : 0; // X'
			const gamma = device.gamma ? ThreeMath.degToRad(device.gamma) + rotationOffset.y : 0; // Y''

			const orient = screenOrientation ? ThreeMath.degToRad(screenOrientation) : 0; // O

			this.setObjectQuaternion(object.quaternion, alpha, beta, gamma, orient);

			// move towards look position
			cameraOrientationObj.translateZ(-2);

			// get screen position of dumby object in place of pointer
			objectWorldPositionToScreen(
				cameraOrientationObj,
				camera,
				this.lookAt.target,
				this.lookAt.coords,
				clientWidth,
				clientHeight
			);
			this.lookAt.coords.x = (this.lookAt.coords.x / clientWidth) * 2 - 1;
			this.lookAt.coords.y = -(this.lookAt.coords.y / clientHeight) * 2 + 1;
			this.lookAt.coords.x = ThreeMath.clamp(this.lookAt.coords.x, -1, 1);
			this.lookAt.coords.y = ThreeMath.clamp(this.lookAt.coords.y, -1, 1);

			this.lookAt.coords.multiply(this.lookAt.range).multiplyScalar(this.lookAt.influence);

			this.updateLookAtTarget();
		}
	}

	updateManualOrientation() {
		const { isMobile } = AppProps.state;

		if (isMobile) {
			this.lookAt.coords.copy(this.pointer).multiplyScalar(this.lookAt.influence);
		} else {
			this.lookAt.coords
				.copy(this.pointer)
				.multiply(this.lookAt.range)
				.multiplyScalar(this.lookAt.influence);
		}

		this.updateLookAtTarget();
	}

	updatePositionTarget(delta = 0) {
		this.pathPositionTarget =
			this.pathPositionOverride ||
			ThreeMath.clamp(
				this.pathPositionTarget + delta * this.pathSpeedScaler,
				this.minCameraPosition,
				this.maxCameraPosition
			);

		if (this.dev) {
			this.dev.positionControl.setValue(this.pathPositionTarget);
		}
	}

	updatePosition() {
		if (!this.cameraPath) return;

		const { cameraRig } = this.props;

		this.pathPosition += (this.pathPositionTarget - this.pathPosition) * 0.05;
		this.setCameraPositionTarget(this.cameraPath.getPositionAt(this.pathPosition));
		cameraRig.position.copy(this.cameraPosition).add(this.positionOffset);
	}

	update(delta = 0.01) {
		if (!this.enabled) return;

		const { cameraRig } = this.props;
		const { useDeviceOrientation, isMobile } = AppProps.state;

		// update position
		this.updatePosition();

		// update orientation
		if (useDeviceOrientation) {
			this.updateDeviceOrientation(delta);
		} else {
			this.updateManualOrientation(delta);
		}

		lerpTowards(this.lookAt.position, this.lookAt.target, this.lookAt.ease.value);

		cameraRig.matrix.lookAt(cameraRig.position, this.lookAt.position, axisUp);
		cameraRig.quaternion.setFromRotationMatrix(cameraRig.matrix);

		if (!isMobile) {
			this.updateInputOverInteractiveObjects();
		}

		this.testForInactivity();
	}

	dispose() {
		console.log('dispose');
		this.unbindEvents();

		this.enabled = null;
		this.radius = null;
		this.hasTransitionedIn = null;
		this.delta = null;
		this.pathPosition = null;
		this.pathPositionTarget = null;
		this.pathPositionOverride = null;
		this.cameraPosition = null;
		this.pointer = null;
		this.pointerCopy = null;
		this.lastInputPosition = null;
		this.matrix = null;
		this.minCameraPosition = null;
		this.maxCameraPosition = null;
		this.pathSpeedScaler = null;
		this.lookAt = null;
		this.transitioning = null;
		this.selectedOnDown = null;
		this.dev = null;
	}
}
