import { h } from 'preact';
import FadingView from '../views/FadingView';
import CSSTransitionGroup from 'preact-css-transition-group';
import { addActiveCSSClass, removeActiveCSSClass } from '../../utils/utils';

import '@styles/components/Button.scss';

export default class CloseButton extends FadingView {
	renderGradient(style) {
		return (
			<div class="close-video-button-gradient-1917">
				<svg viewBox="0 0 50 50">
					<defs>
						<linearGradient
							id="b7063a8e-3490-4327-a3d7-59fdc3069b77"
							x1="25"
							y1="50"
							x2="25"
							gradientUnits="userSpaceOnUse"
						>
							<stop offset="0" stop-color="#ff9646" />
							<stop offset="0.29" stop-color="#e86e37" />
							<stop offset="1" stop-color="#1a2e46" />
						</linearGradient>
					</defs>
					<g id="f745f651-7b01-4f57-b4ac-1e6af9f994f4" data-name="Layer 2">
						<g id="b1f302fd-d64c-4797-8b87-38bd62bc0e03" data-name="Layer 3">
							<polyline
								class="close-video-button-gradient-path-1917"
								points="1 1 49 1 49 49 1 49 1 1"
								style={style}
							/>
						</g>
					</g>
				</svg>
			</div>
		);
	}

	render() {
		const { className, label, onClick, showLabel = true, progress, hasProgress = true } = this.props;

		const val = 192 * (1 - progress);
		const pathStyle = {
			'stroke-dashoffset': val
		};

		return (
			<div
				class={className}
				onClick={onClick}
				onTouchStart={addActiveCSSClass}
				onTouchEnd={removeActiveCSSClass}
				onTouchCancel={removeActiveCSSClass}
				ref={this.ref}
			>
				<CSSTransitionGroup
					component="div"
					transitionName="fade"
					transitionAppear={false}
					transitionEnter={true}
					transitionLeave={true}
				>
					{showLabel && <div class="close-button-label-1917">{label}</div>}
				</CSSTransitionGroup>
				<div class="close-video-button-icon-1917">
					{hasProgress && this.renderGradient(pathStyle)}
					{!hasProgress && <div class="button-gradient-1917" />}
					<div class="close-video-button-icon-inner-1917" />
				</div>
			</div>
		);
	}
}
