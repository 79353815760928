import { h } from 'preact';
import FadingView from '../views/FadingView';
import CSSTransitionGroup from 'preact-css-transition-group';
import { addActiveCSSClass, removeActiveCSSClass } from '../../utils/utils';

import '@styles/components/Button.scss';

export default class AudioButton extends FadingView {
	renderIcon() {
		const { muted } = this.props;

		const className = muted ? 'audio-button-icon-inner-off-1917' : 'audio-button-icon-inner-on-1917';

		return (
			<div class="audio-button-icon-1917">
				<div class="button-gradient-1917" />
				<div class={className} />
			</div>
		);
	}

	render() {
		const { label, onClick, isMobile, showLabel = true } = this.props;

		if (isMobile) {
			return (
				<div
					class="audio-button-1917"
					onClick={onClick}
					onTouchStart={addActiveCSSClass}
					onTouchEnd={removeActiveCSSClass}
					onTouchCancel={removeActiveCSSClass}
					ref={this.ref}
				>
					<CSSTransitionGroup
						component="div"
						transitionName="fade"
						transitionAppear={false}
						transitionEnter={true}
						transitionLeave={true}
					>
						{showLabel && <div class="audio-button-label-1917">{label}</div>}
					</CSSTransitionGroup>
					{this.renderIcon()}
				</div>
			);
		} else {
			return (
				<div class="audio-button-1917" onClick={onClick} ref={this.ref}>
					{this.renderIcon()}
					<div class="audio-button-label-1917">{label}</div>
				</div>
			);
		}
	}
}
