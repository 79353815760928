import { h } from 'preact';
import FadingView from './FadingView';

import '@styles/components/InteractionMessage.scss';

import mobileIcon from '../../../images/common/ui/hand.svg';
import desktopIcon from '../../../images/common/ui/mouse.svg';

export default class InteractionMessage extends FadingView {
	render() {
		const { isMobile, copy, useDeviceOrientation } = this.props;

		const icon = isMobile ? mobileIcon : desktopIcon;

		let label;

		if (!isMobile) {
			label = copy.interactionMessageDesktop;
		} else if (useDeviceOrientation) {
			label = copy.interactionMessageMobileSensor;
		} else {
			label = copy.interactionMessageMobileTouch;
		}

		return (
			<div class="interaction-message-1917" ref={this.ref}>
				<div class="interaction-message-icon-1917">
					<img src={icon} />
				</div>
				<div class="interaction-message-label-1917">{label}</div>
			</div>
		);
	}
}
