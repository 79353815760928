import {
	NormalBlending,
	PlaneBufferGeometry,
	InstancedBufferGeometry,
	InstancedBufferAttribute,
	Vector3,
	Color,
	MeshBasicMaterial,
	Mesh
} from 'three/build/three.module';
import Entity from './entity';
import { randomRange, randomInt } from '../utils/utils';
import { AppProps, PARTICLE_TYPES, MAX_FOG_VALUE, LOWER_FOG_COLOR_DAMPENING } from '../config/constants';

import vertexShader from '../../glsl/smoke.vert';
import fragmentShader from '../../glsl/smoke.frag';

export default class SmokeParticlesEntity extends Entity {
	init() {
		const {
			type,
			textures = [],
			opacity = 1,
			blending = NormalBlending,
			meshSize = 10,
			divisions = 1,
			depthWrite = false,
			depthTest = true,
			maxDistY = 2,
			maxDistZ = 10,
			frustumCulled = false
		} = this.props;

		textures.forEach(tex => {
			tex.needsUpdate = true;
		});

		const geometry = new PlaneBufferGeometry(meshSize, meshSize, divisions, divisions);
		const instanced = this.getInstances(geometry);

		const material = new MeshBasicMaterial({
			color: 0xffffff,
			map: textures[0],
			transparent: true,
			blending,
			depthWrite,
			depthTest,
			opacity: 1,
			fog: true
		});

		material.onBeforeCompile = shader => {
			shader.uniforms.type = { value: type };
			shader.uniforms.time = { value: 0 };
			shader.uniforms.amplitude = { value: 1 };
			shader.uniforms.speedScalar = { value: 1 };
			shader.uniforms.opacityScalar = { value: opacity };
			shader.uniforms.minCamDist = { value: 0.1 };
			shader.uniforms.maxCamDist = { value: 1.0 };
			shader.uniforms.maxDistY = { value: maxDistY };
			shader.uniforms.maxDistZ = { value: maxDistZ };
			shader.uniforms.maxFogValue = { value: MAX_FOG_VALUE };
			shader.uniforms.fogColorDampening = { value: LOWER_FOG_COLOR_DAMPENING };

			if (textures[0]) shader.uniforms.tDiffuse0 = { value: textures[0] };
			if (textures[1]) shader.uniforms.tDiffuse1 = { value: textures[1] };
			if (textures[2]) shader.uniforms.tDiffuse2 = { value: textures[2] };

			shader.vertexShader = vertexShader;
			shader.fragmentShader = fragmentShader;

			this.uniforms = shader.uniforms;
		};

		material.needsUpdate = true;

		this.mesh = new Mesh(instanced, material);
		this.mesh.frustumCulled = frustumCulled || type === PARTICLE_TYPES.OBJECT_SMOKE;
	}

	getInstances(baseGeometry = null) {
		if (baseGeometry) {
			const instancedGeometry = new InstancedBufferGeometry();
			instancedGeometry.index = baseGeometry.index;
			instancedGeometry.attributes.normal = baseGeometry.attributes.normal;
			instancedGeometry.attributes.position = baseGeometry.attributes.position;
			instancedGeometry.attributes.uv = baseGeometry.attributes.uv;

			const {
				type,
				cameraPath,
				amount = 100,
				textures = [],
				maxPathOffset,
				colors,
				minSize = 1,
				maxSize = 1,
				minSpeed = { x: 0.005, y: 0.005, z: 0.005 },
				maxSpeed = { x: 0.01, y: 0.01, z: 0.01 },
				minRotationSpeed = 0.5,
				maxRotationSpeed = 1.5,
				pathStart = 0,
				pathEnd = 1
			} = this.props;

			const positions = [];
			const finalColors = [];
			const sizes = [];
			const speeds = [];
			const rotations = [];
			const rotationSpeeds = [];
			const timeOffsets = [];
			const textureIndices = [];

			const color = new Color();
			const add = new Vector3();

			const tIncrement = 1 / amount;
			let t = 0;

			for (let i = 0; i < amount; i++) {
				if (maxPathOffset) {
					add.set(
						randomRange(-maxPathOffset.x, maxPathOffset.x),
						randomRange(-maxPathOffset.y, maxPathOffset.y),
						randomRange(-maxPathOffset.z, maxPathOffset.z)
					);
				}

				if (type === PARTICLE_TYPES.SMOKE) {
					const pathPos = cameraPath.getPositionAt(randomRange(pathStart, pathEnd)).add(add);
					positions.push(pathPos.x, pathPos.y, pathPos.z);
				} else {
					positions.push(add.x, add.y, add.z);
				}

				color.setHex(colors[(Math.random() * colors.length) | 0]);
				finalColors.push(color.r, color.g, color.b);
				sizes.push(randomRange(minSize, maxSize));
				speeds.push(
					randomRange(minSpeed.x, maxSpeed.x),
					randomRange(minSpeed.y, maxSpeed.y),
					randomRange(minSpeed.z, maxSpeed.z)
				);
				rotations.push(randomRange(0, Math.PI * 2));
				textureIndices.push(randomInt(0, textures.length));
				rotationSpeeds.push(randomRange(minRotationSpeed, maxRotationSpeed));

				if (type === PARTICLE_TYPES.SMOKE) {
					timeOffsets.push(randomRange(0, 1));
				} else {
					timeOffsets.push(t);
					t += tIncrement;
				}
			}

			// clean up attribute arrays after upload
			const cleanArray = function() {
				this.array = null;
			};

			const offsetAttribute = new InstancedBufferAttribute(new Float32Array(positions), 3).onUpload(cleanArray);
			const colorAttribute = new InstancedBufferAttribute(new Float32Array(finalColors), 3).onUpload(cleanArray);
			const sizeAttribute = new InstancedBufferAttribute(new Float32Array(sizes), 1).onUpload(cleanArray);
			const speedAttribute = new InstancedBufferAttribute(new Float32Array(speeds), 3).onUpload(cleanArray);
			const rotationAttribute = new InstancedBufferAttribute(new Float32Array(rotations), 1).onUpload(cleanArray);
			const rotationSpeedAttribute = new InstancedBufferAttribute(new Float32Array(rotationSpeeds), 1).onUpload(cleanArray);
			const timeOffsetAttribute = new InstancedBufferAttribute(new Float32Array(timeOffsets), 1).onUpload(cleanArray);
			const textureIndexAttribute = new InstancedBufferAttribute(new Float32Array(textureIndices), 1).onUpload(cleanArray);

			// set initial amount
			instancedGeometry.maxInstancedCount = amount;

			instancedGeometry.addAttribute('offset', offsetAttribute);
			instancedGeometry.addAttribute('color', colorAttribute);
			instancedGeometry.addAttribute('size', sizeAttribute);
			instancedGeometry.addAttribute('speed', speedAttribute);
			instancedGeometry.addAttribute('rotation', rotationAttribute);
			instancedGeometry.addAttribute('rotationSpeed', rotationSpeedAttribute);
			instancedGeometry.addAttribute('timeOffset', timeOffsetAttribute);
			instancedGeometry.addAttribute('textureIndex', textureIndexAttribute);

			return instancedGeometry;
		}
	}

	initDevControls() {
		const { devControls } = AppProps.state;

		if (devControls) {
			const height = 30;
			const fontColor = '#ffffff';
			const group = devControls.add('group', { name: 'SMOKE PARTICLES', h: height });

			group
				.add('bool', {
					name: 'Enabled',
					value: true,
					fontColor
				})
				.onChange(v => {
					if (v) this.show();
					else this.hide();
				});

			group
				.add('slide', {
					name: 'Particle Amount',
					min: 0,
					max: this.props.amount,
					value: this.props.amount,
					precision: 0,
					fontColor,
					h: height
				})
				.onChange(v => {
					this.mesh.geometry.maxInstancedCount = v;
				});

			group
				.add('color', {
					name: 'Tint',
					type: 'html',
					value: this.mesh.material.color.getHex(),
					fontColor,
					h: height
				})
				.onChange(v => {
					const [r, g, b] = v;
					this.mesh.material.color.setRGB(r, g, b);
				});

			this.particleControls = group;
		}
	}

	removeDevControls() {
		const { devControls } = AppProps.state;

		if (devControls && this.particleControls) {
			devControls.remove(this.particleControls);
			this.particleControls = null;
		}
	}

	update(delta = 0.01) {
		if (this.uniforms) {
			this.uniforms.time.value += delta;
		}
	}

	dispose() {
		this.uniforms = null;
		const { textures } = this.props;
		if (textures) {
			textures.forEach(tex => {
				tex.dispose();
			});
		}
		super.dispose();
	}
}
