import bindAll from 'lodash.bindall';
import Stats from 'stats.js';
import {
	Scene,
	PerspectiveCamera,
	WebGLRenderer,
	Group,
	AmbientLight,
	DirectionalLight,
	Clock,
	FogExp2,
	SpotLight,
	Raycaster,
	WebGLRenderTargetCube,
	LinearFilter,
	RepeatWrapping,
	NearestFilter
} from 'three/build/three.module';
import EffectsManager from './effects-manager';
import { MAX_CAMERA_DISTANCE, EVENTS, BACKGROUND_COLOR, AppProps, FOG_DENSITY, FOG_COLOR, CAMERA_FOV } from '../config/constants';
import EventManager from './event-manager';
import Controls from '../controls/controls';
import SceneManager from './scene-manager';
import SkyboxEntity from '../entities/skybox-entity';
import { disableWebglWarnings } from '../utils/three-utils';

export default class WebGLManager {
	constructor(canvas, basePath) {
		bindAll(this, 'handleResize', 'update', 'handlePause', 'handleResume');
		this.initBase(canvas);
		this.initScene(basePath);
		this.bindEvents();
		this.handleResize();
		this.startUpdating();

		setTimeout(() => {
			if (AppProps.state.devControls) {
				this.initDevControls();
			}
		}, 50);

		const { isMobile, isDevelopment } = AppProps.state;

		if (isMobile && isDevelopment) {
			this.addStats();
		}
	}

	initBase(canvas) {
		const width = window.innerWidth;
		const height = window.innerHeight;
		const { isMobile, pixelRatio, isProduction, commonAssets } = AppProps.state;

		this.scene = new Scene();
		this.scene.fog = new FogExp2(FOG_COLOR, FOG_DENSITY);

		this.cameraRig = new Group();
		this.cameraRig.name = 'cameraRig';
		this.scene.add(this.cameraRig);

		const cameraContainer = new Group();
		cameraContainer.name = 'cameraContainer';
		this.cameraRig.add(cameraContainer);

		this.camera = new PerspectiveCamera(CAMERA_FOV, width / height, 0.01, MAX_CAMERA_DISTANCE);

		this.cameraLight = new SpotLight(0xffcccc, 1, 40, Math.PI / 8, 0.5, 0);
		this.cameraLight.position.set(0, 0, 1);

		cameraContainer.add(this.camera, this.cameraLight, this.cameraLight.target);

		this.cameraOrientationObj = new Group();
		this.scene.add(this.cameraOrientationObj);

		this.renderer = new WebGLRenderer({
			powerPreference: isMobile ? 'default' : 'high-performance',
			canvas
		});

		if (isProduction) disableWebglWarnings(this.renderer);

		this.renderer.domElement.id = 'main-canvas-1917';
		this.renderer.setClearColor(0x000000, 1);
		this.renderer.setPixelRatio(pixelRatio || 1);
		this.renderer.setSize(width, height, false);
		AppProps.app.setState({ anisotropy: this.renderer.capabilities.getMaxAnisotropy() });

		this.container = new Group();
		this.container.name = 'container';
		this.scene.add(this.container);

		this.ambientLight = new AmbientLight(0x404040, 1);
		this.scene.add(this.ambientLight);

		this.directionalLight = new DirectionalLight(0xffffff, 0.5);
		this.directionalLight.position.set(0, 100, 100);
		this.scene.add(this.directionalLight);

		this.effectsManager = new EffectsManager({ renderer: this.renderer, camera: this.camera, scene: this.scene });

		this.clock = new Clock();
		this.paused = false;

		this.controls = new Controls({
			camera: this.camera,
			cameraRig: this.cameraRig,
			cameraOrientationObj: this.cameraOrientationObj,
			domElement: this.renderer.domElement
		});

		const { lut } = commonAssets.textures;
		lut.minFilter = lut.magFilter = NearestFilter;

		AppProps.app.setState({
			scene: this.scene,
			camera: this.camera,
			cameraContainer: cameraContainer,
			cameraRig: this.cameraRig,
			renderer: this.renderer,
			controls: this.controls,
			canvas: this.renderer.domElement,
			raycaster: new Raycaster()
		});
	}

	initScene(basePath) {
		const { commonAssets } = AppProps.state;
		const { skyboxTexture } = commonAssets.textures;

		skyboxTexture.wrapS = skyboxTexture.wrapT = RepeatWrapping;

		// environment map lighting
		const envMap = new WebGLRenderTargetCube(128, 128, {
			generateMipmaps: false,
			minFilter: LinearFilter,
			magFilter: LinearFilter
		}).fromEquirectangularTexture(this.renderer, skyboxTexture);

		this.skybox = new SkyboxEntity({
			texture: skyboxTexture
		});
		this.skybox.init();
		this.container.add(this.skybox.mesh);

		this.sceneManager = new SceneManager({
			basePath,
			controls: this.controls,
			container: this.container
		});

		AppProps.app.setState({
			envMap: envMap,
			skybox: this.skybox
		});
	}

	initDevControls() {
		const { devControls, devControlItems } = AppProps.state;

		if (devControls) {
			const height = 30;
			const fontColor = '#ffffff';
			const group = devControls.add('group', { name: 'SCENE', h: height });

			devControlItems.sceneGroup = group;

			AppProps.app.setState({ envMapListeners: [], devControlItems: devControlItems });

			group
				.add('color', {
					name: 'Sky/Fog Color',
					type: 'html',
					value: BACKGROUND_COLOR,
					fontColor,
					h: height
				})
				.onChange(v => {
					const [r, g, b] = v;
					this.scene.fog.color.setRGB(r, g, b);
					this.renderer.setClearColor(this.scene.fog.color, 1);
					if (this.skybox) {
						this.skybox.mesh.material.color.setRGB(r, g, b);
					}
				});

			group
				.add('slide', {
					name: 'Sky Speed',
					min: 0,
					max: 0.1,
					value: this.skybox.speed,
					precision: 3,
					fontColor,
					h: height
				})
				.onChange(v => {
					this.skybox.setSpeed(v);
				});

			group
				.add('slide', {
					name: 'Fog Density',
					min: 0,
					max: 1,
					value: this.scene.fog.density,
					precision: 2,
					fontColor,
					h: height
				})
				.onChange(v => {
					this.scene.fog.density = v;
				});

			group
				.add('color', {
					name: 'Ambient Light Color',
					type: 'html',
					value: this.ambientLight.color.getHex(),
					fontColor,
					h: height
				})
				.onChange(v => {
					const [r, g, b] = v;
					this.ambientLight.color.setRGB(r, g, b);
				});

			group
				.add('slide', {
					name: 'Ambient Light Intensity',
					min: 0,
					max: 3,
					value: this.ambientLight.intensity,
					precision: 1,
					fontColor,
					h: height
				})
				.onChange(v => {
					this.ambientLight.intensity = v;
				});

			group
				.add('color', {
					name: 'Directional Light Color',
					type: 'html',
					value: this.directionalLight.color.getHex(),
					fontColor,
					h: height
				})
				.onChange(v => {
					const [r, g, b] = v;
					this.directionalLight.color.setRGB(r, g, b);
				});

			group
				.add('slide', {
					name: 'Directional Light Intensity',
					min: 0,
					max: 3,
					value: this.directionalLight.intensity,
					precision: 1,
					fontColor,
					h: height
				})
				.onChange(v => {
					this.directionalLight.intensity = v;
				});

			const { x, y, z } = this.directionalLight.position;
			group
				.add('number', {
					name: 'Directional Light Position',
					value: [x, y, z],
					fontColor,
					h: height,
					precision: 0
				})
				.onChange(v => {
					this.directionalLight.position.set(v[0], v[1], v[2]);
				});

			group
				.add('bool', {
					name: 'Environment Map Enabled',
					value: true,
					fontColor
				})
				.onChange(v => {
					AppProps.state.envMapListeners.forEach(material => {
						material.envMap = v ? AppProps.state.envMap.texture : null;
						material.needsUpdate = true;
					});
				});

			group
				.add('slide', {
					name: 'Environment Map Intensity',
					min: 0,
					max: 2,
					value: 0.5,
					precision: 2,
					fontColor,
					h: height
				})
				.onChange(v => {
					AppProps.state.envMapListeners.forEach(material => {
						material.envMapIntensity = v;
					});
				});
		}
	}

	bindEvents() {
		window.addEventListener('resize', this.handleResize, false);
		window.addEventListener('orientationchange', this.handleResize, false);
		EventManager.on(EVENTS.PAUSE, this.handlePause);
		EventManager.on(EVENTS.RESUME, this.handleResume);
	}

	unbindEvents() {
		window.removeEventListener('resize', this.handleResize);
		window.removeEventListener('orientationchange', this.handleResize);
		EventManager.off(EVENTS.PAUSE, this.handlePause);
		EventManager.off(EVENTS.RESUME, this.handleResume);
	}

	addStats() {
		const stats = new Stats();
		stats.dom.style.cssText = 'position:fixed;bottom:0;left:0;cursor:pointer;opacity:0.9;z-index:10000';
		document.body.appendChild(stats.dom);
		this.stats = stats;
	}

	handlePause() {
		this.paused = true;
		this.clock.stop();
	}

	handleResume() {
		this.clock.start();
		this.paused = false;
	}

	handleResize() {
		const { isIOS } = AppProps.state;
		const delay = isIOS ? 100 : 0;

		this.resizeTimeout = setTimeout(() => {
			const width = window.innerWidth;
			const height = window.innerHeight;

			this.camera.aspect = width / height;
			this.camera.updateProjectionMatrix();

			this.renderer.setSize(width, height, false);

			if (this.effectsManager) {
				this.effectsManager.resize(width, height);
			}

			this.render();
		}, delay);
	}

	startUpdating() {
		this.renderer.setAnimationLoop(this.update);
	}

	stopUpdating() {
		this.renderer.setAnimationLoop(null);
	}

	update() {
		const { showLanding, showOutro, showMap } = AppProps.state;
		if (this.paused || showLanding || showOutro || showMap) return;

		if (this.stats) {
			this.stats.begin();
		}

		const delta = this.clock.getDelta();

		if (this.controls) {
			this.controls.update(delta);
		}

		if (this.skybox) {
			this.skybox.update(delta);
		}

		if (this.sceneManager) {
			this.sceneManager.update(delta);
		}

		this.render();

		if (this.stats) {
			this.stats.end();
		}
	}

	render() {
		if (this.effectsManager) {
			this.effectsManager.render();
		} else if (this.renderer) {
			this.renderer.render(this.scene, this.camera);
		}
	}

	getRenderer() {
		return this.renderer;
	}

	getScene() {
		return this.scene;
	}

	getCamera() {
		return this.camera;
	}

	getCameraRig() {
		return this.cameraRig;
	}

	getContainer() {
		return this.container;
	}

	getClock() {
		return this.clock;
	}

	removeAll() {
		this.scene.children.forEach(child => {
			child.parent.remove(child);
		});
	}

	dispose() {
		console.log('dispose');
		this.stopUpdating();
		this.unbindEvents();
		this.removeAll();
		this.clock.stop();

		if (this.effectsManager) {
			this.effectsManager.dispose();
		}

		if (this.sceneManager) {
			this.sceneManager.dispose();
		}

		if (this.controls) {
			this.controls.dispose();
		}

		if (this.scene) {
			this.scene.dispose();
		}

		if (this.renderer) {
			this.renderer.clear();
			this.renderer.dispose();
		}

		this.scene = null;
		this.cameraRig = null;
		this.camera = null;
		this.cameraLight = null;
		this.cameraOrientationObj = null;
		this.renderer = null;
		this.container = null;
		this.ambientLight = null;
		this.directionalLight = null;
		this.effectsManager = null;
		this.clock = null;
	}
}
