import bindAll from 'lodash.bindall';
import TrenchEntity from '../entities/trench-entity';
import { config } from '../config/config';
import { abs, map } from '../utils/utils';
import {
	CAMERA_POSITION_TRIGGER_THRESHOLD,
	AppProps,
	EVENTS,
	TRIGGER_TYPES,
	INITIAL_TRENCH_INDEX,
	VIDEO_STYLES,
	HOTSPOT_TYPES
} from '../config/constants';
import EventManager from './event-manager';
import { sendTrackingEvent } from '../utils/tracking';

export default class SceneManager {
	constructor(props) {
		bindAll(this, 'handleMediaTrigger', 'handleContentComplete', 'handleLastVideoStarted', 'handleHotspot', 'handleGoHome');
		this.props = props;
		this.init();
	}

	init() {
		const { basePath, controls } = this.props;
		const { trenches } = config;

		AppProps.app.setState({ sceneManager: this });

		this.trenches = [];
		this.currentTrenchIndex = INITIAL_TRENCH_INDEX;
		this.triggerActive = false;

		trenches.forEach(trenchConfig => {
			const trench = new TrenchEntity({
				basePath,
				controls,
				...trenchConfig
			});
			this.trenches.push({ trench, config: trenchConfig });
		});

		this.currentTrench = this.trenches[this.currentTrenchIndex].trench;

		this.loadTrench(this.currentTrenchIndex).then(trench => {
			const { showIntro } = AppProps.state;
			if (showIntro) {
				EventManager.once(EVENTS.INTRO_COMPLETE, () => {
					this.initTrench(this.currentTrenchIndex);
				});
			} else {
				this.initTrench(this.currentTrenchIndex);
			}
		});

		EventManager.on(EVENTS.HOTSPOT_SELECTED, this.handleHotspot);

		setTimeout(() => {
			if (AppProps.state.devControls) {
				this.initDevControls();
			}
		}, 50);
	}

	initDevControls() {
		const { devControls, autoplayVideos = true } = AppProps.state;

		if (devControls) {
			const height = 30;
			const fontColor = '#ffffff';
			const group = devControls.add('group', { name: 'CONTENT', h: height });

			group
				.add('bool', {
					name: 'Triggers Enabled',
					value: true,
					fontColor
				})
				.onChange(v => {
					this.skipTriggers = !v;
				});

			group
				.add('bool', {
					name: 'Autoplay Videos',
					value: autoplayVideos,
					fontColor
				})
				.onChange(v => {
					AppProps.app.setState({ autoplayVideos: v });
				});

			group.add('button', {
				name: 'Video / Seek To End',
				simple: true,
				callback: () => {
					EventManager.emit(EVENTS.VIDEO_SEEK_TO_END);
				},
				fontColor,
				h: height
			});

			group.add('button', {
				name: 'Video / Seek To End And Pause',
				simple: true,
				callback: () => {
					EventManager.emit(EVENTS.VIDEO_SEEK_TO_END, true);
				},
				fontColor,
				h: height
			});

			group.add('button', {
				name: 'Video / Resume',
				simple: true,
				callback: () => {
					EventManager.emit(EVENTS.RESUME_VIDEO);
				},
				fontColor,
				h: height
			});

			group
				.add('slide', {
					name: 'Video Opacity',
					min: 0,
					max: 1,
					value: 1,
					precision: 1,
					fontColor,
					h: height
				})
				.onChange(v => {
					EventManager.emit(EVENTS.SET_VIDEO_OPACITY, v);
				});
		}
	}

	setCurrentTrenchIndex(index) {
		this.currentTrenchIndex = index;
	}

	getCurrentTrenchIndex() {
		return this.currentTrenchIndex;
	}

	loadTrench(index = 0) {
		AppProps.app.setState({ showLoadingMessage: true, fadeToBlack: true });
		return this.trenches[index].trench.load();
	}

	queueNextTrench() {
		const { trenches } = config;
		this.currentTrenchIndex++;

		AppProps.app.setState({ fadeToBlack: true, fadeToBlackDuration: 3 });

		if (this.currentTrenchIndex < trenches.length) {
			console.log(`queue trench ${this.currentTrenchIndex}: start load`);

			this.loadTrench(this.currentTrenchIndex).then(trench => {
				this.initTrench(this.currentTrenchIndex);
			});

			return true;
		}

		return false;
	}

	initTrench(index = 0) {
		const { trench, config } = this.trenches[index];
		const { container, controls } = this.props;

		controls.reset();

		if (this.currentTrench && this.currentTrench !== trench) {
			this.currentTrench.hide();
		}

		this.currentTrench = trench;
		this.currentTrenchConfig = config;

		const init = trnch => {
			trnch.init();
			container.add(trnch.mesh);
			trnch.transitionIn(2, 0, 2);

			AppProps.app.setState({
				fadeToBlackDuration: 3
			});

			setTimeout(() => {
				AppProps.app.setState({
					showLoadingMessage: false,
					fadeToBlack: false
				});
			}, 250);
		};

		if (trench.loaded) {
			init(trench);
		} else {
			this.loadTrench(index).then(t => {
				init(t);
			});
		}
	}

	handleGoHome() {
		if (this.currentTrench) {
			this.currentTrench.hide();
		}

		this.currentTrench = null;
		this.currentTrenchConfig = null;
	}

	handleHotspot(hotspotConfig) {
		const { type, media, url } = hotspotConfig;
		const { controls } = this.props;

		const playVideo = videoStyle => {
			controls.disable();
			EventManager.once(EVENTS.VIDEO_COMPLETE, this.handleContentComplete);
			AppProps.app.setState({
				videoSource: media,
				videoStyle: videoStyle
			});
		};

		switch (type) {
			case HOTSPOT_TYPES.VIDEO:
				playVideo(VIDEO_STYLES.CONTENT);
				break;

			case HOTSPOT_TYPES.VIDEO_4x5:
				playVideo(VIDEO_STYLES.CONTENT_4x5);
				break;

			case HOTSPOT_TYPES.LINK:
				window.open(url, '_blank');
				break;
		}

		// tracking
		const hotspotId = hotspotConfig.id + 1;
		sendTrackingEvent({
			action: 'click',
			category: 'page',
			label: `hotspot ${hotspotId}`,
			value: `hotspot-${hotspotId}-button-click`
		});
	}

	handleMediaTrigger(trigger) {
		this.triggerActive = true;

		AppProps.app.setState({
			logoEnabled: false
		});

		const { controls } = this.props;
		const { last, moveTo, lookAtOverride, rotationOffset } = trigger;

		if (last) {
			EventManager.once(EVENTS.VIDEO_STARTED, this.handleLastVideoStarted);
		}

		controls.setPathPositionOverride(trigger.position);

		if (lookAtOverride) {
			controls.setLookAtOverride(true, lookAtOverride);
		}

		// move to new position that matches video out frame
		if (moveTo && moveTo.position) {
			EventManager.once(EVENTS.VIDEO_TRANSITIONED_IN, () => {
				controls.setPathPosition(moveTo.position, true);
			});
		}

		const finish = setInfluence => {
			const finishInner = () => {
				controls.disable();
				controls.setInfluence(0, 0);

				EventManager.once(EVENTS.VIDEO_COMPLETE, () => {
					this.handleContentComplete(last);
					this.triggerActive = false;
					EventManager.emit(EVENTS.TRIGGER_COMPLETE);
				});
				AppProps.app.setState({
					videoId: trigger.id,
					videoSource: trigger.media,
					videoSkipTransitionOut: trigger.skipTransitionOut,
					videoStyle: VIDEO_STYLES.TRANSITION
				});
			};

			if (setInfluence) {
				controls.setInfluence(0, 1).then(() => {
					finishInner();
				});
			} else {
				finishInner();
			}
		};

		if (rotationOffset) {
			controls.setRotationOffset(rotationOffset, 1.5).then(() => {
				finish();
			});
		} else {
			finish(true);
		}
	}

	handleLastVideoStarted() {
		const nextTrenchFound = this.queueNextTrench();

		// show outro if last trench
		if (!nextTrenchFound) {
			if (this.currentTrench) {
				this.currentTrench.hide();
				this.currentTrench = null;
				this.currentTrenchConfig = null;
			}

			// EventManager.once(EVENTS.VIDEO_TRANSITIONED_OUT, () => {
			// EventManager.once(EVENTS.VIDEO_COMPLETE, () => {
			EventManager.once(EVENTS.VIDEO_ALMOST_COMPLETE, () => {
				AppProps.app.setState({
					showOutro: true,
					showFullUI: false
				});
			});
		}
	}

	handleContentComplete(isLastTrigger) {
		const { controls } = this.props;

		// video already transitioned out at this point now
		// next trench will enable
		if (!isLastTrigger) {
			controls.enable();
			controls.setInfluence(1, 1);
		}
		controls.setPathPositionOverride(null);
		controls.setLookAtOverride(false);
	}

	update(delta = 0.01) {
		if (!this.currentTrenchConfig) return;

		if (!this.skipTriggers) {
			const { controls } = this.props;
			const { pathPosition } = controls;
			const { triggers, sunsetScaleRange } = this.currentTrenchConfig;
			const { skybox } = AppProps.state;

			// set the sunset
			if (sunsetScaleRange && skybox) {
				const { from, scaleMin, to, scaleMax, value } = sunsetScaleRange;
				if (value) {
					skybox.setSunsetScale(value);
				} else {
					const v = map(pathPosition, from, to, scaleMin, scaleMax);
					skybox.setSunsetScale(v.toFixed(3));
				}
			}

			triggers.forEach(trigger => {
				if (trigger.type === TRIGGER_TYPES.MEDIA) {
					// videos
					if (!trigger.triggered) {
						const diff = abs(pathPosition - trigger.position);
						if (diff <= CAMERA_POSITION_TRIGGER_THRESHOLD || pathPosition > trigger.position) {
							trigger.triggered = true;
							this.handleMediaTrigger(trigger);
						}
					}
				} else if (trigger.type === TRIGGER_TYPES.CAMERA_CHANGE) {
					// camera rotations, position offsets
					if (!trigger.triggered && pathPosition >= trigger.position) {
						if (trigger.rotationOffset)
							controls.setRotationOffset(trigger.rotationOffset, trigger.transitionDuration);
						if (trigger.positionOffset)
							controls.setPositionOffset(trigger.positionOffset, trigger.transitionDuration);
						trigger.triggered = true;
					} else if (trigger.triggered && pathPosition < trigger.position) {
						if (trigger.rotationReset) controls.setRotationOffset(trigger.rotationReset, trigger.transitionDuration);
						if (trigger.positionReset) controls.setPositionOffset(trigger.positionReset, trigger.transitionDuration);
						trigger.triggered = false;
					}
				}
			});
		}

		if (this.currentTrench) {
			this.currentTrench.update(delta);
		}
	}

	dispose() {
		console.log('dispose');
		this.trenches.forEach(trenchObj => {
			trenchObj.trench.dispose();
		});

		this.trenches = null;
		this.currentTrenchIndex = null;
		this.triggerActive = null;
		this.currentTrench = null;
		this.currentTrenchConfig = null;
		this.props = null;
	}
}
