import { h, Component, createRef } from 'preact';
import gsap from 'gsap';

export default class FadingView extends Component {
	constructor(props) {
		super(props);
		this.ref = createRef();
		this.state = {
			ref: this.ref
		};
	}

	componentWillAppear(callback) {
		this.transitionIn(callback);
	}

	componentWillEnter(callback) {
		this.transitionIn(callback);
	}

	componentWillLeave(callback) {
		this.transitionOut(callback);
	}

	componentWillUnmount() {
		this.ref = null;
	}

	transitionIn(callback) {
		if (!this.ref.current) {
			callback();
			return;
		}

		const { duration = 0.6, delay = 0 } = this.props;

		if (duration === 0) {
			gsap.set(this.ref.current, { opacity: 1 });
			callback();
		} else {
			gsap.fromTo(
				this.ref.current,
				{
					opacity: 0
				},
				{
					duration,
					delay,
					opacity: 1,
					ease: 'power3.inOut',
					onComplete: () => {
						if (this.ref) callback();
					}
				}
			);
		}
	}

	transitionOut(callback) {
		if (!this.ref.current) {
			callback();
			return;
		}

		const { duration = 0.6 } = this.props;

		gsap.to(this.ref.current, {
			duration,
			opacity: 0,
			ease: 'power3.inOut',
			onComplete: () => {
				callback();
			}
		});
	}

	render() {
		return <div ref={this.ref} />;
	}
}
