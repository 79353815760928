import { h } from 'preact';
import bindAll from 'lodash.bindall';
import { openIOSARQuickLook, openAndroidARViewer } from '../../utils/ar';
import FadingView from '../views/FadingView';
import CSSTransitionGroup from 'preact-css-transition-group';
import { addActiveCSSClass, removeActiveCSSClass } from '../../utils/utils';

import '@styles/components/Button.scss';

export default class ARButton extends FadingView {
	constructor(props) {
		super(props);
		bindAll(this, 'handleClick');
	}

	handleClick() {
		const { isIOS, arFiles } = this.props;

		if (isIOS) {
			console.log('open AR iOS:', arFiles.ios);
			openIOSARQuickLook(arFiles.ios);
		} else {
			console.log('open AR Android:', arFiles.android);
			openAndroidARViewer(arFiles.android, '1917 AR');
		}
	}

	render() {
		const { label, showLabel = true } = this.props;

		return (
			<div
				class="ar-button-1917"
				onClick={this.handleClick}
				onTouchStart={addActiveCSSClass}
				onTouchEnd={removeActiveCSSClass}
				onTouchCancel={removeActiveCSSClass}
				ref={this.ref}
			>
				<CSSTransitionGroup
					component="div"
					transitionName="fade"
					transitionAppear={false}
					transitionEnter={true}
					transitionLeave={true}
				>
					{showLabel && <div class="ar-button-label-1917">{label}</div>}
				</CSSTransitionGroup>
				<div class="ar-button-icon-1917">
					<div class="button-gradient-1917" />
					<div class="ar-button-icon-inner-1917" />
				</div>
			</div>
		);
	}
}
