import { h } from 'preact';
import FadingView from './FadingView';
import { addActiveCSSClass, removeActiveCSSClass } from '../../utils/utils';
import { AppProps } from '../../config/constants';
import gsap from 'gsap';

import '@styles/components/LegalPopup.scss';

import logos from '@ui-images/legal-logos.png';

export default class LegalPopup extends FadingView {
	transitionIn(callback) {
		if (!this.ref.current) {
			callback();
			return;
		}

		const { duration = 0.6, delay = 0, isMobile } = this.props;

		gsap.fromTo(
			this.ref.current,
			{
				opacity: isMobile ? 1 : 0,
				y: isMobile ? '100%' : 50
			},
			{
				duration,
				delay,
				opacity: 1,
				y: 0,
				ease: 'power3.inOut',
				onComplete: () => {
					if (this.ref) callback();
				}
			}
		);
	}

	transitionOut(callback) {
		if (!this.ref.current) {
			callback();
			return;
		}

		const { duration = 0.6, isMobile } = this.props;

		gsap.to(this.ref.current, {
			duration,
			opacity: isMobile ? 1 : 0,
			y: isMobile ? '100%' : 50,
			ease: 'power3.inOut',
			onComplete: () => {
				callback();
			}
		});
	}

	renderLinks() {
		const { copy, config } = this.props;
		const { links } = config;

		return links.map(link => {
			return (
				<div
					class="legal-popup-link-1917"
					onClick={() => {
						window.open(link.url, '_blank');
					}}
					onTouchStart={addActiveCSSClass}
					onTouchEnd={removeActiveCSSClass}
					onTouchCancel={removeActiveCSSClass}
				>
					{copy.legal.links[link.labelKey]}
				</div>
			);
		});
	}

	render() {
		const { copy, side } = this.props;

		const className = side === 1 ? 'legal-popup-1917 right-side' : 'legal-popup-1917';

		return (
			<div class={className} ref={this.ref}>
				<div class="legal-popup-header-1917">{copy.legal.header}</div>
				<div class="legal-popup-link-container-1917">{this.renderLinks()}</div>
				<img class="legal-popup-logos-1917" src={logos} />
				<div class="legal-popup-copyright-container">
					<div class="legal-popup-copyright-1-1917">{copy.legal.copyright1}</div>
					<div class="legal-popup-copyright-2-1917">{copy.legal.copyright2}</div>
				</div>
				<div
					class="legal-popup-close-button-1917"
					onClick={() => {
						AppProps.app.setState({ showLegals: false });
					}}
					onTouchStart={addActiveCSSClass}
					onTouchEnd={removeActiveCSSClass}
					onTouchCancel={removeActiveCSSClass}
				/>
			</div>
		);
	}
}
