import { Mesh } from 'three/build/three.module';
import CameraPath from '../controls/camera-path';
import { AppProps, PARTICLE_TYPES, DEBUG_PATHS, LOWER_FOG_COLOR_DAMPENING, EVENTS, MAX_FOG_VALUE } from '../config/constants';
import Entity from './entity';
import DustParticlesEntity from './dust-particles-entity';
import SmokeParticlesEntity from './smoke-particles';
import HotSpotEntity from './hotspot-entity';
import FootstepAnimationEntity from './footstep-animation-entity';
import EventManager from '../managers/event-manager';
import { sendTrackingEvent } from '../utils/tracking';

import standardVert from '../../glsl/standard.vert';
import standardFrag from '../../glsl/standard.frag';

export default class TrenchEntity extends Entity {
	init() {
		const { geometry, path } = this.assets.geometry;
		const { id, rootMesh, triggers, pathOffsetY, particles, ar, footstepAnimation, endCards, meshesToRemove } = this.props;
		const { anisotropy, envMap, devControls } = AppProps.state;
		const { textures } = this.assets;

		AppProps.app.setState({ arFiles: ar });

		triggers.forEach(trigger => {
			trigger.triggered = false;
		});

		if (!this.mesh) {
			// console.log('geometry.scene:', geometry.scene);
			const root = rootMesh ? geometry.scene.getObjectByName(rootMesh) : geometry.scene;
			this.mesh = root;

			this.cameraPath = new CameraPath({
				line: path.children[0],
				pathOffsetY,
				parent: this.mesh
			});

			if (DEBUG_PATHS) {
				this.cameraPath.setDebugMode(true);
			}

			this.uniforms = [];

			if (devControls) {
				AppProps.state.envMapListeners.length = 0;
			}

			const toRemove = [];
			this.mesh.traverse(child => {
				// console.log('child:', child.name);

				let removalFound = false;

				if (meshesToRemove) {
					meshesToRemove.forEach(name => {
						if (child.name === name) {
							removalFound = true;
							toRemove.push(child);
						}
					});
				}

				if (child instanceof Mesh && !removalFound) {
					if (child.material) {
						// textures that blend the end of trenches
						endCards.forEach(endCard => {
							if (endCard.meshId === child.name) {
								// all have same uuid, so clone to avoid maps getting added to wrong models
								child.material = child.material.clone();
								child.material.map = textures[endCard.textureId];
								child.material.transparent = true;

								// window.material = child.material;

								// defines (uv.y is flipped in the gltf)
								Object.assign(child.material.defines, {
									FLIP_UV_Y: 1,
									DISABLE_SPOT_LIGHTS: 1,
									DISABLE_DIRECTIONAL_LIGHTS: 1
								});
							}
						});

						// props
						child.material.roughness = 0.85;
						child.material.metalness = 0.5;
						child.material.envMap = envMap.texture;
						child.material.envMapIntensity = 0.5;

						// dev controls
						if (devControls && AppProps.state.envMapListeners) {
							AppProps.state.envMapListeners.push(child.material);
						}

						if (child.material.normalMap) child.material.normalMap.anisotropy = anisotropy;

						if (child.material.map) {
							child.material.map.anisotropy = anisotropy;
						}

						// shader customizations
						child.material.onBeforeCompile = shader => {
							shader.uniforms.maxFogValue = { value: MAX_FOG_VALUE };
							shader.uniforms.fogColorDampening = { value: LOWER_FOG_COLOR_DAMPENING };
							shader.uniforms.time = { value: 0 };

							shader.vertexShader = standardVert;
							shader.fragmentShader = standardFrag;

							this.uniforms.push(shader.uniforms);
						};
					}
				}
			});

			toRemove.forEach(removal => {
				this.mesh.remove(removal);
			});

			this.createHotspots(this.cameraPath);

			if (particles) {
				this.createParticles(particles);
			}

			if (footstepAnimation) {
				this.createFootstepAnimation();
			}
		} else {
			AppProps.app.setState({
				hotspots: this.hotspots.map(hotspot => {
					hotspot.reset();
					return hotspot.getHitDetectionMesh();
				})
			});
			this.show();
		}

		sendTrackingEvent({
			action: 'load',
			category: `trench section ${id}`,
			label: 'page',
			value: `trench-${id}-load`
		});
	}

	getParticleClass(type) {
		if (type === PARTICLE_TYPES.DUST) {
			return DustParticlesEntity;
		} else {
			return SmokeParticlesEntity;
		}
	}

	createParticles(particleConfig) {
		const { commonAssets } = AppProps.state;
		this.particles = [];
		particleConfig.forEach(pConfig => {
			const { basePosition } = pConfig;
			const textures = [];
			pConfig.textureIds.forEach(texId => {
				textures.push(commonAssets.textures[texId]);
			});
			const ParticleClass = this.getParticleClass(pConfig.type);
			const particles = new ParticleClass({ cameraPath: this.cameraPath, textures, ...pConfig });
			particles.loaded = true;
			particles.init();
			particles.initDevControls();
			this.mesh.add(particles.mesh);
			this.particles.push(particles);
			if (basePosition) {
				particles.mesh.position.fromArray(basePosition);
			}
		});
	}

	createHotspots(cameraPath) {
		const { hotspots } = this.props;

		this.hotspots = [];
		AppProps.state.hotspots.length = 0;

		hotspots.forEach(hotspotConfig => {
			const hotspot = new HotSpotEntity({ ...hotspotConfig, textures: this.assets.textures, cameraPath });
			hotspot.init();

			const hotspotHit = hotspot.getHitDetectionMesh();

			hotspotHit.userData = { ...hotspotConfig, object: hotspot };

			// no longer revealing extruded text meshes, always visible

			// const { meshIdToReveal } = hotspotConfig;
			// if (meshIdToReveal) {
			// 	const meshToReveal = this.mesh.getObjectByName(meshIdToReveal);
			// 	if (meshToReveal) {
			// 		meshToReveal.material = meshToReveal.material.clone(); // they all share a material, so clone
			// 		meshToReveal.material.transparent = true;
			// 		meshToReveal.material.opacity = 0;
			// 		hotspot.mesh.userData.meshToReveal = meshToReveal;
			// 	}
			// }

			this.mesh.add(hotspot.mesh);
			this.hotspots.push(hotspot);
			AppProps.state.hotspots.push(hotspotHit);
		});
	}

	createFootstepAnimation() {
		const { startPosition, footstepAnimation } = this.props;
		const { isMobile } = AppProps.state;

		const animation = new FootstepAnimationEntity({
			config: footstepAnimation,
			cameraPath: this.cameraPath,
			startPosition
		});
		animation.init();
		this.mesh.add(animation.mesh);

		AppProps.app.setState({ showInteractionMessage: true });

		EventManager.once(EVENTS.ANIMATION_COMPLETE, () => {
			if (isMobile) {
				AppProps.app.setState({ showInteractionMessage: false, showButtonLabels: false });
			} else {
				AppProps.app.setState({ showInteractionMessage: false });
			}
		});

		EventManager.once(EVENTS.SCROLL, () => {
			AppProps.app.setState({ showInteractionMessage: false, showButtonLabels: false });
		});
	}

	transitionIn(duration = 1, delay = 0, fadeToBlackDuration = 1) {
		const { controls, triggers, startPosition } = this.props;

		let endPosition = 1;

		triggers.forEach(trigger => {
			if (trigger.last) {
				endPosition = trigger.position;
			}
		});

		setTimeout(() => {
			AppProps.app.setState({
				fadeToBlackDuration,
				fadeToBlack: false
			});

			controls.setCameraPath(this.cameraPath, startPosition, endPosition, duration);
			// controls.setInfluence(1, 2);
			controls.setInfluence(1, 3).then(() => {
				controls.resetScrollTime();
				AppProps.app.setState({
					logoEnabled: true
				});
			});
			controls.enable();
		}, delay * 1000);
	}

	transitionOut(duration = 1, delay = 0, fadeToBlackDuration = 1) {
		this.transTimeout = setTimeout(() => {
			AppProps.app.setState({
				fadeToBlack: true,
				fadeToBlackDuration
			});
		}, delay * 1000);
	}

	update(delta = 0.01) {
		if (this.propUniforms) {
			this.propUniforms.time.value += delta;
		}

		if (this.uniforms) {
			this.uniforms.forEach(uniforms => {
				uniforms.time.value += delta;
			});
		}

		if (this.particles) {
			this.particles.forEach(particles => {
				particles.update(delta);
			});
		}

		if (this.hotspots) {
			this.hotspots.forEach(hotspot => {
				hotspot.update();
			});
		}
	}

	show() {
		// console.log('trench show');
		super.show();
		if (this.particles) {
			this.particles.forEach(particles => {
				particles.initDevControls();
			});
		}
	}

	hide() {
		// console.log('trench hide');
		super.hide();
		if (this.particles) {
			this.particles.forEach(particles => {
				particles.removeDevControls();
			});
		}

		const { skybox } = AppProps.state;
		if (skybox) skybox.resetSunsetScale();
	}

	dispose() {
		console.log('dispose');
		clearTimeout(this.transTimeout);

		if (this.cameraPath) {
			this.cameraPath.dispose();
		}

		if (this.particles) {
			this.particles.forEach(particles => {
				particles.dispose();
			});
		}

		if (this.currentImage3d) {
			this.currentImage3d.dispose();
		}

		if (this.hotspots) {
			this.hotspots.forEach(hotspot => {
				hotspot.dispose();
			});
		}

		this.transTimeout = null;
		this.propUniforms = null;
		this.cameraPath = null;
		this.particles = null;
		this.uniforms = null;
		this.hotspots = null;
		this.currentImage3d = null;

		super.dispose();
	}
}
